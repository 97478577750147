import { useParams, useHistory } from "react-router";
import { useEffect, useState, useContext } from "react";
import { Card, Spinner, Tabs, Tab, Row, Col, Button, Form, Collapse } from "react-bootstrap";
import { Avatar } from "@mui/material";
import { Table } from "../../Components/Table";
import { GetGenericColumns } from "../../Utilities/Columns";
import { postData } from "../../Utilities/apiRequests";
import { LinearProgress } from "@mui/material";
import * as FaIcons from "react-icons/fa";
import * as GiIcons from "react-icons/gi";
import WindowedSelect from "react-windowed-select";
import { labelValueSerializer } from "../../Utilities/Formaters";
import { CreateRuleForm } from "./Rules";
import { useRef } from "react";
import { UserContext } from "../../App";

let columns = [
  "Date Of Service",
  "Timestamp",
  "Provider_Name",
  "CPT_Code",
  "Billed",
  "Allowed",
  "Total_Paid",
  "Ins1Name",
  "Units",
  "Modifier 1",
]

export const RuleError = () => {
  const rule_initial_state = {
    rule_name: "",
    date_created: new Date(),
    created_by: "",
    filters: [],
    identifier: { name: "", operator: "", value: "" },
    is_active: false,
    expires: new Date(),
    description: ""
  }

  const { id } = useParams();
  const history = useHistory();
  const editRef = useRef(null);
  const { user } = useContext(UserContext);

  const [rows, setRows] = useState([]);
  const [rule, setRule] = useState(rule_initial_state);
  const [author, setAuthor] = useState({ first_name: "", last_name: "", email: "" });

  const [loading, setLoading] = useState(false);
  const [ruleLoading, setRuleLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [submission, setSubmission] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    make_api_requests();
  }, [])

  useEffect(() => {
    if (rule.created_by)
      postData(`/billing_api/get_user/${rule.created_by}`, {}, setAuthor, setError);
    if (rule.identifier.name && !columns.includes(rule.identifier.name) && !rule.identifier.name.includes("("))
      columns = columns.concat([rule.identifier.name])
    if (rule.filters.some(filter => filter.value.includes("[") || filter.value.includes("<@>")))
      setDisableEdit(true);
  }, [rule])

  useEffect(() => {
    if (submission) {
      make_api_requests();
      setShowEdit(false);
      setSubmission(false);
      window.scrollTo(0, 0);
    }
  }, [submission])

  const make_api_requests = () => {
    postData(`/billing_api/get_billing_errors/${id}`, {}, setRows, setError, { setLoading: setLoading })
    postData(`/billing_api/get_billing_rules/${id}`, {}, setRule, setError, { setLoading: setRuleLoading })
  }

  const handleEdit = (e, param) => {
    e.preventDefault()
    // whenever blur/enter/confirmed, edit the parameter for the rule
    // send api request only if a change has been made
    if (rule[param] !== e.target.value) {
      postData(`/billing_api/edit_rule/${id}`, { [param]: e.target.value }, setRule, setError)
    }
  }

  return (
    <div className="mycontainer" style={{ width: "80%" }}>
      <Card>
        <a href="#" onClick={() => history.push("/BillingRules/Rules")}>Back</a>
        <Card.Title as="h2">Errors for Billing Rule: </Card.Title>
        {ruleLoading ? <Spinner animation="border" /> :
          <>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h3 style={{ fontSize: "2.5rem", display: "flex", alignItems: "center" }}>
                {/* {rule.rule_name} */}
                <Form.Control
                  className="editableTitle"
                  defaultValue={rule.rule_name}
                  onBlur={(e) => handleEdit(e, "rule_name")}
                />
              </h3>
              <div className="subtitle" style={{ float: "right" }}>
                Created on {new Date(rule.date_created).toISOString().split("T")[0]} <br />
                by {author.first_name} {author.last_name}
              </div>
            </div>
            <Tabs defaultActiveKey="meta">
              <Tab title="Metadata" eventKey="meta">
                <Tab.Container>
                  <MetaData rule={rule} author={author} errors={rows} />
                  <RuleInformation rule={rule} />
                  {!disableEdit &&
                    user &&
                    (user.role.some(r => ["ADMIN", "BILLING_ERROR_ADMIN"].includes(r)) || user.uid === rule.created_by) &&
                    <Card ref={editRef}>
                      <div>
                        <a>
                          <h3 style={{ display: "flex", justifyContent: "space-between" }} onClick={() => setShowEdit(!showEdit)}>
                            <>Edit Rule Information</>
                            <>{showEdit ? <FaIcons.FaAngleDoubleUp /> : <FaIcons.FaAngleDoubleDown />}</>
                          </h3>
                        </a>
                        <Collapse in={showEdit} onEntered={() => editRef.current.scrollIntoView()}>
                          <div>
                            <CreateRuleForm
                              editExistingRule={true}
                              setError={setError}
                              setSubmission={setSubmission}
                              rule={rule} />
                          </div>
                        </Collapse>
                      </div>
                    </Card>
                  }
                </Tab.Container>
              </Tab>
              <Tab title="Subscribers" eventKey="subs">
                <Tab.Container>
                  <Subscribers rule={rule} author={author} />
                </Tab.Container>
              </Tab>
            </Tabs>
          </>
        }
        <br />
        <br />
        <hr />
        {loading && <LinearProgress variant="indeterminate" />}
        <Table
          columns={GetGenericColumns(rows, columns, { Timestamp: "Date Identified" })}
          data={rows}
          isExpand={true}
          singleExpand={true}
          isColumnFilter={true}
        />
      </Card>
    </div>
  )
}

const RuleInformation = ({ rule }) => {
  rule['identifier']['name'] = rule["identifier"]["name"].replace("<@>", "");
  if (typeof rule["identifier"]["value"] === 'string')
    rule["identifier"]["value"] = rule["identifier"]["value"].replace("<@>", "");
  if (rule["identifier"]["value2"]) rule["identifier"]["value2"] = rule["identifier"]["value2"].replace("<@>", "");
  return (
    <Card>
      <Card.Title>Filter Details for Rule</Card.Title>
      <h3> Filters </h3>
      {rule["filters"].map((r, idx) => {
        if (typeof r['value'] === 'string') r['value'] = r['value'].replace("<@>", "")
        if (r['value2']) r['value2'] = r['value2'].replace("<@>", "")
        return (
          <div key={idx}>
            {r["name"]} {r["operator"]} {r["value"].toString()} {r["value2"] && r["operator"] === "between" ? `and ${r["value2"]}` : ""} <br />
          </div>
        )
      })}
      <br />
      <h3> Identifier </h3>
      {rule["identifier"]["name"]} {rule["identifier"]["operator"]} {rule["identifier"]["value"]} <br />
      Any entry shown below is shown as a billing error because the {rule["identifier"]["name"]} {" "}
      value is {rule["identifier"]["operator"]} {rule["identifier"]["value"]} {rule["identifier"]["value2"] && rule["identifier"]["operator"] === "between" ?
        `and ${rule["identifier"]["value2"]}` : ""}
    </Card >
  )
}

const MetaData = ({ rule, author, errors }) => {
  return (
    <div>
      <b>Status</b>: {rule.is_active ? "Active" : "Inactive"} {" "} <FaIcons.FaCircle style={{ color: !rule.is_active ? "red" : "green" }} /><br />
      <b>Author</b>: {author.first_name} {author.last_name} ({author.email}) | id#{rule.created_by} <br />
      <b>Expires</b>: {rule.expires.toString()} <br />
      <b>Description</b>: {rule.description} <br />
      <b>Errors Caught</b>: {errors.length}
    </div>
  )
}

const Subscribers = ({ rule, author }) => {
  const { user } = useContext(UserContext);

  const [subs, setSubs] = useState([]);
  const [subPics, setSubPics] = useState({});
  const [emailOpts, setEmailOpts] = useState([]);

  const [filteredEmailOpts, setFilteredEmailOpts] = useState([]);
  const [newSub, setNewSub] = useState(null);

  const [loadingOpts, setLoadingOpts] = useState(false);
  const [loadingSubs, setLoadingSubs] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (rule && rule.id) {
      LoadData();
    }
  }, [])

  useEffect(() => {
    if (emailOpts.length > 0) setFilteredEmailOpts(emailOpts);
    if (emailOpts.length > 0 && emailOpts.some(opt => subs.includes(opt.mail))) {
      let temp = emailOpts;
      temp = temp.filter(opt => !subs.includes(opt.mail))
      setFilteredEmailOpts(temp);
    }
  }, [emailOpts, subs])

  useEffect(() => {
    if (subs.length > 0 && subs.some(sub => !Object.keys(subPics).includes(sub))) {
      let data = {};
      subs.forEach(sub => {
        if (subPics[sub]) data[sub] = subPics[sub]
        else data[sub] = ""
      })
      postData("/billing_api/get_subscriber_pics", data, setSubPics, setError)
    }
  }, [subs])

  const LoadData = () => {
    // get current subs
    postData(`/billing_api/get_subscribers/${rule.id}`, {}, setSubs, setError, { setLoading: setLoadingSubs })
    // get azure ad emails
    postData("/billing_api/get_subscriber_options", {}, setEmailOpts, setError, { setLoading: setLoadingOpts })
  }

  const handleNewSub = (email) => {
    if (!loadingAdd) {
      postData(`/billing_api/add_subscriber/${rule.id}`, { email: email }, setSubs, setError, { setLoading: setLoadingAdd });
      setNewSub(null);
    }
  }

  const handleRemoveSub = (email) => {
    if (!loadingAdd) {
      postData(`/billing_api/delete_subscriber/${rule.id}`, { email: email }, setSubs, setError, { setLoading: setLoadingAdd });
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name,
    };
  }

  return (
    <div>
      {
        user.role.some(role => ["ADMIN", "BILLING_ERROR_ADMIN"].includes(role)) &&
        <div>
          Add new subscribers to the rule. A subscriber will receive email updates whenever new errors show
          up for a given rule.
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col md="5">
              <WindowedSelect
                options={labelValueSerializer(filteredEmailOpts, "displayName", "mail")}
                isLoading={loadingOpts}
                isOptionDisabled={option => option.value === author.email}
                placeholder="Add a new subscriber..."
                value={newSub ? newSub : null}
                onChange={(e) => setNewSub(e)}
              />
            </Col>
            <Col md="auto">
              <Button
                onClick={() => handleNewSub(newSub.value)}
                disabled={loadingAdd}
              >
                <FaIcons.FaPlus />
              </Button>
            </Col>
          </Row>
        </div>
      }
      <br />
      <h3>All Subscribers</h3>
      This Billing Rule has {subs.length} subscriber{subs.length === 1 ? "" : "s"}. Author is automatically subscribed and cannot be unsubscribed.
      {!subs.includes(user.email) && user.email && user.uid !== rule.created_by &&
        <div>
          You are not subscribed to this rule. <a href="#" onClick={() => handleNewSub(user.email)}>Click here to subscribe.</a>
        </div>
      }
      {subs.includes(user.email) && user.email && user.uid !== rule.created_by &&
        <div>
          You are already subscribed to this rule. <a href="#" onClick={() => handleRemoveSub(user.email)}>Click here to unsubscribe.</a>
        </div>
      }
      <hr />
      {subs.length === 0 &&
        <div style={{ textAlign: "center" }}>
          <h3>
            No subscribers... yet.
          </h3>
          <div style={{ color: "gray", textAlign: "center" }}>
            Subscribers will be listed here upon subscribing to the rule.
          </div>
          <GiIcons.GiNothingToSay style={{ fontSize: "10rem", color: "lightgray" }} />
        </div>
      }
      {subs.map((sub, idx) => {
        let firstInitial = sub[0].toUpperCase();
        let lastInitial = sub.split("@")[0].includes(".") ? sub.split(".")[1][0].toUpperCase() : ""
        let name = `${firstInitial}${lastInitial}`

        return (
          <Row key={idx} style={{ display: "flex", alignItems: "center", paddingBottom: "1rem" }}>
            <Col md="auto">
              <Avatar {...stringAvatar(name)} src={subPics[sub] ? `data:image/jpeg;base64,${subPics[sub]}` : ""}>{name}</Avatar>
            </Col>
            <Col>
              {sub}
            </Col>
            {(user.role.some(role => ["ADMIN", "BILLING_ERROR_ADMIN"].includes(role)) || user.uid === rule.created_by) &&
              <Col>
                <FaIcons.FaTrashAlt
                  className="red-on-hover"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRemoveSub(sub)}
                />
              </Col>
            }
          </Row>
        )
      })}
    </div>
  )
}