import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import getData from "../../Utilities/getData";
import { postData } from "../../Utilities/apiRequests";
import { GetInfusionColumns } from "../../Utilities/Columns";
import { ErrorAlert, LoadingAlert } from "../../Components/Alerts";
import { Table } from "../../Components/Table";
import { InfusionForm } from "./InfusionForm";
import NotificationCard from "../../Components/Notification";

const InfusionPage = () => {
  const initialState = { result: "", data: [] };
  const [responseData, setResponseData] = useState(initialState);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [insurance, setInsurance] = useState([]);
  const [medication, setMedication] = useState([]);

  const [showUnits, setShowUnits] = useState(false);

  useEffect(() => {
    document.title = "Stream | Infusion"
    let isSubscribed = true;
    if (isSubscribed) {
      getData(setInsurance, setError, "/infusion_api/get_insurances");
      getData(setMedication, setError, "/infusion_api/get_medications");
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    if (error !== null) {
      if (error.code === 400) {
        setShowUnits(true)
      }
    }
  }, [error]);


  const handleSubmit = (values) => {
    setResponseData(initialState);
    postData("/infusion_api/predict", values, setResponseData, setError, {
      setLoading: setLoading,
      history: history,
    });
    setError(null)
  };

  return (
    <div className="mycontainer">
      <InfusionForm
        handleSubmit={handleSubmit}
        medication={medication}
        insurance={insurance}
        showUnits={showUnits}
      />
      {(responseData.result.valuable === 0 && (
        <NotificationCard
          cardTitle={"Specialty Pharmacy"}
          cardBody={
            "Recent data suggests that this infusion is NOT profitable. Bill a specialty pharmacy."
          }
        />
      )) ||
        (responseData.result.valuable === 1 && (
          <NotificationCard
            severity={"success"}
            cardTitle={"Buy and Bill"}
            cardBody={"Recent data suggests that this infusion is profitable."}
          />
        )) ||
        (responseData.result.valuable === 2 && (
          <NotificationCard
            severity={"warning"}
            cardTitle={"Warning: No data found"}
            cardBody={`The estimated cost of this infusion is $${parseFloat(responseData.result.total_price).toFixed(2)}.
          \nFurther research is recommended.`}
          />
        )) ||
        (responseData.result.valuable === 3 && (
          <NotificationCard
            severity={""}
            cardTitle={"Buy and Bill"}
            cardBody={`The estimated cost of this infusion is $${parseFloat(responseData.result.total_price).toFixed(2)}.
          \nNot enough recent data exists for this insurance-procedure combination. Models suggest a buy and bill protocol.`}
          />
        ))}
      <ErrorAlert error={error} setToggleAlert={setError} />
      <LoadingAlert isLoading={loading} />
      <Collapse in={responseData.data.length > 0}>
        <div>
          <Table data={responseData.data} columns={GetInfusionColumns()} />
        </div>
      </Collapse>
    </div>
  );
};

export default InfusionPage;
