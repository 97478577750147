import { MDBBtn as Button } from "mdbreact";

function Popup({ show, handleTrue, handleFalse }) {
  if (show === "delete") {
    return (
      <div>
        <p>Are you sure you want to delete?</p>
        <Button
          className="confirm"
          color="cls"
          onClick={() => {
            handleFalse(false);
          }}
        >
          No
        </Button>
        <Button
          className="confirm"
          color="cls-danger"
          onClick={() => {
            handleTrue();
          }}
        >
          Yes
        </Button>
      </div>
    );
  } else if (show === "edit") {
    return (
      <div>
        <p>Are you sure you want to edit?</p>
        <Button
          className="confirm"
          color="cls"
          onClick={() => {
            handleFalse(false);
          }}
        >
          No
        </Button>
        <Button
          className="confirm"
          color="cls-danger"
          variant="danger"
          onClick={() => {
            handleTrue();
          }}
        >
          Yes
        </Button>
      </div>
    );
  }
}

export default Popup;
