import React, { useState, useContext, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { TabList, TabPanel, Tab, Tabs } from "react-tabs";
import { UserContext } from "../../App";
import * as IoIcons from "react-icons/io";
import ChangePasswordPage from "./ChangePasswordPage";
import ChangeEmailPage from "./ChangeEmailPage";
import ChangeNamePage from "./ChangeNamePage";
import ChangeRolePage from "./ChangeRolePage";
import OverviewPage from "./OverviewPage";
import HomePage from "../HomePage/HomePage";
import { postData } from "../../Utilities/apiRequests";

const ProfilePage = () => {
  const { user } = useContext(UserContext);
  const [tabIndex, setTabIndex] = useState(0);
  const [notifs, setNotifs] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!notifs) {
      postData("/profile_api/get_requested_roles", "role", setNotifs, setError);
    }
  }, [notifs]);

  return (
    <div className="mycontainer">

      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>
            {" "}
            <IoIcons.IoMdHome color="#173070" /> Home{" "}
          </Tab>
          <Tab>
            {" "}
            <IoIcons.IoMdPerson color="#173070" /> Profile{" "}
            <NotificationIcon user={user} notif={notifs} />{" "}
          </Tab>
          <Tab>
            {" "}
            <IoIcons.IoMdSettings color="#173070" /> Settings{" "}
          </Tab>
        </TabList>

        <TabPanel>
          <HomePage />
        </TabPanel>
        <TabPanel>
          <OverviewPage />
        </TabPanel>
        <TabPanel>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <IoIcons.IoMdLock color="#173070" /> Change Password
              </Accordion.Header>
              <Accordion.Body>
                <ChangePasswordPage />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <IoIcons.IoMdMail color="#173070" /> Change Email
              </Accordion.Header>
              <Accordion.Body>
                <ChangeEmailPage />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <IoIcons.IoIosPerson color="#173070" /> Change Name
              </Accordion.Header>
              <Accordion.Body>
                <ChangeNamePage />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <IoIcons.IoIosRibbon color="#173070" /> Request Role Change
              </Accordion.Header>
              <Accordion.Body>
                <ChangeRolePage />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </TabPanel>
      </Tabs>
    </div>
  );
};

const NotificationIcon = ({ user, notif }) => {
  if ((user.role.includes("ADMIN")) & (notif !== false && notif.length > 0)) {
    return <IoIcons.IoMdNotifications color="red" />;
  } else {
    return null;
  }
};

export default ProfilePage;
