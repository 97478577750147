import { useState, useContext } from "react";
import { Form, Row, Col, Spinner, Collapse } from "react-bootstrap";
import { MDBBtn, MDBInput } from "mdbreact";
import Select from "react-select";
import { checkPasswordStrength } from "../../Utilities/LoginUtil";
import * as IoIcons from "react-icons/io";
import { useForm } from "../../Utilities/useForm";
import { UserContext, roleOptions } from "../../App";

const RoleReference = ({ show }) => {
  return (
    <Collapse in={show}>
      <div className="info-card">
        {roleOptions.map((role, idx) =>
          <div key={idx}>
            <strong>{role.label}</strong>: {role.desc}
          </div>
        )}
      </div>
    </Collapse>
  )
}

export const ChangeRoleForm = ({ onFormSubmit, verified, loading }) => {
  const initialState = {
    request: [],
    reason: "",
  };
  const filteredOptions = ["EXTERNAL", "UNVERIFIED"]
  const [formData, handleChange, setFormData] = useForm(initialState);
  const [showRef, setShowRef] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    onFormSubmit(formData);
    setFormData(initialState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Request a role change. </Form.Label>
        <h5>You may select multiple roles.</h5>
        <a href="#" onClick={() => setShowRef(!showRef)}>Don't know what roles to select? Click to {showRef ? 'hide' : 'show'}.</a>
        <RoleReference show={showRef} />
        <h4>WARNING: You will be REPLACING your existing roles if you are approved. Select all roles that you wish to have.</h4>
        <Row>
          <Col md="4">
            <Select
              isMulti
              placeholder="Desired Roles..."
              name="request"
              id="request"
              // value={roleOptions.filter(option => option.value === formData.request)}
              options={roleOptions.filter(x => !filteredOptions.includes(x.value))}
              onChange={(e) => handleChange(e, "request", true)}
              isDisabled={!verified}
            ></Select>
          </Col>
        </Row>
        <Row>
          <Col>
            <MDBInput
              type="textarea"
              name="reason"
              label="Enter a reason for this request"
              value={formData.reason}
              rows={4}
              onChange={handleChange}
              disabled={!verified || loading}
            ></MDBInput>
          </Col>
          <Col />
        </Row>
        <MDBBtn color="cls" type="submit" value="" disabled={!verified}>
          {loading && <Spinner animation="grow" variant="light" />}
          {!loading ? "Submit" : " Submitting..."}
        </MDBBtn>
      </Form.Group>
    </Form>
  );
};

export const ChangePasswordForm = ({
  onFormSubmit,
  setShowGuidelines,
  showGuidelines,
}) => {
  const { user } = useContext(UserContext);
  const initialState = {
    password: "",
    confirmPassword: "",
    curPassword: "",
  };
  const [formData, handleChange, setFormData] = useForm(initialState);
  const [showPwMatch, setShowPwMatch] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    onFormSubmit(formData);
    setFormData(initialState);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Change your password.</Form.Label>
        <Row>
          <Col>
            <Form.Control
              type="password"
              name="curPassword"
              placeholder="Current Password"
              required
              value={formData.curPassword}
              onChange={handleChange}
            />
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              type="password"
              name="password"
              placeholder="New Password"
              required
              value={formData.password}
              onChange={handleChange}
              onFocus={() => setShowGuidelines(true)}
            />
          </Col>
          <Col>
            <Form.Control
              type="password"
              name="confirmPassword"
              placeholder="Confirm New Password"
              required
              value={formData.confirmPassword}
              onChange={handleChange}
              onFocus={() => {
                setShowPwMatch(true);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {showGuidelines &&
              (checkPasswordStrength(formData.password, user) !== "" ? (
                <div className="error pwerror">
                  {checkPasswordStrength(formData.password, user)}
                </div>
              ) : (
                <IoIcons.IoMdCheckmarkCircle color="green" />
              ))}
          </Col>
          <Col>
            {showPwMatch &&
              formData.confirmPassword !== "" &&
              (formData.password !== formData.confirmPassword ? (
                <IoIcons.IoMdCloseCircle color="red" />
              ) : (
                <IoIcons.IoMdCheckmarkCircle color="green" />
              ))}
          </Col>
        </Row>
        <MDBBtn color="cls" type="submit" value="Register">
          Submit
        </MDBBtn>
      </Form.Group>
    </Form>
  );
};

export const ChangeEmailForm = ({ onFormSubmit }) => {
  const { user } = useContext(UserContext);

  const initialState = {
    newEmail: "",
    curPassword: "",
  };
  const [formData, handleChange, setFormData] = useForm(initialState);

  const handleSubmit = (event) => {
    event.preventDefault();
    onFormSubmit(formData);
    setFormData(initialState);
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Change your Email.</Form.Label>
        <Row>
          <Col>Current Email: {user.email}</Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              type="email"
              name="newEmail"
              placeholder="New Email"
              required
              value={formData.newEmail}
              onChange={handleChange}
            />
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              type="password"
              name="curPassword"
              placeholder="Enter your password"
              required
              value={formData.curPassword}
              onChange={handleChange}
            />
          </Col>
          <Col />
        </Row>
        <MDBBtn color="cls" type="submit" value="Register">
          Submit
        </MDBBtn>
      </Form.Group>
    </Form>
  );
};

export const ChangeNameForm = ({ onFormSubmit }) => {
  const { user } = useContext(UserContext);
  const initialState = {
    firstName: "",
    lastName: "",
    curPassword: "",
  };
  const [formData, handleChange, setFormData] = useForm(initialState);
  const handleSubmit = (event) => {
    event.preventDefault();
    onFormSubmit(formData);
    setFormData(initialState);
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Change your name.</Form.Label>
        <Row>
          <Col xs={3}>
            <Form.Control
              type="name"
              name="firstName"
              placeholder={user.first_name}
              required
              value={formData.firstName}
              onChange={handleChange}
            />
          </Col>
          <Col xs={3}>
            <Form.Control
              name="lastName"
              type="name"
              placeholder={user.last_name}
              required
              value={formData.lastName}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Control
              type="password"
              name="curPassword"
              placeholder="Enter your password"
              required
              value={formData.curPassword}
              onChange={handleChange}
            />
          </Col>
          <Col />
        </Row>
        <MDBBtn color="cls" type="submit" value="Register">
          Submit
        </MDBBtn>
      </Form.Group>
    </Form>
  );
};
