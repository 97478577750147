import { useState, useContext } from "react";
import { UserContext, roleOptions } from "../App";
import { postData } from "../Utilities/apiRequests";
import Select from "react-select";

import { Form, Row, Col, Card, CloseButton } from "react-bootstrap";
import { MDBBtn } from "mdbreact";
import getData from "../Utilities/getData";
import commonPassword from "common-password-checker";
import { checkPasswordStrength } from "../Utilities/LoginUtil";
import * as IoIcons from "react-icons/io";
import { ErrorAlert, LoadingAlert } from "./Alerts";

const validDomains = [
  "@cls.health",
  "@ncpmanage.com",
  "@ombcs.com",
  "@omegambs.com",
  "@theheightshospital.com",
  "@tranquilconsulting.com",
  "@tranquilityresearch.com"
]

export function RegisterUserForm({ setMsg, msg, Login, setSignUp }) {
  const isAdmin = Login === undefined;
  const initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "UNVERIFIED",
  };
  const { setUser } = useContext(UserContext);
  const [values, setValues] = useState(initialFormState);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showGuidelines, setShowGuidelines] = useState(false);
  const [showPwMatch, setShowPwMatch] = useState(false);

  const handleChange = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };
  // Handles form submission
  const handleSubmit = (event) => {
    // Prevents showing inputted data in the browser url
    event.preventDefault();

    if (values.password !== values.confirmPassword) {
      setError("Passwords don't match.");
    } else if (checkPasswordStrength(values.password, values) !== "") {
      setError("Please follow the password guidelines.");
    } else if (commonPassword(values.password)) {
      setError("Don't use common passwords like 'password'.");
    } else if (!validDomains.some(domain => values.email.includes(domain))) {
      setError(`Email domain '${values.email.split("@")[1]}' is invalid or not whitelisted.`);
    } else {
      // Calls SignUp function in Login Page
      SignUp(values);
      // Empties/Resets form on submission
      setValues(initialFormState);
      setShowGuidelines(false);
    }
  };

  const SignUp = () => {
    // Checks if user is logged in
    getData(setUser, setMsg, "/auth_api/is_logged_in");

    if (isAdmin)
      postData("/admin_api/sign-up/admin", values, setMsg, setError, {
        setLoading: setLoading,
      });
    else {
      postData("/admin_api/sign-up/user", values, setMsg, setError, {
        successCallback: () =>
          Login({ email: values.email, password: values.password }, true),
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Card.Header>
          <Row>
            <Col>
              <h2 className="form-signin-heading">Register User</h2>
            </Col>
            <Col md="1">
              {!isAdmin &&
                <CloseButton disabled={isAdmin} className="header-close" onClick={() => setSignUp(false)}></CloseButton>
              }
            </Col>
          </Row>
        </Card.Header>
        <Col>
          <Form.Label className="registerForm" htmlFor="firstName">
            First Name:
          </Form.Label>
          <Form.Control
            type="firstName"
            name="firstName"
            className="form-control"
            placeholder="First Name"
            required
            value={values.firstName}
            onChange={(e) => handleChange(e)}
          />
        </Col>
        <Col>
          <Form.Label className="registerForm" htmlFor="lastName">
            Last Name:
          </Form.Label>
          <Form.Control
            type="lastName"
            name="lastName"
            className="form-control"
            placeholder="Last Name"
            required
            value={values.lastName}
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label className="registerForm" htmlFor="email">
            Email:
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            className="form-control"
            placeholder="Email Address"
            required
            value={values.email}
            onChange={(e) => handleChange(e)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label className="registerForm" htmlFor="password">
            Password:
          </Form.Label>
          <Form.Control
            type="password"
            name="password"
            className="form-control"
            placeholder="Password"
            required
            value={values.password}
            onChange={(e) => handleChange(e)}
            onFocus={() => setShowGuidelines(true)}
          />
        </Col>
      </Row>
      <Row style={{}}>
        <Col>
          {showGuidelines &&
            (checkPasswordStrength(values.password, values) !== "" ? (
              <div className="error pwerror">
                {checkPasswordStrength(values.password, values)}
              </div>
            ) : (
              <IoIcons.IoMdCheckmarkCircle color="green" />
            ))}
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label className="registerForm" htmlFor="confirmPassword">
            Confirm Password:
          </Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            className="form-control"
            placeholder="Confirm Password"
            required
            value={values.confirmPassword}
            onChange={(e) => handleChange(e)}
            onFocus={() => {
              setShowPwMatch(true);
            }}
          />
        </Col>
      </Row>
      <Row style={{}}>
        <Col>
          {showPwMatch &&
            values.confirmPassword !== "" &&
            (values.password !== values.confirmPassword ? (
              <IoIcons.IoMdCloseCircle color="red" />
            ) : (
              <IoIcons.IoMdCheckmarkCircle color="green" />
            ))}
        </Col>
      </Row>
      {isAdmin ? (
        <Row>
          <Col>
            <Form.Label className="registerForm">
              Roles:
              <Select
                isMulti
                name="role"
                options={roleOptions}
                className="form-control-selection"
                onChange={(e) => handleChange({ target: { value: e.map(x => x.value), name: "role" } })}
              />
            </Form.Label>
          </Col>
        </Row>
      ) : null}

      <MDBBtn color="cls" type="submit" value="Register">
        Register
      </MDBBtn>
      <ErrorAlert error={error} setToggleAlert={setError} />
      <LoadingAlert isLoading={loading} />
    </Form>
  );
}

export function RegisterUserFormCard({ setMsg, msg, Login, setSignUp }) {
  return (
    <Card>
      <Card.Body>
        <RegisterUserForm setMsg={setMsg} msg={msg} Login={Login} setSignUp={setSignUp} />
      </Card.Body>
    </Card>
  );
}