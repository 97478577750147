import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import getData from "../../../Utilities/getData";
import { LogoutDiv } from "./LogoutDiv";
import { getCookie } from "../../../Utilities/LoginUtil";
import { UserContext } from "../../../App";
import { postData } from "../../../Utilities/apiRequests";

function LogoutPage() {
  const { setUser } = useContext(UserContext);

  const [error, setError] = useState("");
  // Used to redirect to another Page
  const history = useHistory();
  const opts = {
    method: "GET",
    credentials: "same-origin",
    headers: {
      "X-CSRF-TOKEN": getCookie("csrf_access_token"),
    },
  };
  ///////////////////////////////////
  // CALLED ON LOGOUT BUTTON CLICK //
  ///////////////////////////////////
  function Logout() {
    // Checks if user is already logged out
    // Requests the backend to logout the user
    fetch("/auth_api/logout", opts).then((response) => {
      if (response.status === 200) {
        // Redirects to Login Page
        getData(setUser, setError, "/auth_api/is_logged_in");
        history.push("/");
      } else {
        // Catches and Sets error message
        response.json().then((data) => {
          setError(JSON.stringify(data));
        });
        alert("Error Logging Out");
      }
    });
    //ELSE (IF NOT LOGGED IN) Redirects to Login Page
  }

  // Conditionally renders the Logout Page if logged in
  // ELSE renders a Please Login message

  return (
    <div className="mycontainer">
      <LogoutDiv Logout={Logout} error={error} />
    </div>
  );
}

export default LogoutPage;
