import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { MDBBtn } from "mdbreact";
import * as IoIcons from "react-icons/io";
import { roleOptions } from "../../App";
import { postData } from "../../Utilities/apiRequests";

const AdminOverviewContent = () => {
  const [msg, setMsg] = useState("");
  const [alertTable, setAlertTable] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (alertTable.length === 0) {
      postData(
        "/profile_api/get_requested_roles",
        { role: "role" },
        setAlertTable,
        setError,
        {
          setLoading: setLoading,
        }
      );
    }
  }, []);

  useEffect(() => {
    if (msg.response === "Success") {
      const rowToDelete = alertTable.find(
        (e) => e.alertID === msg.alertID
      );
      deleteRow(alertTable.indexOf(rowToDelete));
      setMsg("");
    }
  }, [error, msg, alertTable]);

  const handleSubmit = (row, response) => {
    setLoading(true);
    const data = {
      uid: row.uid,
      role: row.requestedRole,
      alertID: row.alertID,
      response: response,
    };
    postData("/profile_api/set_role", data, setMsg, setError, { setLoading: setLoading });
  };
  const deleteRow = (index) => {
    alertTable.splice(index, 1);
  };
  const renderRoleRequests = (request, index) => {
    return (
      <tr key={index}>
        <td>{request.uid}</td>
        <td>{request.name}</td>
        <td>{request.requestedRole.join(", ")}</td>
        <td>{request.reason}</td>
        <td>{new Date(request.date + "-6").toLocaleDateString()}</td>
        <td>
          <Row>
            <Col className="center-col-text">
              <MDBBtn
                onClick={() => handleSubmit(request, "approve")}
                id="approve"
                className="small"
                color="cls"
              >
                {loading && <Spinner animation="grow" variant="light" />}
                {!loading ? <IoIcons.IoMdCheckmark /> : ""}
              </MDBBtn>
            </Col>
            <Col className="center-col-text">
              <MDBBtn
                onClick={() => handleSubmit(request, "deny")}
                id="deny"
                className="small"
                color="cls-danger"
              >
                {loading && <Spinner animation="grow" variant="light" />}
                {!loading ? <IoIcons.IoMdClose /> : ""}
              </MDBBtn>
            </Col>
          </Row>
        </td>
      </tr>
    );
  };
  return (
    <div>
      {alertTable.map(renderRoleRequests).length !== 0 && (
        <table>
          <thead>
            <tr>
              <th>UID</th>
              <th>Name</th>
              <th>Requested Role</th>
              <th>Reason</th>
              <th>Date</th>
              <th>Approve/Deny</th>
            </tr>
          </thead>
          <tbody>
            {alertTable.map(renderRoleRequests).length !== 0 ? (
              alertTable.map(renderRoleRequests)
            ) : (
              <tr />
            )}
          </tbody>
        </table>
      )}
      {loading && <Spinner animation="border" />}
      {alertTable.map(renderRoleRequests).length === 0 && !loading &&
        "You're all caught up!"}
    </div>
  );
};
export default AdminOverviewContent;
