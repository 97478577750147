import React from "react";
import Select from "react-select";
import { roleOptions } from "../App";
import { handleEdits } from "../Utilities/TableUtilities";
import { Link } from "react-router-dom";
import { Badge, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
export const EditableCell = ({
  value: initialValue,
  column: { id },
  row: { original, isSelected },
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const uid = original.id;
  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    handleEdits({ uid, [id]: value });
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  if (isSelected)
    return <input value={value} onChange={onChange} onBlur={onBlur} />;
  else return value;
};

export const EditablePasswordCell = ({
  value: initialValue,
  column: { id },
  row: { original, isSelected },
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const uid = original.id;

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    handleEdits({ uid, [id]: value });
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  if (isSelected)
    return (
      <input
        value={value}
        type="password"
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  else return value;
};

export const EditableSelectionCell = ({
  value: initialValue,
  column: { id },
  row: { original, isSelected },
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const uid = original.id;

  const onChange = (e) => {
    e = e.map(x => x.value)
    setValue(e);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    handleEdits({ uid, [id]: value });
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  if (isSelected)
    return (
      <Select
        isMulti
        options={roleOptions}
        onChange={onChange}
        onBlur={onBlur}
        value={value.map(x => { return { label: x, value: x } })}
        placeholder="Select..."
      />
    );
  else return Array.isArray(value) ? value.join(", ") : value;
};

export const EditableEmailCell = ({
  value: initialValue,
  column: { id },
  row: { original, isSelected },
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);
  const uid = original.id;

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    handleEdits({ uid, [id]: value });
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  if (isSelected)
    return (
      <input type="email" value={value} onChange={onChange} onBlur={onBlur} />
    );
  else return value;
};

export const LinkCell = ({
  namespace,
  querystr,
  row: { original },
  value: initialValue,
}) => {
  // We need to keep and update the state of the cell normally
  return (
    <Link className="link" to={`${namespace}/${original[querystr]}`}>
      {initialValue}
    </Link>
  );
};

export const OnCallDateCell = ({
  value: initialValue,
  column: { id },
  row: { original },
}) => {
  // We need to keep and update the state of the cell normally
  const start = original.start_time.split(" ");
  const end = original.end_time.split(" ");
  const s_time = parseInt(start[0][0] + start[0][1]);
  const e_time = parseInt(end[0][0] + end[0][1]);
  if (
    ((start[1] === "PM" && end[1] === "AM") ||
      (start[1] === end[1] && s_time >= e_time)) &&
    new Date().toLocaleDateString() ===
    new Date(original.end_date).toLocaleDateString()
  ) {
    return (
      <Link className="link" to={`doctor / ${original.doctor_id}`}>
        Yesterday at {initialValue}
      </Link>
    );
  } else {
    return (
      <Link className="link" to={`doctor / ${original.doctor_id}`}>
        {initialValue}
      </Link>
    );
  }
};

export const MoreInfoHoverCell = ({
  value: initialValue,
  row: { original },
}) => {
  const popover = (
    <Popover className="in">
      <Popover.Header>Descriptions</Popover.Header>
      <Popover.Body>
        <div>{original["Diagnosis 1 Description"]}</div>
        <div>{original["Diagnosis 2 Description"]}</div>
        <div>{original["Diagnosis 3 Description"]}</div>
      </Popover.Body>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger={["click", "hover"]}
      placement="right"
      overlay={popover}
    >
      <Row>
        <Col>{initialValue}</Col>
        <Col className="col-2">
          <Badge
            className="badge-i"
            bg="secondary"
            pill
            style={{ cursor: "pointer" }}
          >
            i
          </Badge>
        </Col>
      </Row>
    </OverlayTrigger>
  );
};
