import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../App";
import { Modal, Form, Spinner, Container } from "react-bootstrap";
import { MDBBtn as Button } from "mdbreact";
import { postData } from "../Utilities/apiRequests";
import { SuccessAlert, ErrorAlert } from "./Alerts";

const BugReport = ({ show, setShow }) => {
  const { user } = useContext(UserContext);

  const [contactInfo, setContactInfo] = useState("");
  const [bugReport, setBugReport] = useState("");

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = { contact: contactInfo, report: bugReport, user: user }
    postData("/profile_api/send_bug_report", data, setSuccess, setError,
      {
        setLoading: setLoading
      });
  }

  useEffect(() => {
    if (success === "Success") {
      setContactInfo("");
      setBugReport("");
      setSuccess(null);
      setSuccessMsg("Successfully submitting bug report. You can close this popup.")
    }
    return () => {

    }
  }, [success])

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton> <h1>Submit a bug report</h1> </Modal.Header>

          <Modal.Body>
            <Form.Label>Enter your preferred contact information so we can follow up</Form.Label>
            <Form.Control
              required
              placeholder="Email/Phone number"
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)} />

            <Form.Label>Describe the problem that you're having</Form.Label>
            <Form.Control
              rows={5}
              required
              as="textarea"
              placeholder={`Infinite loading screen, I keep getting a 500 error code, etc...\nPlease be as descriptive as possible.`}
              value={bugReport}
              onChange={(e) => setBugReport(e.target.value)} />
            <SuccessAlert msg={successMsg} setToggleAlert={setSuccessMsg} />
            {/* <ErrorAlert msg={error} setToggleAlert={setError}/> */}
          </Modal.Body>

          <Modal.Footer>
            <Button color="cls" type="submit" disabled={loading}>
              {loading ? <Spinner animation="grow" variant="light" /> && "Submitting" : "Submit"}
            </Button>

          </Modal.Footer>
        </Form>
      </Container>
    </Modal>
  );
};

export default BugReport;
