import { useState, useContext, useEffect } from "react";
import { Row, Carousel, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LoginForm } from "./LoginForm";
import { UserContext } from "./../../../App";
import { RegisterUserForm } from "../../../Components/RegisterUserForm";
import { ForgotPasswordDiv } from "./ForgotPasswordDiv";
import { ErrorAlert, SuccessAlert } from "../../../Components/Alerts";
import { postData } from "../../../Utilities/apiRequests";
import getData from "../../../Utilities/getData";

function LoginPage() {
  const use_mfa = true;

  const { setUser } = useContext(UserContext);

  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [forgotPassword, setForgotPassword] = useState(false);
  const [userData, setUserData] = useState(null)
  const [signedIn, setSignedIn] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [showMFA, setShowMFA] = useState(false);
  const [mfaCode, setMfaCode] = useState(null);
  const [msg, setMsg] = useState("");

  const [index, setIndex] = useState(0);

  useEffect(() => {
    signUp ? setIndex(1) : setIndex(0)
  }, [signUp]);

  // Used to redirect to another Page

  /////////////////////////////////////
  // CALLED ON LOGIN FORM SUBMISSION //
  /////////////////////////////////////
  const Login = (values, isRegister) => {
    // email and password submitted in Login Form
    const doRedirect = history.location.state && history.location.state.from && !isRegister;
    let opts = {
      setLoading: setLoading,
      history: history,
    }
    if (mfaCode || !use_mfa) opts.redirect = doRedirect ? history.location.state.from : "/home";
    postData(
      "/auth_api/login",
      { email: values.email, password: values.password, mfa_code: mfaCode },
      setResponse,
      setError,
      opts
    );
  };

  useEffect(() => {
    if (response && 'mfa_pending' in response && response['mfa_pending']) {
      setShowMFA(true);
      setSuccess("A verification code has been sent to your email. Please enter it in the new field.");
    }
    else {
      if (response) setUser(response);
    }
  }, [response])

  useEffect(() => {
    let isSubscribed = true;
    document.title = "Stream | Log in"
    if (userData === null) {
      getData(setUserData, setError, '/auth_api/is_logged_in')
    } else if (!userData.role.includes("UNAUTHORIZED")) {
      setSignedIn(true);
    } else setSignedIn(false);
    return () => isSubscribed = false;
  }, [userData]);

  useEffect(() => {
    let isSubscribed = true;

    if (signedIn)
      history.push("/home")

    return () => isSubscribed = false;
  }, [signedIn]);


  // Renders NavBar and Login Form with Error message if there is one
  return (
    <div className="mycontainer">
      <Row className="loginForm">
        <Card className="form-signin">
          <Carousel
            indicators={false}
            interval={null}
            activeIndex={index}
            controls={false}>

            <Carousel.Item>
              <LoginForm
                Login={Login}
                ForgotPass={setForgotPassword}
                Signup={setSignUp}
                isLoading={loading}
                showMFA={showMFA}
                setMfaCode={setMfaCode}
              />
            </Carousel.Item>

            <Carousel.Item>
              <RegisterUserForm
                msg={msg}
                setMsg={setMsg}
                Login={Login}
                setSignUp={setSignUp}
              />
            </Carousel.Item>

          </Carousel>
        </Card>
      </Row>
      <Row className="width-35 center">
        <ErrorAlert error={error} setToggleAlert={setError} />
        <SuccessAlert msg={success} setToggleAlert={setSuccess} />
      </Row>
      <ForgotPasswordDiv show={forgotPassword} setShow={setForgotPassword} />
    </div>
  );
}
export default LoginPage;
