import AppNav from "./AppNav";
import { ProviderMatch } from "./ProviderMatch";
import { UnverifiedAlert } from "../../Components/UnverifiedAlert";
import React, { useContext, useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { UserContext } from "../../App";

const HomePage = () => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    document.title = "Stream | Home"
  }, [])

  return (
    <>
      <UnverifiedAlert />
      <Row>
        <Col>
          <Card>
            <Row>
              <Col md="3">
                <img className="home-icons" src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/user-icon.png" title="user icons" />
              </Col>

              <Col>
                <h1 md="9">
                  Hello <span className="blue-text-bold">{user.first_name} {user.last_name}!</span>
                </h1>
                <p>Welcome to <span>CLS Health</span></p>
              </Col>
            </Row>
          </Card>
          <ProviderMatch />
        </Col >
        <Col> <AppNav /> </Col>
      </Row >
    </>
  );
};

export default HomePage;
