import React from "react";
import { Button } from "@mui/material";
import WindowedSelect from "react-windowed-select";
import { labelValueSerializer } from "../../../Utilities/Formaters";
import { postData } from "../../../Utilities/apiRequests";

export const CurdPermissions = ({ row, providers, refresh }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        postData(
          "/admin_api/edit_user_permissions",
          row.original,
          (x) => {
            row.values.Permission =
              "Read:" +
              row.original.read_scope.map((provider) => {
                return (
                  " " + provider["First Name"] + " " + provider["Last Name"]
                );
              }) +
              " | " +
              "Write Unresolve: " +
              row.original.unresolve_scope.map((provider) => {
                return provider["First Name"] + " " + provider["Last Name"];
              }) +
              " | " +
              "Write Resolve: " +
              row.original.resolve_scope.map((provider) => {
                return provider["First Name"] + " " + provider["Last Name"];
              });
            row.toggleRowExpanded();
          },
          (x) => {
            console.log(x);
          }
        );
      }}
      className="row w-100 justify-content-center flex-row p-2"
    >
      <div className="col-md-7 p-2 d-flex flex-column">
        <h4>Read</h4>
        <div>
          <h5>Doctors</h5>
          <WindowedSelect
            isMulti
            options={labelValueSerializer(
              providers,
              ["First Name", "Last Name"],
              "doctorId"
            )}
            value={labelValueSerializer(
              row.original.read_scope,
              ["First Name", "Last Name"],
              "doctorId"
            )}
            onChange={(e) => {
              row.original.read_scope = getCommonComponents(
                providers,
                e,
                "doctorId",
                "value"
              );
              if (
                !getValues(row.original.read_scope, "doctorId").includes("*")
              ) {
                row.original.unresolve_scope = getCommonComponents(
                  row.original.unresolve_scope,
                  e,
                  "doctorId",
                  "value"
                );
                row.original.resolve_scope = getCommonComponents(
                  row.original.resolve_scope,
                  e,
                  "doctorId",
                  "value"
                );
              }
              refresh();
            }}
          />
        </div>

        <Button
          className="mt-4"
          variant="contained"
          color="warning"
          type="submit"
          size="large"
          fullWidth
        >
          Submit
        </Button>
      </div>
      <div className="col-md-5 p-2">
        <h4>Write</h4>
        <h5>Unresolve</h5>
        <WindowedSelect
          isMulti
          options={labelValueSerializer(
            providers,
            ["First Name", "Last Name"],
            "doctorId"
          ).filter(
            (obj) =>
              getValues(row.original.read_scope, "doctorId").includes(
                obj.value
              ) || getValues(row.original.read_scope, "doctorId").includes("*")
          )}
          value={labelValueSerializer(
            row.original.unresolve_scope,
            ["First Name", "Last Name"],
            "doctorId"
          )}
          onChange={(e) => {
            row.original.unresolve_scope = getCommonComponents(
              providers,
              e,
              "doctorId",
              "value"
            );
            refresh();
          }}
        />
        <h5 className="mt-1">
          Resolve
          <WindowedSelect
            isMulti
            options={labelValueSerializer(
              providers,
              ["First Name", "Last Name"],
              "doctorId"
            ).filter(
              (obj) =>
                getValues(row.original.read_scope, "doctorId").includes(
                  obj.value
                ) ||
                getValues(row.original.read_scope, "doctorId").includes("*")
            )}
            value={labelValueSerializer(
              row.original.resolve_scope,
              ["First Name", "Last Name"],
              "doctorId"
            )}
            onChange={(e) => {
              row.original.resolve_scope = getCommonComponents(
                providers,
                e,
                "doctorId",
                "value"
              );
              refresh();
            }}
          />
        </h5>
      </div>
    </form>
  );
};

// Write a function that returns the common components in an array
const getCommonComponents = (
  arr1,
  arr2,
  id1 = "doctorId",
  id2 = "doctorId"
) => {
  let commonComponents = [];
  arr1.forEach((item) => {
    arr2.forEach((item2) => {
      if (item[id1] === item2[id2]) {
        commonComponents.push(item);
      }
    });
  });
  return commonComponents;
};

// const getUncommonComponents = (arr1, arr2, id1 = "id", id2 = "id") => {
//   let uncommonComponents = [];
//   arr1.forEach((item) => {
//     arr2.forEach((item2) => {
//       if (item[id1] !== item2[id2]) {
//         uncommonComponents.push(item);
//       }
//     });
//   });
//   return uncommonComponents;
// };

const getValues = (arr, key) => {
  let values = [];
  arr.forEach((item) => {
    values.push(item[key]);
  });
  return values;
};
