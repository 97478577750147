import React, { useContext, useState } from "react";
import logo from "./../CLS_Logo.png";
// import SideNavigation from "./SideNavigation";
import { UserContext } from "../App";
import { MDBRow } from "mdbreact";
import BugReport from "./BugReport";
import { CustomizedMenus } from "./PopoutMenu";
import * as Fa from "react-icons/fa";
import { IconButton, Tooltip } from "@mui/material";

export const Navigation = () => {
  const { user } = useContext(UserContext);
  const [showBugReport, setShowBugReport] = useState(false);
  return (
    <>
      <header className="navigation">
        <MDBRow className="logo-title">
          <a href={user.email && user.email !== "UNAUTHORIZED" ? "/home" : "/"}>
            <img
              className="cls-logo"
              alt="Clear Lake Specialties Logo"
              draggable={false}
              src={logo}
            />
          </a>
        </MDBRow>

        <nav className="mainnav">
          <Tooltip title={<p className="my-tooltip">Support</p>}>
            <IconButton
              className="nav-icon-button"
              onClick={() => setShowBugReport(true)}
            >
              <Fa.FaQuestionCircle fontSize={"3rem"} />
            </IconButton>
          </Tooltip>

          <CustomizedMenus />
        </nav>
      </header>
      <BugReport show={showBugReport} setShow={setShowBugReport} />
    </>
  );
};

export const PatientNav = () => {
  return (
    <header className="navigation" style={{ justifyContent: "center" }}>
      <MDBRow>
        <img
          style={{ width: "23rem" }}
          alt="Clear Lake Specialties Logo"
          draggable={false}
          src={logo}
        />
      </MDBRow>
    </header>
  )
}
