import { List } from "react-virtualized"
import { CloseButton, Form, Spinner } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";

const MAX_HEIGHT = 600;
const MIN_HEIGHT = 50;

export const VirtualSelect = ({ options, useLabel = false, setMainData, loading, disabled = false, selectedList = [] }) => {
  const ref = useRef(null);

  const [searchTerm, setSearchTerm] = useState([]);
  const [list, setList] = useState([]);

  const [focused, setFocused] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (options.length > 0 && !refresh) {
      setData(options);
      setRefresh(1);
    }
  }, [options])

  useEffect(() => {
    if (refresh)
      setData(options)
  }, [focused])

  // useEffect(() => {
  //   if (selectedList.length === 0) setList([])
  // }, [selectedList])

  useEffect(() => {
    setMainData(list);
  }, [list])

  const onSelect = item => {
    let value = useLabel ? item.label : item.value;
    setSearchTerm(value);
    if (list.length > 0)
      setList((prev) => ([...prev, value]))
    else
      setList([value])
    setFocused(false)
  }

  const handleClose = (item) => {
    setList(list.filter(e => e != item))
  }

  const handleChange = (e) => {
    if (e !== "") {
      setData(options.filter((item) => item.label.toLowerCase().includes(e.toLowerCase())));
    }
    else {
      setData(options);
    }
    setSearchTerm(e);
  }
  return (
    <div
      onBlur={() => setFocused(false)}
    >
      <Form.Control
        ref={ref}
        className="VirtualSearchBar"
        // fullWidth
        // contentEditable
        // value={searchTerm}
        onKeyDown={e => e.key === "Enter" && e.preventDefault()}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={() => setFocused(true)}
        disabled={disabled}
      />

      {focused ?
        data.length ?
          <List
            className="VirtualSearchList"
            width={ref.current.offsetWidth}
            height={data.length < 12 ? MIN_HEIGHT * (data.length ? data.length : 1) : MAX_HEIGHT}
            rowHeight={50}
            rowCount={data.length}
            rowRenderer={rowRenderer(data, onSelect)}
            onBlur={() => setFocused(false)}
          />
          :
          <div className="VirtualSearchList" style={{ width: ref.current.offsetWidth, display: "flex", justifyContent: "center", alignItems: "center", height: MIN_HEIGHT, color: "lightgray" }}>
            {loading ? <>Loading... {" "} <Spinner animation="border" variant="light" /> </> : "No Data"}
          </div>
        : null
      }
      {list.length > 0 ?
        <div className="VirtualItems">
          {list.map((item, idx) =>
            <div key={idx} className="VirtualItemSelected">{item}{" "}<CloseButton onClick={() => handleClose(item)} style={{ fontSize: "1rem" }} /></div>
          )}
        </div> : null
      }
    </div>
  );
}

const renderMenu = (data, onSelect) => () => {
  return (
    <List
      width={500}
      height={600}
      rowHeight={50}
      rowCount={data.length}
      rowRenderer={rowRenderer(data, onSelect)}
    />
  );
};

const rowRenderer = (data, onSelect) => ({ key, index, style }) => {
  const client = data[index];
  return (
    <div className="VirtualElement" key={key} style={style} onMouseDown={onSelect.bind(null, client)}>
      <div>{client.label}</div>
    </div>
  );
};