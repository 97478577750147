import { Tab, Card } from "react-bootstrap"
import { useState, useEffect } from "react"
import { postData } from "../../Utilities/apiRequests"
import { LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line } from "recharts";

export const AnalyticsHome = () => {
  const [portalData, setPortalData] = useState([{ day: 0, value: 0 }]);
  const [extrapolatedData, setExtrapolatedData] = useState([{ day: 0, value: 0 }]);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    postData('payment_api/get_portal_payments', {}, setPortalData, setError, { setLoading: setLoading })
  }, [])

  useEffect(() => {
    if (portalData.length > 1) {
      postData("payment_api/logarithmic_extrapolation", portalData, setExtrapolatedData, setError, { setLoading })
    }
  }, [portalData])

  return (
    <div className="mycontainer">
      <Card>
        <Card.Body>
          <h3>Recent data: ${portalData[portalData.length - 1].value}</h3>
          <GraphComp data={portalData} />
          <h3>Extrapolated data: ${extrapolatedData[extrapolatedData.length - 1].value} over 365 day lifetime</h3>
          <GraphComp data={extrapolatedData} />
        </Card.Body>
      </Card>
    </div>
  )
}

const GraphComp = ({ data }) => {
  return (
    <LineChart width={700} height={250} data={data} style={{}}>
      <CartesianGrid />
      <XAxis dataKey="day" />
      <YAxis />
      <Tooltip formatter={(value) => [`$${value}`, "Total Collected"]} labelFormatter={(value) => `Day ${value}`} />
      {/* <Legend verticalAlign="top" /> */}
      <Line type="monotone" dataKey="value" stroke="#8884d8" />
    </LineChart>
  )
}