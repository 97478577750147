import { getCookie } from "../Utilities/LoginUtil";

const getData = (setData, setError, url, optionalCallback, optionalValue, options = null) => {
  options = options || {};
  const setLoading = options.setLoading || null;
  const successCallback = options.successCallback || null;

  if (optionalValue === undefined) optionalValue = 0;
  if (optionalCallback === undefined)
    optionalCallback = function () {
      return;
    };

  const requireLoading = setLoading !== null;
  if (requireLoading) setLoading(true);

  const controller = new AbortController()
  const timeoutId = setTimeout(() => {
    controller.abort()
    setError("Operation timed out. Refresh the page and try again.")
    if (requireLoading) setLoading(false)
  }, 18000)

  const opts = {
    method: "GET",
    headers: {
      "X-CSRF-TOKEN": getCookie("csrf_access_token"),
    },
    signal: controller.signal
  };
  fetch(url, opts).then((response) => {
    // if POST was successfull
    // Set data recieved to appropriate state
    if (response.status === 200) {
      response.json().then((data) => {
        if (requireLoading) setLoading(false);
        if (successCallback) successCallback();
        setData(data, optionalCallback(optionalValue));
      });
    } else {
      // Catchs & Sets error message
      response.json().then((msg) => {
        if (requireLoading) setLoading(false);
        setError(msg);
      });
    }
    clearTimeout(timeoutId)
  });
};

export default getData;
