import { useState, useEffect, useContext } from "react";
import { postData } from "../../Utilities/apiRequests";
import { Row, Col, Button, Form, Spinner } from "react-bootstrap";
import states from "../../Components/states_titlecase.json";
import { UserContext } from "../../App";
import { CheckCircleOutline } from "@mui/icons-material";

export const OnlinePayment = ({
  patient,
  setSuccess,
  success,
  cart,
  setError,
  setPatient,
  customer = null,
  receipt
}) => {
  const { user } = useContext(UserContext);
  const [expMonth, setExpMonth] = useState("01");
  const [expYear, setExpYear] = useState(String(new Date().getFullYear()));
  // const [error, setError] = useState(null);

  const [stax, setStax] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [items, setItems] = useState([]);

  let totalToPay = cart.reduce(
    (partialSum, a) => partialSum + a.PatientPaid,
    0
  );
  const createLineItems = (cart) => {
    var itemsArr = [];
    for (let item in cart) {
      itemsArr.push({
        id: cart[item].CPTCode,
        item: `${cart[item].FacilityName}: ${cart[item].Description}`,
        details: `${cart[item].ProviderName} ${cart[item]["Diagnosis 1 Description"]
          ? ": " + cart[item]["Diagnosis 1 Description"]
          : ""
          }`,
        quantity: 1,
        price: cart[item].Remaining,
      });
    }
    setItems(itemsArr);
  };
  useEffect(() => {
    createLineItems(cart);
  }, [cart]);
  useEffect(() => {
    const StaxJs = window.StaxJs;
    const s = new StaxJs("CLS-Health-f25559925b0d", {
      price: String(totalToPay),
      number: {
        id: "card-number", // the html id of the div you want to contain the credit card number field
        placeholder: "0000 0000 0000 0000", // the placeholder the field should contain
        style: "height: 1.58rem; width: 100%; font-size: 15px;", // the style to apply to the field
        type: "text", // the input type (optional)
        format: "prettyFormat", // the formatting of the CC number (prettyFormat || plainFormat || maskedFormat)
      },
      cvv: {
        id: "card-cvv", // the html id of the div you want to contain the cvv field
        placeholder: "CVV", // the placeholder the field should contain
        style: "height: 1.58rem; width: 100%; font-size: 15px;", // the style to apply to the field
        type: "text", // the input type (optional)
      },
    });

    setStax(s);
  }, []);

  useEffect(() => {
    if (stax !== null) {
      stax
        .showCardForm()
        .then((handler) => {
          console.log("form was loaded");
          // for quick testing, you can set a test number and test cvv here
          // handler.setTestPan("4111111111111111");
          // handler.setTestCvv("123");
        })
        .catch((err) => {
          console.log("there was an error loading the form: ", err);
        });
      stax.on("card_form_complete", (message) => {
        console.log("card_form_complete", message);
        // activate pay button
        setDisabled(false);
      });

      stax.on("card_form_incomplete", (message) => {
        console.log("card_form_incomplete", message);
        // deactivate pay button
        setDisabled(true);
      });
    }
  }, [stax]);

  const extraDetails = {
    total: totalToPay, //totalToPay,
    firstname: patient.ufname,
    lastname: patient.ulname,
    company: new Date(patient.dob).toISOString().split("T")[0],
    month: expMonth,
    year: expYear,
    phone: patient.phone,
    address_1: patient.address,
    address_2: patient.address2,
    address_city: patient.city,
    address_state: patient.state,
    address_zip: patient.ZIP,
    address_country: "USA",
    send_receipt: true,
    validate: false,
    method: "card",
    meta: {
      lineItems: items
    }
  };
  if (customer) extraDetails["customer_id"] = customer;

  return (
    <>
      <Form
        onChange={(e) => {
          let value = e.target.value;
          if (e.target.id === "patient_state")
            value = states.filter((state) => state.label === e.target.value)[0]
              .value;
          setPatient((prev) => ({ ...prev, [e.target.id]: value }));
        }}
      >
        <Row>
          <Col>
            <Row>
              <Col>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  id="ufname"
                  type="text"
                  placeholder={patient.ufname}
                  defaultValue={patient.ufname}
                />
              </Col>

              <Col>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  id="ulname"
                  type="text"
                  placeholder={patient.ulname}
                  defaultValue={patient.ulname}
                />
              </Col>
            </Row>
            <Row>
              <Form.Label>Phone</Form.Label>
              <Col>
                <Form.Control
                  id="patient_phone_number"
                  type="text"
                  placeholder={patient.phone}
                  defaultValue={patient.phone}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col md={8}>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  id="patient_address"
                  type="text"
                  placeholder={patient.address}
                  defaultValue={patient.address}
                />
              </Col>
              <Col md={4}>
                <Form.Label>Address 2</Form.Label>
                <Form.Control
                  id="patient_address2"
                  type="text"
                  placeholder={patient.address2 || "None"}
                  defaultValue={patient.address2}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>City</Form.Label>
                <Form.Control
                  id="patient_city"
                  type="text"
                  placeholder={patient.city}
                  defaultValue={patient.city}
                />
              </Col>
              <Col>
                <Form.Label>State</Form.Label>
                <Form.Select
                  id="patient_state"
                  defaultValue={"Texas"} //make generic later idc
                  style={{
                    width: "75%",
                    fontSize: "1.4rem",
                    marginLeft: 0,
                    marginTop: "5px",
                  }}
                >
                  {states.map((state) => {
                    return <option key={state.value}>{state.label}</option>;
                  })}
                </Form.Select>
              </Col>
              <Col>
                <Form.Label>ZIP</Form.Label>
                <Form.Control
                  id="patient_zip_code"
                  type="text"
                  placeholder={patient.ZIP}
                  defaultValue={patient.ZIP}
                />
              </Col>
            </Row>
            <Row></Row>
          </Col>
        </Row>
      </Form>
      <hr />

      <form onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Form.Label>Enter Card Information</Form.Label>
          <Row style={{ alignItems: "center" }}>
            <Col md="auto">
              <Form.Label>Card Number: </Form.Label>
            </Col>
            <Col
              md="auto"
              id="card-number"
              style={{
                width: "200px",
                height: "30px",
                border: "1px lightgray solid",
                background: "white",
                borderRadius: 4,
              }}
            ></Col>
            <Col md="auto">
              <Form.Label>Expiration: </Form.Label>
            </Col>
            <Col md="1">
              <Form.Control
                style={{ width: "110%" }}
                placeholder="MM/YY"
                maxLength={5}
                inputMode="numeric"
                onChange={(e) => {
                  if (e.target.value[0] > 1 && e.target.value.length == 1) {
                    e.target.value = `0${e.target.value}/`
                  }
                  else if (e.target.value.length == 2 && e.nativeEvent.inputType !== "deleteContentBackward") {
                    e.target.value = `${e.target.value}/`
                  }
                  setExpMonth(e.target.value.split("/")[0])
                  setExpYear(`20${e.target.value.split("/")[1]}`)
                }}
              />
            </Col>
            <Col md="auto">
              <Form.Label>CVV: </Form.Label>
            </Col>
            <Col
              md="auto"
              id="card-cvv"
              style={{
                width: "50px",
                height: "30px",
                border: "1px lightgray solid",
                background: "white",
                borderRadius: 4,
              }}
            ></Col>
          </Row>
        </Row>
        <hr />

        {/* Make the payment in stax. Post the cart into sql using processpayment api route */}
        <Button
          // style={{ float: "right" }}
          disabled={loading || disabled || success}
          id="paybutton"
          onClick={() => {
            console.log("submitting...");
            setLoading(true);

            stax
              .pay(extraDetails)
              .then((response) => {
                console.log("invoice object:", response);
                console.log(
                  "transaction object:",
                  response.child_transactions[0]
                );
                receipt.invoice_id = response.child_transactions[0].invoice_id
                // on success call api to process payment and then continue
                let data = {
                  cart: cart,
                  patient: patient,
                  validation_code:
                    response.child_transactions[0].payment_method.card_type +
                    ": " +
                    response.child_transactions[0].last_four,
                  transaction_id: response.child_transactions[0].id,
                  invoice_id: response.child_transactions[0].invoice_id,
                  merchantId: user.uid,
                  paymentType: "credit",
                  total: cart.reduce(
                    (prev, cur) => prev + cur.PatientPaid,
                    0.0
                  ),
                  total_collected: response.total_paid,
                };
                console.log(data);
                console.log(response);
                postData(
                  "/payment_api/add_payment_to_db",
                  data,
                  () => { },
                  () => { },
                  {
                    successCallback: () => { },
                  }
                );
                postData("/payment_api/resend_receipt", receipt, () => { }, () => { })
                setLoading(false);
                setSuccess(true);
              })
              .catch((err) => {
                console.log("unsuccessful payment:", err);
                setError(
                  `Could not process payment: ${err.message
                    ? err.message
                    : err.payment_attempt_message
                      ? err.payment_attempt_message
                      : "Make sure your information is correct"
                  }`
                );
                setLoading(false);
              });
          }}
        >
          {loading ? (
            <>
              <Spinner animation="border" />{" "}
            </>
          ) : (
            "Pay"
          )}
        </Button>
      </form>
    </>
  );
};

const SuccessfulPayment = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h2>Payment Successful!</h2>
      <CheckCircleOutline style={{ color: "green", fontSize: "10rem" }} />
      <p>You can safely close this page.</p>
    </div>
  )
}