import React, { useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { UserContext } from "../../App";
import AdminOverviewContent from "./AdminOverviewPage";
import { ErrorAlert, SuccessAlert } from "../../Components/Alerts";
import { UnverifiedAlert } from "../../Components/UnverifiedAlert";
import { MerchantTransactions } from "../PatientPages/MerchantTransactions";

const OverviewPage = () => {
  const { user } = useContext(UserContext);
  const [error, setError] = useState(null);

  const [success, setSuccess] = useState(null);

  return (
    <div>
      <UnverifiedAlert />
      <div>
        Email: {user.email} <br />
        Name: {user.first_name} {user.last_name}
        <br />
        Role: {user.role.join(", ")}
        <br />
      </div>
      <Form>
        <Form.Group>
          <ErrorAlert error={error} setToggleAlert={setError} />
          <SuccessAlert msg={success} setToggleAlert={setSuccess} />
        </Form.Group>
        {user.role.includes("ADMIN") ?
          <AdminOverviewContent /> : null
        }
        {user.role.includes('PATIENT_MANAGER') && (
          <MerchantTransactions range={0} />
        )}
      </Form>
    </div>
  );
};
export default OverviewPage;
