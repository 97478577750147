import React, { useState, useContext, useEffect } from "react";
import { Form, Modal, Alert } from "react-bootstrap";
import { UserContext } from "../App";

import getItem from "../Utilities/getItem";
import { MDBBtn as Button } from "mdbreact";

const VerificationPopup = ({ show, setShow }) => {
  const { user } = useContext(UserContext);
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");
  const [code, setCode] = useState("");

  const [alertToggled, toggleAlert] = useState(false);
  const [alert, setAlert] = useState("");
  const [successToggled, toggleSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    if (error !== "") {
      toggleAlert(true);
      setAlert(error);
    }
    if (msg === "Verified") {
      setCode("");
      toggleSuccess(true);
      setSuccessMsg(
        "Your account has been validated! You can now safely exit this popup and refresh the page."
      );
    }
  }, [error, msg]);

  const generateCode = () => {
    const data = { uid: user.uid, email: user.email };
    getItem("/auth_api/send_verification", data, setMsg, setError);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = { uid: user.uid, email: user.email, code: code };
    getItem("/auth_api/check_verification_role", data, setMsg, setError);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        className="in"
        onShow={() => generateCode()}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h2">Verify your account.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          A verification code has been sent to your email.
          <br />
          Didn't receive one?{" "}
          <a href="#" onClick={() => generateCode()}>
            Click here to resend.
          </a>
          <Form onSubmit={handleSubmit}>
            <Form.Control
              placeholder="123456"
              type="number"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <Button color="cls" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
        {alertToggled && (
          <Alert
            variant="danger"
            className="in"
            dismissible
            onClose={() => toggleAlert(false)}
          >
            {alert}
          </Alert>
        )}
        {successToggled && (
          <Alert
            variant="success"
            className="in"
            dismissible
            onClose={() => toggleSuccess(false)}
          >
            {successMsg}
          </Alert>
        )}
      </Modal>
    </>
  );
};

export default VerificationPopup;
