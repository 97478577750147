import { useForm } from "../../../Utilities/useForm";
import { Card, Col, Form, Row, Tooltip } from "react-bootstrap";
import { MDBBtn as Button } from "mdbreact";
import Select from "react-select";
import { TooltipIcon } from "../../../Components/TooltipIcon";
import { labelValueSerializer } from "../../../Utilities/Formaters";
import { VirtualSelect } from "../../../Components/VirtualSelect";
import { useState, useEffect } from "react";


export const SearchForm = ({ onFormSubmit, provider, medlist, codelist, meds, codes, loading, setMeds, setCodes }) => {
  // Initial values for search criteria
  const initialState = {
    codesToInclude: "",
    codesToExclude: "",
    medsToInclude: "",
    medsToExclude: "",
    fromAge: 0,
    toAge: 99,
    patientSex: ["male", "female"],
    race: "None",
    providerName: [],
    dateTo: "",
    dateFrom: "",
  };

  // Handles changes and storage of values in form (NON-Bool)
  const [values, handleChange] = useForm(initialState);
  const [formData, setFormData] = useState(initialState);

  const [includeMeds, setIncludeMeds] = useState([]);
  const [excludeMeds, setExcludeMeds] = useState([]);
  const [includeCodes, setIncludeCodes] = useState([]);
  const [excludeCodes, setExcludeCodes] = useState([]);

  // Boolean values
  var decriptDate = true;
  if (values.providerName) decriptDate = false;
  else values.dateFrom = values.dateTo = "";

  // Handles form submission
  const handleSubmit = (event) => {
    // Prevents showing inputted data in the browser url
    event.preventDefault();
    // Calls Login function in Login Page
    // Gives function inputted data submitted in the form
    onFormSubmit(values);
  };

  useEffect(() => {
    let tempForm = values;
    // let m = includeMeds.concat(excludeMeds)
    // let c = includeCodes.concat(excludeCodes)

    tempForm['codesToInclude'] = includeCodes
    tempForm['codesToExclude'] = excludeCodes
    tempForm['medsToInclude'] = includeMeds
    tempForm['medsToExclude'] = excludeMeds

    // setMeds(medlist.filter(med => !m.includes(med.label)))
    // setCodes(codelist.filter(code => !c.includes(code.value)))

    setFormData(tempForm)
  }, [includeCodes, includeMeds, excludeCodes, excludeMeds, values])

  useEffect(() => {
    if (includeCodes.length === 0 && includeMeds.length === 0) {
      setExcludeCodes([])
      setExcludeMeds([])
    }
  }, [includeCodes, includeMeds])


  return (
    <Card className="stream-card">
      <Form className="form-search" onSubmit={handleSubmit}>
        <Row style={{ marginBottom: "1rem" }}>
          <h1>Patient Eligibility Research</h1>
        </Row>
        <Row>
          <Col>
            <Form.Label htmlFor="codesToInclude">
              Codes to Include {" "}
              <TooltipIcon content={"List of codes are STRICTLY INCLUSIVE, meaning it will look up patients who have ALL these codes."} />
            </Form.Label>
            <VirtualSelect
              options={codes}
              setMainData={setIncludeCodes}
              loading={loading}
            />
          </Col>
          <Col>
            <Form.Label htmlFor="codesToExclude">
              Codes to Exclude {" "}
              <TooltipIcon content={"List of codes are LOOSELY INCLUSIVE, meaning it will look up patients who have ANY of these codes."} />
            </Form.Label>
            <VirtualSelect
              options={codes}
              setMainData={setExcludeCodes}
              loading={loading}
              disabled={(includeCodes.length === 0 && includeMeds.length === 0)}
            // selectedList={excludeCodes}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label htmlFor="medsToInclude">Medications to Include: </Form.Label>
            <VirtualSelect
              options={meds}
              useLabel={true}
              setMainData={setIncludeMeds}
              loading={loading}
            />
          </Col>
          <Col>
            <Form.Label htmlFor="medsToExclude">Medications to Exclude: </Form.Label>
            <VirtualSelect
              options={meds}
              useLabel={true}
              setMainData={setExcludeMeds}
              loading={loading}
              disabled={(includeCodes.length === 0 && includeMeds.length === 0)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="registerForm">Patient Sex: </Form.Label>
            <Select
              name="patientSex"
              isMulti
              options={[
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ]}
              onChange={(e) => handleChange(e, "patientSex", true)}
              value={[
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
              ].filter((obj) => values.patientSex.includes(obj.value))}
              className="basic-multi-select"
              classNamePrefix="select"
              isClearable
            />
          </Col>
          <Col>
            <Form.Label className="ageRange" htmlFor="fromAge">
              Age From:{" "}
            </Form.Label>
            <Form.Control
              className="form-control"
              type="number"
              step={1}
              name="fromAge"
              id="fromAge"
              value={values.fromAge}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <Form.Label htmlFor="toAge">To: </Form.Label>
            <Form.Control
              className="form-control"
              type="number"
              step={1}
              name="toAge"
              id="toAge"
              value={values.toAge}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label className="registerForm">Provider Name: </Form.Label>
            <Select
              name="providerName"
              options={labelValueSerializer(
                provider,
                "ProviderName",
                "ProviderName"
              )}
              value={labelValueSerializer(
                provider,
                "ProviderName",
                "ProviderName"
              ).filter((obj) => values.providerName.includes(obj.value))}
              onChange={(e) => {
                handleChange(e, "providerName", true);
              }}
              isClearable
              isMulti
            />
          </Col>
          <Col>
            <Form.Label style={{ marginBottom: "1rem" }}>
              Date From:{" "}
            </Form.Label>
            <Form.Control
              type="date"
              className="form-control"
              name="dateFrom"
              value={values.dateFrom}
              onChange={handleChange}
              disabled={decriptDate || formData['providerName'].length === 0}
            />
          </Col>
          <Col>
            <Form.Label style={{ marginBottom: "1rem" }}>Date To: </Form.Label>
            <Form.Control
              type="date"
              className="form-control"
              name="dateTo"
              value={values.dateTo}
              onChange={handleChange}
              disabled={decriptDate || formData['providerName'].length === 0}
            />
          </Col>
        </Row>
        <Button color="cls" type="submit">
          Submit
        </Button>
      </Form>
    </Card>
  );
};
