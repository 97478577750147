////////////////////////////////
var edits = [];
var rowUids = [];
///////////////////////////////

export const rowUidHolder = (rowIDs) => {
  rowUids = rowIDs;
};

export const getRowUid = () => {
  const uids = rowUids;
  rowUids = [];
  return uids;
};

export const getEdits = () => {
  const changes = edits;
  edits = [];
  return changes;
};
export const handleEdits = (edit) => {
  var elementFound = false;
  edits.forEach((element) => {
    if (element.uid === edit.uid) {
      const secondKey = Object.keys(edit)[1];
      element[secondKey] = edit[secondKey];

      elementFound = true;
    }
  });

  if (elementFound === false) edits.push(edit);
};

export const formatEdits = () => {
  const changesMade = getEdits();
  const rowsToChange = getRowUid();
  const formatedChangesMade = [];

  rowsToChange.forEach((row) => {
    changesMade.forEach((changes) => {
      if (changes.uid === row) {
        formatedChangesMade.push(changes);
      }
    });
  });

  return formatedChangesMade;
};
