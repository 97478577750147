import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Card, FormControl, Button, Form, Collapse, Spinner } from "react-bootstrap";
import Select from "react-select";

import { Loader } from "@googlemaps/js-api-loader";
import env from "react-dotenv";
import { postData } from "../../Utilities/apiRequests";
import { ErrorAlert } from "../../Components/Alerts";
import { labelValueSerializer } from "../../Utilities/Formaters";
import "./Maps.css"
import * as FaIcons from "react-icons/fa";

const loader = new Loader({
  apiKey: env.google_api,
  version: "weekly",
  libraries: ["places", "maps", "marker"],

});

export const PublicProviderMatch = () => {
  const [specs, setSpecs] = useState([]);
  const [selectedSpec, setSelectedSpec] = useState("");
  const [ptAddress, setPtAddress] = useState("");
  const [results, setResults] = useState([]);

  const [markers, setMarkers] = useState([]);
  const [center, setCenter] = useState({});

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const placeRef = useRef(null);
  const mapDivRef = useRef(null);

  useEffect(() => {
    postData('/research_api/get_specialties', {}, setSpecs, setError);
  }, [])

  useEffect(() => {
    // Populates Map props after search results are returned
    if (results['response']) {
      let temp = [{
        position: results['position'],
        title: "Patient's Address",
        background: "#ff671d"
      }];
      results['response'].forEach(result => {
        temp.push({
          position: result['position'],
          title: result['facility_name']
        })
      });
      setMarkers(temp);
      setCenter(results['position']);
    }
  }, [results])

  const Search = (event) => {
    event.preventDefault();
    let data = {
      patient_address: ptAddress,
      specialty: selectedSpec
    }
    setResults([]);
    postData('/research_api/provider_match', data, setResults, setError, { setLoading: setLoading })
  }

  return (
    <Form className="mycontainer" onSubmit={(e) => Search(e)}>
      <Card>
        <Card.Header as="h2" style={{ textAlign: "center" }}>Provider Match</Card.Header>
        <Card.Body>
          <p style={{ maxWidth: "unset" }}>
            This tool quickly looks up and lists all the facilities associated with a specialty in relation to an address
          </p>
          <Row>
            <Col>
              <FormControl
                className="address-input"
                placeholder="Zip Code or City Name..."
                ref={placeRef}
                value={ptAddress}
                onChange={(e) => setPtAddress(e.target.value)}
                style={{ borderRadius: "0.5rem", height: "3.6rem" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Select
                placeholder="Select a Specialty..."
                onChange={(e) => setSelectedSpec(e.value)}
                options={labelValueSerializer(specs, "specialtyName", "specialtyName")}
              />
            </Col>
          </Row>
          <br />
          <Button
            disabled={loading || !selectedSpec || !ptAddress}
            type="submit"
          >
            {loading ? <Spinner animation="border" /> : null} Search
          </Button>
          <ErrorAlert setToggleAlert={setError} error={error} />
          <Collapse
            in={results['response'] && results['response'].length > 0}
            onEntered={() => window.scrollTo(0, mapDivRef.current.offsetTop)}
          >
            <div ref={mapDivRef}>
              <hr />
              <MapComponent
                placeRef={placeRef}
                setPtAddress={setPtAddress}
                center={center}
                markers={markers}
              />
              <ResultsList results={results} />
            </div>
          </Collapse>
        </Card.Body>
      </Card>
    </Form>
  )
}

const ResultsList = ({ results }) => {
  const [showDetails, setShowDetails] = useState(null);

  return (
    <>
      {results['response'] && results['response'].map((result, idx) => (
        <div key={idx} id={`facility-${idx}`}>
          <Row>
            <Card
              className="facility-card"
              onClick={() => showDetails === idx ? setShowDetails(null) : setShowDetails(idx)}
              style={{ cursor: "pointer" }}
            >
              <Card.Title style={{ display: "flex", alignItems: "center", fontSize: "3.5rem", color: "#31447c" }}>
                <div
                  dangerouslySetInnerHTML={{ "__html": MarkerItem({ "idx": idx + 1 }).innerHTML }}
                  style={{ marginRight: "1rem" }}
                />
                {result['facility_name']}
              </Card.Title>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className="subtitle" style={{ fontSize: "1.6rem" }}>
                  {result['facility_address']} | {" "}
                  <b>{(result['distance'] / 1609.344).toFixed(2)} miles </b> or {" "}
                  <b>{parseInt(result['time'] / 60)} minutes </b> away
                  <br />
                </div>
                {showDetails === idx ? <FaIcons.FaChevronUp /> : <FaIcons.FaChevronDown />}
              </div>
              <Card.Body>
                <Collapse in={showDetails === idx}>
                  <div>
                    <hr />
                    <Row>
                      <Col md="3">
                        <h3>Doctor(s)</h3>
                      </Col>
                      <Col md="9">
                        <Row style={{ textAlign: "center" }}>
                          <Col>Su</Col>
                          <Col>M</Col>
                          <Col>T</Col>
                          <Col>W</Col>
                          <Col>Th</Col>
                          <Col>F</Col>
                          <Col>S</Col>
                        </Row>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        {result['facility_doctor'].map((doctor, idx) => (
                          <Row key={idx}>
                            <Col md="3">
                              {doctor['name']} <br />
                            </Col>
                            <Col className="schedule-container" md="9">
                              <Row style={{ textAlign: "center" }}>
                                <DoctorSchedule schedule={doctor['worktimes']} />
                              </Row>
                            </Col>
                            {idx < result['facility_doctor'].length - 1 ? <hr /> : ""}
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </Card.Body>
            </Card>
          </Row>
        </div>
      ))}
    </>
  )
}

const MarkerItem = (property) => {
  const content = document.createElement('div');
  const svg = property['idx'] ?
    `<svg class="marker" xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 32 50">
      <path d="m 15 0 C 23.284 0 30 6.716 30 15 C 30 24.5 15 48 15 48 s -15 -23.5 -15 -33 C 0 6.716 6.716 0 15 0 z z z"/>
    </svg>` :
    `<div class="patient-marker"/>`

  content.innerHTML = `
  <div class="marker-container" name="card-marker-${property['idx']}">
    <span class="marker-idx">${property['idx'] ? property['idx'] : ""}</span>
    ${svg}
  </div>
    `
  return content;
}

const MapComponent = ({ placeRef, setPtAddress, center, markers }) => {
  /*  Custom component directly implementing the Google Maps API
      I didn't really like any of the existing React wrapper components so 
      here's a manual implementation. The only necessary library is the Loader which
      handles loading the API scripts into the page with our API Key.
  */

  const [refresh, setRefresh] = useState(0);
  const [localMarkers, setLocalMarkers] = useState([]);

  const mapRef = useRef(null);
  const initialZoom = 12;

  useEffect(() => {
    if (window.google) return;
    // LOAD MAPS API AND PARENT COMPONENTS
    loader.importLibrary("maps").then(({ Map }) => {
      console.log("loading maps")
      const map = new Map(document.getElementById('map'), {
        zoom: initialZoom,
        mapId: env.MAP_ID
      })
      mapRef.current = map;
      setCenter();
    })

    // LOAD PLACES API AND PARENT COMPONENTS
    loader.importLibrary("places").then(({ Autocomplete }) => {
      console.log("loading places")
      const places = new Autocomplete(placeRef.current);
      places.setOptions({ types: ['postal_code', 'locality'] })
      places.setComponentRestrictions({ country: ['us'] })
      places.addListener('place_changed', () => {
        setPtAddress(places.getPlace().formatted_address);
      })
    })
  }, [])

  useEffect(() => {
    if (localMarkers && mapRef.current)
      fitBoundsToMarkers();
  }, [localMarkers])

  useEffect(() => {
    // Changes the maps center coordinates when the passed in prop is changed
    setCenter();
  }, [center, mapRef.current])

  useEffect(() => {
    if (!(mapRef.current && markers !== undefined)) return;
    if (markers.length > 0) {
      let temp = [];
      setAllMarkers(null);
      mapRef.current.setZoom(initialZoom);
      for (let i = 0; i < markers.length; i++) {
        markers[i]['idx'] = i;
        const marker = new window.google.maps.marker.AdvancedMarkerElement({
          map: mapRef.current,
          position: markers[i].position,
          title: markers[i].title,
          content: MarkerItem(markers[i])
        })
        marker.addListener("click", () => {
          scrollToRef(i - 1)
        })
        temp.push(marker);
      }
      setLocalMarkers(temp);
    }
    else {
      setAllMarkers(null);
      setLocalMarkers([]);
    }
  }, [markers, mapRef.current])

  const setCenter = () => {
    if (center['lat'] && mapRef.current) {
      mapRef.current.setCenter(center);
      setRefresh(refresh + 1);
    }
  }

  const setAllMarkers = (map) => {
    for (let i = 0; i < localMarkers.length; i++) {
      localMarkers[i].setMap(map);
    }
  }

  function fitBoundsToMarkers() {
    var bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < localMarkers.length; i++) {
      bounds.extend(localMarkers[i].position);
    }
    mapRef.current.fitBounds(bounds);
    mapRef.current.setZoom(mapRef.current.getZoom() - 1);
  }

  function scrollToRef(idx) {
    if (idx < 0) return
    window.scrollTo(0, document.getElementById(`facility-${idx}`).offsetTop)
    let marker = document.getElementsByName(`card-marker-${idx + 1}`)[1]
    marker.addEventListener("animationend", () => {
      marker.classList.remove("wiggle-marker")
    })
    marker.classList.add("wiggle-marker")
  }

  return (
    <div id='map' style={{ width: '100%', height: '400px' }} />
  )
}

const DoctorSchedule = ({ schedule }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (schedule) {
      console.log(schedule)
      let temp = [];
      // group each unique weekday value such that the final result looks like
      // {weekday: x, times: [{starttime: x, endtime: x}]}
      for (let i = 0; i < 7; i++) {
        temp.push({
          weekday: i + 1,
          times: []
        })
        schedule.filter(e => e['weekday'] === i + 1).forEach(time => {
          temp[i]['times'].push({ starttime: formatTime(time.starttime), endtime: formatTime(time.endtime) })
        })
      }
      setData(temp);
    }
  }, [schedule])

  function formatTime(inputTime) {
    const [hours, minutes] = inputTime.split(':');
    const containsAMorPM = /am|pm/i;
    let formattedTime = "";

    // Convert to 12-hour format
    let ampm = 'am';
    let formattedHours = parseInt(hours, 10);

    if (formattedHours >= 12) {
      ampm = 'pm';
      if (formattedHours > 12) {
        formattedHours -= 12;
      }
    }

    // Remove leading 0's
    formattedHours = formattedHours.toString().replace(/^0/, '');

    // Construct the final formatted time
    formattedTime = `${formattedHours}:${minutes || '00'}`;
    formattedTime = `${formattedTime.replace(/:00$/, '')}`;
    if (!containsAMorPM.test(formattedTime)) formattedTime += ampm

    return formattedTime;
  }

  return (
    <>
      {data.map((e, idx) => (
        <Col key={idx}>
          {e['times'].length > 0 ?
            <>
              {e['times'].map((t, idx2) => (
                <div key={idx2} style={{ marginBottom: "1rem" }}>
                  {t['starttime']} - {t['endtime']} <br />
                </div>
              )
              )}
            </>
            :
            <div>
              --
            </div>
          }
        </Col>
      ))}
    </>
  )
}