import "mdbreact/dist/css/mdb.css";
import "./App.css";

import React, { useState, useMemo, createContext } from "react";
import { MDBAnimation as Animation } from "mdbreact";
import { ProtectedRoute } from "./Components/ProtectedRoute";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PleaseLogin } from "./Components/PleaseLogin";
import { Navigation, PatientNav } from "./Components/Navigation";

import LoginPage from "./Pages/AuthPages/LoginPage/LoginPage";
import LogoutPage from "./Pages/AuthPages/LogoutPage/LogoutPage";
import AdminPage from "./Pages/AdminPages/SuperAdminPage/AdminPage";
import ProfilePage from "./Pages/ProfilePages/ProfilePage";

import SearchPage from "./Pages/ResearchPages/ResearchSearchPage/SearchPage";
import ShowMorePage from "./Pages/ResearchPages/PatientInfoPage/ShowMorePage";

import PatientPaymentPage from "./Pages/PatientPortal/PatientPaymentPage";
import VerificationPage from "./Pages/PatientPortal/VerificationPage";

import InfusionPage from "./Pages/InfusionPage/InfusionPage";

import { PatientTabs } from "./Pages/PatientPages/PatientTabs";
import { Checkout } from "./Pages/PatientPages/Checkout";

import { BillingTabs } from "./Pages/Billing/BillingTabs";

import OtherAPI from "./Pages/OtherAPI";
import { MainBillingAdmin } from "./Pages/AdminPages/BillingAdminPage/MainBillingAdmin";
import { AnalyticsHome } from "./Pages/AnalyticsPages/Analytics";
import { RulesHome } from "./Pages/BillingErrors/RulesHome";
import { Rules } from "./Pages/BillingErrors/Rules";
import { RulesErrors } from "./Pages/BillingErrors/Errors";
import { RuleError } from "./Pages/BillingErrors/RuleError";
import { ReportsPage } from "./Pages/ScoreCards/ReportsPage";
import { ReportCardsPage } from "./Pages/ScoreCards/ReportCardsPage";
import { PublicProviderMatch } from "./Pages/PublicPages/PublicProviderMatch";

export const UserContext = createContext({
  email: null,
  role: null,
  first_name: null,
  last_name: null,
  uid: null,
});
export const roles = [
  "ADMIN",
  "RESEARCH",
  "EXTERNAL",
  "BILLING",
  "BILLING_ADMIN",
  "BILLING_ERROR",
  "BILLING_ERROR_ADMIN",
  "PATIENT_MANAGER",
  "PROVIDER_MATCH",
  "SCORECARDS",
  "SCORECARDS_ADMIN",
  "OC_ADMIN",
  "OC_MANAGER",
  "INFUSION_MANAGER",
  "INFUSION_INTAKE",
  "READ_ONLY",
  "UNVERIFIED",
];
export const roleOptions = [
  { label: "Admin", value: "ADMIN", desc: "Access to site administration resources and applications" },
  { label: "Research", value: "RESEARCH", desc: "Accesss to Research application" },
  { label: "External", value: "EXTERNAL", desc: "No special permissions; External non-CLS affiliated user" },
  // { label: "On-Call Admin", value: "OC_ADMIN", desc: "Oncall app administrator" },
  // { label: "On-Call Manager", value: "OC_MANAGER", desc: "Oncall app user" },
  { label: "Provider Match", value: "PROVIDER_MATCH", desc: "Provider Match widget user" },
  { label: "Scorecards", value: "SCORECARDS", desc: "Scorecards app user; subscribe to various scorecards" },
  { label: "Scorecards Admin", value: "SCORECARDS_ADMIN", desc: "Scorecards admin; create and delete scorecard children" },
  { label: "Infusion Manager", value: "INFUSION_MANAGER", desc: "Access to Infusion app and management resources" },
  { label: "Infusion Intake", value: "INFUSION_INTAKE", desc: "Access to Infusion app" },
  { label: "Billing", value: "BILLING", desc: "Access to the Billing app; view Stream payment history for a given provider" },
  { label: "Billing Admin", value: "BILLING_ADMIN", desc: "Access to Billing + give users access to view and/or resolve payments for specific providers" },
  { label: "Billing Rule Manager", value: "BILLING_ERROR", desc: "Access to Billing Rules app; create, subscribe to and monitor Billing Rules" },
  { label: "Billing Rule Administrator", value: "BILLING_ERROR_ADMIN", desc: "Access to Billing Rules + manage/edit/toggle existing rules" },
  { label: "Patient Manager", value: "PATIENT_MANAGER", desc: "Access to Patient Lookup app; lookup patients and take payments" },
  { label: "Marketing", value: "READ_ONLY", desc: "Read only access to specific sites" },
  { label: "Unverified", value: "UNVERIFIED", desc: "Default role when signing up" },
];
function App() {
  const [user, setUser] = useState({
    email: null,
    role: [],
    first_name: null,
    last_name: null,
    uid: null,
  });
  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  const url = window.location.pathname.split("/")[1]

  return (
    <div className="App">
      <UserContext.Provider value={userValue}>
        <Animation type="fadeIn">
          <Router>
            {url !== "patientpay" && url !== "paymentportal" ? <Navigation /> : <PatientNav />}
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/not-authorized" component={PleaseLogin} />
              <ProtectedRoute
                roles={roles}
                exact
                path="/checkout"
                component={Checkout}
              />
              <ProtectedRoute
                roles={["ADMIN", "BILLING", "BILLING_ADMIN"]}
                exact
                path="/billing"
                component={BillingTabs}
              />
              <ProtectedRoute
                roles={["ADMIN", "BILLING_ADMIN"]}
                exact
                path="/iam"
                component={MainBillingAdmin}
              />
              <ProtectedRoute
                roles={roles}
                exact
                path="/profile"
                component={ProfilePage}
              />
              <ProtectedRoute
                roles={["ADMIN", "READ_ONLY", "PATIENT_MANAGER", "BILLING_ADMIN"]}
                exact
                path={["/patients", "/patients/:uid", "/patients/:uid/pdf"]}
                component={PatientTabs}
              />
              <ProtectedRoute
                roles={["ADMIN", "BILLING_ERROR", "BILLING_ERROR_ADMIN"]}
                exact
                path={["/BillingRules"]}
                component={RulesHome}
              />
              <ProtectedRoute
                roles={["ADMIN", "BILLING_ERROR", "BILLING_ERROR_ADMIN"]}
                exact
                path={["/BillingRules/Rules"]}
                component={Rules}
              />
              <ProtectedRoute
                roles={["ADMIN", "BILLING_ERROR", "BILLING_ERROR_ADMIN"]}
                exact
                path={["/BillingRules/Errors"]}
                component={RulesErrors}
              />
              <ProtectedRoute
                roles={["ADMIN", "BILLING_ERROR", "BILLING_ERROR_ADMIN"]}
                exact
                path={["/BillingRules/Errors/:id"]}
                component={RuleError}
              />
              <ProtectedRoute
                roles={["ADMIN", "SCORECARDS", "SCORECARDS_ADMIN"]}
                exact
                path={["/ScoreCards"]}
                component={ReportsPage}
              />
              <ProtectedRoute
                roles={["ADMIN", "SCORECARDS", "SCORECARDS_ADMIN"]}
                exact
                path={["/ScoreCards/:id"]}
                component={ReportCardsPage}
              />
              <ProtectedRoute
                roles={[
                  "ADMIN",
                  "INFUSION_MANAGER",
                  "INFUSION_INTAKE",
                  "READ_ONLY",
                ]}
                exact
                path="/infusion"
                component={InfusionPage}
              />
              <ProtectedRoute
                roles={roles}
                exact
                path="/home"
                component={ProfilePage}
              />
              <ProtectedRoute
                roles={["ADMIN"]}
                exact
                path="/admin"
                component={AdminPage}
              />
              <ProtectedRoute
                roles={roles}
                exact
                path="/logout"
                component={LogoutPage}
              />
              <ProtectedRoute
                roles={["ADMIN", "RESEARCH", "READ_ONLY"]}
                exact
                path="/showdata"
                component={SearchPage}
              />
              <ProtectedRoute
                roles={roles}
                exact
                path="/its_time"
                component={OtherAPI}
              />
              <ProtectedRoute
                roles={["ADMIN", "RESEARCH", "READ_ONLY"]}
                exact
                path="/id/:uid"
                component={ShowMorePage}
              />
              <ProtectedRoute
                roles={["ADMIN"]}
                exact
                path="/analytics"
                component={AnalyticsHome}
              />
            </Switch>
          </Router>
          <Router>
            <Switch>
              <Route
                exact
                path="/patientpay/:uid"
                component={PatientPaymentPage}
              />
              <Route
                exact
                path="/paymentportal/:uid"
                component={VerificationPage}
              />
              <Route
                exact
                path="/match"
                component={PublicProviderMatch}
              />
              <Route exact path="/.well-known/apple-developer-merchantid-domain-association" />
            </Switch>
          </Router>
        </Animation>
      </UserContext.Provider>
    </div>
  );
}

export default App;
