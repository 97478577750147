import { useState } from "react";
import { Collapse } from "react-bootstrap";
import * as Fa from 'react-icons/fa'
export const BriefCollapse = ({ children, lines = 5, defaultShow = false }) => {
  const [show, setShow] = useState(defaultShow);
  const el = children.length;
  document.documentElement.style.setProperty('--collapse-line-height', `${1.6 * lines}rem`)
  return (
    <>
      {el > lines * 95 ?
        <>
          <Collapse in={show} className="partial-collapse mb-2">
            <div id="pc-div">
              {children}
            </div>
          </Collapse>
          <a onClick={() => setShow(!show)} >
            Show {show ? <> less <Fa.FaAngleDoubleUp /> </> : <> more <Fa.FaAngleDoubleDown /> </>}
          </a>
        </>
        : <div id="pc-div">
          {children}
        </div>}
    </>
  )
}