import { Col, Row, Card, FormControl, Button, Form, Collapse, Spinner, Dropdown } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { postData } from "../../Utilities/apiRequests";
import { labelValueSerializer } from "../../Utilities/Formaters";
import Select from "react-select";
import WindowedSelect from "react-windowed-select";
import * as Fa from "react-icons/fa";
import { IconButton } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import env from "react-dotenv";
import { ErrorAlert } from "../../Components/Alerts";

export const ProviderMatch = () => {
  const secondInitialRef = useRef(null);
  const firstInitialRef = useRef(null);
  const patientSelectRef = useRef(null);

  const [specs, setSpecs] = useState([]);
  const [selectedSpec, setSelectedSpec] = useState("");
  const [wk, setWk] = useState("2");

  const [initials, setInitials] = useState({ fname: "", lname: "" });
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [ptAddress, setPtAddress] = useState("");

  const [results, setResults] = useState([]);

  const [showInfo, setShowInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(null);

  const { ref } = usePlacesWidget({
    apiKey: env.google_api,
    options: { types: ['address'] },
    onPlaceSelected: (place) => {
      setPtAddress(place.formatted_address)
    }
  });

  useEffect(() => {
    postData('/research_api/get_specialties', {}, setSpecs, setError)
  }, [])

  useEffect(() => {
    if (initials.fname != "" && initials.lname != "") {
      postData('/patients_api/get_patients', { dob: "", initials: [initials.fname, initials.lname] }, setPatients, setError, {
        setLoading: setLoading
      })
    }
  }, [initials.fname, initials.lname])

  useEffect(() => {
    if (selectedPatient.uid) {
      setPtAddress(`${selectedPatient.address}, ${selectedPatient.city}, ${selectedPatient.state} ${selectedPatient.ZIP}`)
    }
  }, [selectedPatient])

  useEffect(() => {
    if (error) setResults([])
  }, [error])

  const findMatch = (event) => {
    event.preventDefault()
    setInitials({ fname: "", lname: "" })
    postData('/research_api/get_best_match', { patient_address: ptAddress, specialty: selectedSpec, weeks: wk }, setResults, setError, { setLoading: setSubmitLoading })
  }

  return (
    <Form onSubmit={findMatch}>
      <Card>
        <Card.Header as="h2">Provider Match
          <IconButton
            onClick={() => setShowInfo(!showInfo)}
          >
            <Fa.FaQuestionCircle fontSize={"3rem"} />
          </IconButton> </Card.Header>
        <Card.Body>
          <InfoScreen show={showInfo} />
          <Card.Title>Patient/Address</Card.Title>
          <p>Enter a patient's address, or look up a patient (via their first/last initials) to autofill their details</p>
          <Row>
            <Col>
              <Row>
                <Col md={2}>
                  <Form.Label style={{ fontSize: "1.2rem" }}>1st Initial</Form.Label>
                  <FormControl
                    maxLength="1"
                    placeholder="A-Z..."
                    value={initials.fname}
                    ref={firstInitialRef}
                    onChange={(e) => {
                      setInitials((prevSinglePost) => ({
                        ...prevSinglePost,
                        fname: e.target.value,
                      }));
                      if (e.target.value !== "")
                        secondInitialRef.current.focus();
                    }} />
                </Col>
                <Col md={2}>
                  <Form.Label style={{ fontSize: "1.2rem" }}>2nd Initial</Form.Label>
                  <FormControl
                    maxLength="1"
                    placeholder="A-Z..."
                    value={initials.lname}
                    ref={secondInitialRef}
                    onChange={(e) => {
                      setInitials((prevSinglePost) => ({
                        ...prevSinglePost,
                        lname: e.target.value,
                      }));
                      if (e.target.value !== "")
                        patientSelectRef.current.focus();
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label style={{ fontSize: "1.3/rem" }}>Search</Form.Label>
                  <WindowedSelect
                    ref={patientSelectRef}
                    isLoading={loading}
                    onChange={(e) => setSelectedPatient(patients.filter((patient) => patient.uid === e.value)[0])}
                    options={labelValueSerializer(patients, ["ufname", "ulname"], "uid")}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormControl
                    ref={ref}
                    value={ptAddress}
                    onChange={(e) => setPtAddress(e.target.value)}
                  />
                  {ptAddress !== "" ? <p className="warning">Please check that patient address is correct.</p> : null}
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Card.Title>Specialty</Card.Title>
            <WeekDropdown wk={wk} setWk={setWk} />
          </div>
          <Row>
            <Col>
              <Row>
                <Select
                  onChange={(e) => setSelectedSpec(e.value)}
                  options={labelValueSerializer(specs, "specialtyName", "specialtyName")}
                />
              </Row>
            </Col>
          </Row>
          <br />
          <Button
            type="submit"
            disabled={selectedSpec === "" || ptAddress === "" || loading || submitLoading}>
            {submitLoading ? <Spinner animation="border" variant="light" /> : "Find Best Match"}
          </Button>
          {results.length > 0 && <hr />}
          <Row>
            <br />
          </Row>
          <BestResults results={results} loading={submitLoading} />
          <ErrorAlert setToggleAlert={setError} error={error} />
        </Card.Body>
      </Card>
    </Form>
  )
}

const BestResults = ({ results, loading }) => {
  const MIN_SCORE = 50
  const resultsRef = useRef(null);

  if (results.error)
    return (
      <Collapse
        in={!loading}
        onEntered={() => window.scrollTo(0, resultsRef.current.offsetTop)}
      >
        <div ref={resultsRef}>{results.error}</div>
      </Collapse>
    )
  else if (results)
    return (
      <>
        <Collapse
          in={results.length > 0 && !loading}
          onEntered={() => window.scrollTo(0, resultsRef.current.offsetTop)}
        >
          <div ref={resultsRef}>
            {results.map((result, idx) => (
              <Row key={idx} style={{ padding: "0.5rem", borderRadius: "1rem", background: result.score < MIN_SCORE ? "#ffe6e6" : "" }}>
                <Col md={4}>
                  <Row>
                    <h3>#{idx + 1}</h3>
                  </Row>
                  <Row>
                    <Col className="subtitle">
                      <div style={{
                        color: result.score >= 80 ? "green" : result.score >= 50 ? "orangered" : "crimson"
                      }}>
                        Score: {result.score}
                      </div>
                      <b>Availabilities ({result.wk >= 1 ? result.wk : parseInt(result.wk * 168 / 24)} {result.wk >= 1 ? "wk" : "dy"}):</b> <br />
                      {result.availabilities} {" "}
                      &#x2022; {(100 * result.availabilities / (result.availabilities + result.appointments)).toFixed(0)}% apt capacity<br />
                      <b>Commute:</b> <br />
                      {parseInt(result.duration / 60)} mins
                      &#x2022; {(result.distance / 1609.344).toFixed(2)}mi
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <h3>
                      {result.facility_name}
                    </h3>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          {result.provider_name}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="subtitle">{result.facility_address}</div>
                          {result.score < MIN_SCORE &&
                            <div className="warning">We do not recommend referring with a score less than {MIN_SCORE}.</div>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* {result.score <= 60 &&
                    <Alert variant="warning">Refer to non-CLS provider</Alert>
                  } */}
                </Col>
                {idx < results.length - 1 && <hr />}
              </Row>
            ))}
          </div>
        </Collapse>
      </>
    )
  else return (<></>)
}

const InfoScreen = ({ show }) => {
  return (
    <>
      <Collapse in={show}>
        <div className="info-card">
          <h3>Purpose</h3>
          This tool suggests the best possible referral based on a selected specialty and the patients address.
          <h3>Patient Lookup and Address Filling</h3>
          You can conveniently autofill the patients address by looking them up via their first and last initial and selecting them. <br />
          If the patient's address is incorrect, or if they aren't found, you can fill the field out manually. <br />
          <h3>Understanding the Output</h3>
          The results will be a list of facility-provider availabilities sorted based on a score (bigger is better), weighed between two variables. <br />
          <strong>Distance</strong>: the distance in miles patient lives from the facility, based on the patient address entered. <br />
          <strong>Availabilities</strong>: how many appointments are available for that provider at that facility over the next week.
        </div>
      </Collapse>
    </>
  )
}

const WeekDropdown = ({ wk, setWk }) => {
  const handleClick = (e) => {
    setWk(e.target.id)
  }

  return (
    <Dropdown>
      <div className="underline-hover">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          {wk >= 1 ? wk : parseInt(wk * 168 / 24)} {wk >= 1 ? "week" : "day"} lookahead
        </Dropdown.Toggle>
      </div>
      <Dropdown.Menu>
        <Dropdown.Item id="0.43" onClick={handleClick}>3 days</Dropdown.Item>
        <Dropdown.Item id="2" onClick={handleClick}>2 weeks</Dropdown.Item>
        <Dropdown.Item id="4" onClick={handleClick}>4 weeks</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    style={{ color: "dimgray" }}
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));