import { MDBBtn, MDBCard, MDBCardHeader, MDBCol, MDBRow } from "mdbreact";
import { UserContext } from "../../App";
import { FacilitySVG } from "../../Components/SVGComponents";
import {
  Form,
  InputGroup,
  Modal,
  ModalBody,
  ProgressBar,
} from "react-bootstrap";
import { useState, useContext, useEffect } from "react";
import { postData } from "../../Utilities/apiRequests";
import { createPDF } from "./Checkout";

export const PaidTransactions = ({ transactions, patient, copay }) => {
  const { user } = useContext(UserContext);
  const [pay, setPay] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [RefundTrans, setRefundTrans] = useState({});
  const [selectedToPrint, setSelectedToPrint] = useState(new Set());
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    if (transactions.length > 0) {
      transactions.forEach((t) => {
        if (t.Total) {
          if (!t.Refunded) {
            t.Refunded = 0;
          }
          t.Refund_Amount = parseFloat(t.Patient_Paid) + parseFloat(t.Refunded);
        }
      });
    }
  }, [transactions]);

  const handleRefund = (transaction) => {
    postData(
      "payment_api/refund",
      transaction,
      () => { },
      () => { },
      {
        successCallback: () => {
          transaction.Refund_Amount = 0;
          setConfirm(false);
        },
      }
    );
  };
  const printTranscript = () => {
    const pastPay = transactions.filter((trans) =>
      selectedToPrint.has(trans.Desc_ID)
    );
    createPDF(null, null, { cart: pastPay, patient: patient, copay: copay });
  };

  for (let i = 0; i < transactions.length; i++) {
    if (transactions[i].Timestamp == null) {
      transactions[i].Timestamp = transactions[i].Payment_Date;
    }
  }
  transactions.sort(function (a, b) {
    return b.Timestamp - a.Timestamp;
  });
  return (
    <>
      <MDBCardHeader>
        <h2>Paid Transactions</h2>
        <MDBBtn disabled={selectedToPrint.size == 0} onClick={() => printTranscript()}>Print Transcript</MDBBtn>
        <Form.Check
          defaultChecked={false}
          label="Select all"
          onChange={(e) => {
            if (e.target.checked) {
              let arr = []
              for (let i in transactions) {
                if (transactions[i].Desc_ID)
                  arr.push(transactions[i].Desc_ID)
              }
              setSelectedToPrint(new Set(arr))
            }
            else setSelectedToPrint(new Set())
          }}
        />
      </MDBCardHeader>
      {transactions.map((trans, idx) => {
        if (trans.Business_Code == "N/A") {
          trans.Business_Code = "CLS";
        }
        if (trans.Transaction_Type !== "REFUND")
          return (
            <MDBCard key={idx} className="PaidTransactionCard">
              <Modal
                show={confirm === idx}
                onHide={() => setConfirm(false)}
                centered
              >
                <ModalBody>
                  Are you sure you want to refund{" "}
                  <strong>${refundAmount}</strong> for this transaction?
                  <MDBRow>
                    <div>
                      <MDBBtn
                        color="cls"
                        onClick={() => handleRefund(RefundTrans)}
                      >
                        Yes
                      </MDBBtn>
                      <MDBBtn onClick={() => setConfirm(false)}>No</MDBBtn>
                    </div>
                  </MDBRow>
                </ModalBody>
              </Modal>
              <MDBRow>
                <MDBCol
                  md="2"
                  className="v-align"
                  style={{ justifyContent: "center" }}
                >
                  <FacilitySVG
                    Text={trans.Facility_Name}
                    Code={trans.Business_Code}
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBRow>
                    <MDBCol md="5">
                      <strong>Date of Payment</strong>
                    </MDBCol>
                    <MDBCol md="7" className="right-col-text">
                      {new Date(trans.Timestamp).toLocaleDateString("en-US", {
                        timeZone: "GMT",
                      })}
                    </MDBCol>
                  </MDBRow>
                  <br></br>
                  <MDBRow>
                    <MDBCol md="5">
                      <strong>Physician</strong>
                    </MDBCol>
                    <MDBCol md="7" className="right-col-text">
                      {trans.Provider}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="5">
                      <strong>Date of Service</strong>
                    </MDBCol>
                    <MDBCol md="7" className="right-col-text">
                      {new Date(trans.Date_of_Service).toLocaleDateString(
                        "en-US",
                        { timeZone: "GMT" }
                      )}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="5">
                      <strong>Visit Description</strong>
                    </MDBCol>
                    <MDBCol md="7" className="right-col-text">
                      {trans.Item_Desc}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="4" style={{ padding: "0rem 2.5rem" }}>
                  <MDBRow>
                    <div className="progress-label">
                      <span className="fw-150">Total Paid:</span>
                      <span className="fw-300">
                        <strong>${trans.Patient_Paid}</strong>
                      </span>
                    </div>
                    <ProgressBar>
                      <ProgressBar
                        now={
                          ((trans.Patient_Paid + trans.Refunded) /
                            trans.Patient_Paid) *
                          100
                        }
                        variant="success"
                      />
                      <ProgressBar
                        now={((-1 * trans.Refunded) / trans.Patient_Paid) * 100}
                        variant="warning"
                      />
                    </ProgressBar>
                  </MDBRow>
                  {trans.Total && user.role.includes("ADMIN") ? (
                    <>
                      <MDBRow>
                        <MDBCol>
                          <MDBBtn
                            disabled={
                              parseFloat(trans.Refund_Amount) <= 0 ||
                              -1 * trans.Refunded == trans.Patient_Paid ||
                              !trans.Refund_Amount
                            }
                            onClick={() => {
                              setConfirm(idx);
                              setRefundTrans(trans);
                              setRefundAmount(trans.Refund_Amount);
                            }}
                          >
                            Refund
                          </MDBBtn>
                        </MDBCol>
                        <MDBCol style={{ marginTop: "0.375rem" }}>
                          <InputGroup>
                            <InputGroup.Text>
                              Refund{trans.isDispute ? "*" : ""} $
                            </InputGroup.Text>

                            <Form.Control
                              type="number"
                              className="editableField"
                              value={
                                trans.Refund_Amount ? trans.Refund_Amount : ""
                              }
                              onChange={(e) => {
                                trans.Refund_Amount = e.target.value;
                                setPay(
                                  transactions.map((item, index) =>
                                    index === idx
                                      ? (item = trans)
                                      : (item = item)
                                  )
                                );
                              }}
                              onBlur={(e) => {
                                if (
                                  isNaN(parseFloat(e.target.value)) ||
                                  parseFloat(e.target.value) < 0 ||
                                  parseFloat(e.target.value) >
                                  trans.Patient_Paid + trans.Refunded
                                ) {
                                  trans.Refund_Amount = (
                                    parseFloat(trans.Patient_Paid) +
                                    parseFloat(trans.Refunded)
                                  ).toFixed(2);
                                  setPay(
                                    transactions.map((item, index) =>
                                      index === idx
                                        ? (item = trans)
                                        : (item = item)
                                    )
                                  );
                                }
                              }}
                              onKeyPress={(e) => {
                                if (e.key == "Enter") {
                                  e.target.blur();
                                }
                              }}
                            />
                          </InputGroup>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <Form.Check
                          type="checkbox"
                          label="Add to print transcript"
                          id={`check-${idx}`}
                          checked={
                            selectedToPrint.has(trans.Desc_ID)
                              ? "checked"
                              : false
                          }
                          onChange={(e) => {
                            if (selectedToPrint.has(trans.Desc_ID))
                              selectedToPrint.delete(trans.Desc_ID);
                            else selectedToPrint.add(trans.Desc_ID);
                            setRefresh(refresh + 1);
                          }}
                        />
                      </MDBRow>
                    </>
                  ) : null}
                </MDBCol>
              </MDBRow>
            </MDBCard>
          );
      })}
    </>
  );
};
