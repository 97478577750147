import { getCookie } from "../Utilities/LoginUtil";
import { getRowUid } from "../Utilities/TableUtilities";

const Delete = (setMsg, history, type, url) => {
  const opts1 = {
    method: "GET",
    headers: {
      "X-CSRF-TOKEN": getCookie("csrf_access_token"),
    },
  };
  fetch("/auth_api/is_logged_in", opts1);
  const deleteRows = getRowUid();
  const opts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": getCookie("csrf_access_token"),
    },
    body: JSON.stringify({ type: type, data: deleteRows }),
  };

  fetch(url, opts).then((response) => {
    // if POST was successfull
    if (response.status === 200) {
      response.json().then((data) => {
        setMsg(data);
      });
    } else if (response.status === 401) {
      history.push("/not-authorized");
    } else {
      // Catchs & Sets error message
      response.json().then((data) => {
        setMsg(data.msg);
      });
      //alert("Error: Editing User");
    }
  });
};

export default Delete;
