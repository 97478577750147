import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, Button, LinearProgress, Badge, Tooltip } from "@mui/material";
import { Card, Col, Container, Row, Collapse } from "react-bootstrap";
import WindowedSelect from "react-windowed-select";
import { labelValueSerializer, getValues } from "../../Utilities/Formaters";
import { postData } from "../../Utilities/apiRequests";
import { getCookie, setCookie } from "../../Utilities/LoginUtil";
import * as FaIcons from "react-icons/fa";
import { ErrorAlert } from "../../Components/Alerts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ overflow: "visible" }}
      {...other}
    >
      {value === index && (
        <Box style={{ paddingTop: "2rem" }}>
          <span style={{ fontWeight: "light", fontSize: "1.2rem" }}>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export const QuickLookup = ({ onFormSubmit, providers, facilities, setPatientInfo }) => {
  let defaultProviders = getCookie("defaultProviders");
  defaultProviders = defaultProviders ? JSON.parse(defaultProviders) : [];
  const [showQuikBar, setShowQuikBar] = useState(window.innerWidth > 1280);
  const [value, setValue] = useState(0);
  const [refresh, setRefresh] = useState(-1);
  const [collapseIdx, setCollapseIdx] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedProviders, setSelectedProviders] =
    useState(defaultProviders);
  let times = {};

  if (defaultProviders.length === 0) {
    localStorage.removeItem("searchKeys");
  }

  let patientCookie = localStorage.getItem("searchKeys") || "";
  if (patientCookie) {
    patientCookie = patientCookie.replace("[", "").replace("]", "").split('",');
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = (event) => {
    var val = JSON.parse(event.target.value)
    var defaultProvider = providers.filter((provider) => provider.doctorId == val.doctorId)
    var defaultResource = providers.filter((resource) => resource.doctorId == val.resourceId)
    var defaultFacility = facilities.filter((facility) => facility.Facility_ID == val.facilityId)
    if (window.innerWidth < 1280) setShowQuikBar(!showQuikBar)
    if (defaultProvider.length > 0) {
      localStorage.setItem(
        "defaultProvider",
        JSON.stringify({ label: `${defaultProvider[0]['First Name']} ${defaultProvider[0]['Last Name']}`, value: defaultProvider[0].doctorId })
      );
    }
    if (defaultResource.length > 0) {
      localStorage.setItem(
        "defaultResource",
        JSON.stringify({ label: `${defaultResource[0]['First Name']} ${defaultResource[0]['Last Name']}`, value: defaultResource[0].doctorId })
      );
    }
    if (defaultFacility.length > 0) {
      localStorage.setItem(
        "defaultFacility",
        JSON.stringify({ label: defaultFacility[0]['Facility_Name'], value: defaultFacility[0].Facility_ID })
      );
    }
    setPatientInfo(val);
    onFormSubmit(val);
  };
  const handleNewSchedules = (event) => {
    const values = getValues(event, "value");//0.000277
    setCookie("defaultProviders", JSON.stringify(values), 0.5);
    setSelectedProviders(values);
    postData(
      "/patients_api/get_patients_today",
      values,
      (data) => {
        localStorage.setItem("searchKeys", JSON.stringify(data.full));
        setCookie("searchKeys", JSON.stringify(data.uids), 0.5);
        Object.keys(times).forEach((time, idx) => collapseIdx[idx] = true)
        setRefresh(0);
      },
      setError,
      {
        setLoading: setLoading
      }
    );
  };
  if (patientCookie && patientCookie.length > 0 && patientCookie[0]) {
    patientCookie.map((patient, idx) => {
      patient = patient.replace("\"", "").replace("\"", "")
      let appointmentTime = parseInt(patient.split(",")[4].split(":")[0]);
      let providerId = patient.split(",")[5];
      if (times[appointmentTime] === undefined) {
        times[appointmentTime] = [];
      }
      if (times[appointmentTime][providerId] === undefined)
        times[appointmentTime][providerId] = [];

      times[appointmentTime][providerId].push(patient);
    })
    //order the times
    times = Object.keys(times).sort().reduce((obj, key) => {
      obj[key] = times[key];
      return obj;
    }, {})
    if (refresh <= 0)
      Object.keys(times).forEach((time, idx) => collapseIdx[idx] = true)
  }

  return (
    <>
      <a onClick={() => setShowQuikBar(!showQuikBar)}>
        <Card style={{ display: "flex", flexDirection: "row", alignItems: "center" }} className={!showQuikBar ? "quik-tag" : "quik-tag-hidden"}>
          <span className="quik-content">
            Quick Lookup &nbsp;
          </span>
          <FaIcons.FaExpandAlt />
        </Card>
      </a>
      <Card
        className={showQuikBar ? "quik-bar" : "quik-bar-hidden"}
        style={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <a onClick={() => setShowQuikBar(!showQuikBar)} style={{ textAlign: "right" }}>
          <FaIcons.FaCompressAlt />
        </a>
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Horizontal tabs example"
          sx={{ overflow: "visible" }}
        >
          <Tab label="Quick Lookup" {...a11yProps(0)} />

          <Tab label="History" {...a11yProps(1)} />
        </Tabs>
        <TabPanel
          style={{ maxWidth: "100%", width: "100%" }}
          value={value}
          index={0}
        >
          <WindowedSelect
            className="w-100"
            options={
              selectedProviders.length >= 8
                ? []
                : labelValueSerializer(
                  providers,
                  ["First Name", "Last Name"],
                  "doctorId"
                )
            }
            isMulti
            value={labelValueSerializer(
              providers,
              ["First Name", "Last Name"],
              "doctorId"
            ).filter((obj) => selectedProviders.includes(obj.value))}
            onChange={(e) => handleNewSchedules(e)}
          />
          {loading ? <> <br /><LinearProgress /> </> : null}
          <Container style={{ marginTop: "1rem" }}>
            <a
              onClick={() => {
                let newArr = collapseIdx;
                newArr.forEach((e, i) => newArr[i] = false);
                setCollapseIdx(newArr);
                setRefresh(refresh + 1);
              }}>
              Hide all
            </a>
            {Object.keys(times).map((time, idx) => {
              time = parseInt(time);
              return (
                <Row key={'row' + idx}>
                  <Col md="3">
                    {time <= 12 ? time : time % 12} - {time < 12 ? time + 1 : time % 12 + 1}{time + 1 < 12 ? "am" : "pm"} {" "}
                  </Col>
                  <Col>
                    <a
                      style={{ display: "flex", justifyContent: "right" }}
                      onClick={() => {
                        let newArr = collapseIdx;
                        newArr[idx] = !newArr[idx];
                        setCollapseIdx(newArr);
                        setRefresh(refresh + 1);
                      }
                      }>
                      {collapseIdx[idx] ? "hide" : "show"} </a>
                    <Collapse in={collapseIdx[idx]}>
                      <div>
                        {times[time].map((provider, jdx) => {
                          let providerObj = providers.filter(p => p['doctorId'] == jdx)[0];
                          return (
                            <div key={jdx}>
                              {selectedProviders.length > 1 ?
                                <span style={{ fontWeight: "unset", display: "flex", paddingBottom: 0 }}>
                                  {providerObj ? `${providerObj['First Name']} ${providerObj['Last Name']}` : null}
                                </span>
                                : null}
                              {provider.map((patient, kdx) => {
                                patient = patient.split(",")
                                return (
                                  <Button
                                    className="d-inline-block m-1"
                                    name={patient[0]}
                                    value={JSON.stringify({
                                      uid: patient[0],
                                      ufname: patient[2],
                                      ulname: patient[1],
                                      dob: patient[3],
                                      doctorId: patient[5],
                                      resourceId: patient[6],
                                      facilityId: patient[7],
                                    })}
                                    variant="contained"
                                    style={{ backgroundColor: "#0E4DA0", fontWeight: "bold" }}
                                    key={kdx}
                                    onClick={handleSubmit}
                                  >
                                    {patient[2] + " " + patient[1]}
                                  </Button>
                                )
                              })}
                              {jdx !== (times[time].length - 1) ? <hr /> : ""}
                            </div>
                          )
                        })}
                      </div>
                    </Collapse>
                    <hr style={{ marginTop: "1rem" }} />
                  </Col>
                </Row>
              )
            })}
            <ErrorAlert error={error} setToggleAlert={setError} />
          </Container>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {getCookie("LookupHistory") ? JSON.parse(getCookie("LookupHistory")).map((patient, jdx) => {
            return (
              <Tooltip
                key={jdx}
                style={{ fontSize: 14 }}
                title={!patient.collected && patient.balance > 0 ?
                  <p className="my-tooltip">No balance was collected.</p>
                  : ""}
                placement="top">
                <Badge
                  color="error"
                  badgeContent={""}
                  invisible={patient.collected || patient.balance <= 0}
                  overlap="circular"
                >
                  <Button
                    className="d-inline-block m-1 lookup-color"
                    name={patient.uid}
                    value={JSON.stringify(patient)}
                    variant="contained"

                    onClick={handleSubmit}
                  >
                    {patient.ufname + " " + patient.ulname}
                  </Button>
                </Badge>
              </Tooltip>
            )
          }) : "Your lookup history is empty."
          }
        </TabPanel>
      </Card>
    </>
  );
};
