import React from "react";
import { Form } from "react-bootstrap";
import { MDBRow as Row, MDBCol as Col } from "mdbreact";

export const ReceiptForm = ({ patientInfo, setReceiptInfo, receiptInfo }) => {

  const handleChange = (target) => {
    setReceiptInfo((prevReceiptInfo) => ({
      ...prevReceiptInfo,
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    }));
  };

  return (
    <div>
      <h3>Receipt</h3>
      <Form.Group>
        <Row>
          <Col>
            <Form.Check
              name="print"
              value="print"
              id="print"
              label="Print"
              type="checkbox"
              onChange={(e) => handleChange(e.target)}
            />
          </Col>
        </Row>
        <Row>
          <Col md="2">
            <Form.Check
              name="isEmail"
              value="email"
              id="email"
              label="Email"
              type="checkbox"
              onChange={(e) => handleChange(e.target)}
            />
          </Col>
          <Col md="5">
            <Form.Control
              name="email"
              disabled={!receiptInfo.isEmail}
              placeholder={patientInfo.email}
              value={receiptInfo.email}
              onChange={(e) => handleChange(e.target)}
            />
          </Col>
        </Row>
        <Row className="InputRow">
          <Col md="2">
            <Form.Check
              name="isPhone"
              value="sms"
              id="sms"
              label="SMS"
              type="checkbox"
              defaultChecked
              onChange={(e) => handleChange(e.target)}
            />
          </Col>
          <Col md="5">
            <Form.Control
              name="phone"
              disabled={!receiptInfo.isPhone}
              placeholder={patientInfo.phone}
              value={receiptInfo.phone}
              type="tel"
              onChange={(e) => handleChange(e.target)}
            />
          </Col>
        </Row>
      </Form.Group>
    </div>
  );
};
