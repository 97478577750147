import { useState } from "react";

// Handles the storage and change of non-boolean values used in forms
// This is not used when a reset of the form is required after submission

// LOOK INTO HOW TO USE THIS EVEN IN THE CASE STATED ABOVE
export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);
  const handleChange = (e, name = false, isMulti = false) => {
    if (name) {
      if (isMulti) {
        setValues((prevValues) => ({
          ...prevValues,
          [name]: e.map((obj) => obj.value),
        }));
      } else {
        if (e === null) {
          setValues((prevValues) => ({
            ...prevValues,
            [name]: "",
          }));
        } else {
          setValues((prevValues) => ({
            ...prevValues,
            [name]: e.value,
          }));
        }
      }
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [e.target.name]: e.target.value,
      }));
    }
  };
  return [values, handleChange, setValues];
};
