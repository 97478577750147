import { MDBBtn } from "mdbreact";

export function LogoutDiv({ Logout, error }) {
  return (
    <>
      {error !== "" ? <div className="error">{error}</div> : ""}
      <h1 className="logout">
        Are you sure you want to logout?
        <br></br>
      </h1>
      <MDBBtn color="cls-danger" onClick={Logout}>
        Yes!
      </MDBBtn>
    </>
  );
}
