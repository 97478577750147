import React from "react";
import { MDBBtn } from "mdbreact";
import { Card, Spinner } from "react-bootstrap";
import { useForm } from "../../../Utilities/useForm";

export function LoginForm({ Login, Signup, ForgotPass, isLoading, showMFA, setMfaCode }) {
  const [values, handleChange] = useForm({ email: "", password: "" });

  const handleSubmit = (event) => {
    event.preventDefault();
    Login(values);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card.Header>
        <Card.Title><h1 style={{ "fontSize": "4rem" }}>Sign In</h1></Card.Title>
      </Card.Header>
      <Card.Body>
        <label htmlFor="email" className="sr-only">
          Email:
        </label>

        <input
          type="email"
          name="email"
          className="form-control"
          placeholder="Email address"
          required
          value={values.email}
          onChange={handleChange}
        />

        <label htmlFor="password" className="sr-only">
          Password:
        </label>

        <input
          type="password"
          name="password"
          className="form-control"
          placeholder="Password"
          required
          value={values.password}
          onChange={handleChange}
        />
        {showMFA &&
          <>
            <input
              autoFocus
              name="mfa"
              inputMode="numeric"
              className="form-control"
              placeholder="6-digit Multifactor Authentication Code"
              required
              onChange={(e) => setMfaCode(e.target.value)}
            />
          </>
        }
        <a
          style={{ width: "100%" }}
          href="#"
          onClick={() => ForgotPass(true)}
        >
          Forgotten or Expired password?
        </a>
        <MDBBtn
          className="fill-width"
          color="cls"
          type="submit"
          value="Login"
          disabled={isLoading}
        >
          {isLoading ?
            <Spinner animation="border" variant="light" />
            : "Sign in"}
        </MDBBtn>
      </Card.Body>
      <Card.Footer>
        Don't have an account?{" "}
        <a href="#" onClick={() => Signup(true)}>
          Click here to register.
        </a>
      </Card.Footer>
    </form>
  );
}

export function LoginFormCard({ Login, Signup, ForgotPass }) {
  return (
    <Card className="form-signin">
      <LoginForm Login={Login} Signup={Signup} ForgotPass={ForgotPass} />
    </Card>
  );
}