import { Card, Form, Button, Row, Col, Collapse, Spinner, CloseButton, Alert, Modal } from "react-bootstrap"
import { useHistory } from "react-router"
import { useState, useEffect, useRef, useContext } from "react";
import Select from "react-select";
import { arrayToLabelValue } from "../../Utilities/Formaters";
import { postData } from "../../Utilities/apiRequests";
import WindowedSelect from "react-windowed-select";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import { Switch, Tooltip, LinearProgress, Popover } from "@mui/material";
import { Table } from "../../Components/Table";
import { GetGenericColumns } from "../../Utilities/Columns";
import { ErrorAlert } from "../../Components/Alerts";
import { UserContext } from "../../App";

const operators = ['=', '!=', '<', '>', '<=', '>=', 'like', 'not like', 'in', 'not in', 'between']
const preview_columns = [
  "Date Of Service",
  "Provider_Name",
  "CPT_Code",
  "Billed",
  "Allowed",
  "Total_Paid",
  "Ins1Name",
  "Units",
  "Modifier 1",
  // "Description",
]
export const Rules = () => {
  const history = useHistory();
  const [submission, setSubmission] = useState(false);
  const [error, setError] = useState(null);

  return (
    <div className="mycontainer" style={{ width: "70%" }}>
      <Card>
        <a href="#" onClick={() => history.push("/BillingRules")}>Back</a>
        <Card.Body>
          <h2>Rules Management</h2>
          You can manage, add, delete or modify all existing rulesets here.
          <hr />
          <CreateRuleForm setSubmission={setSubmission} setError={setError} />
        </Card.Body>
        <ErrorAlert error={error} setToggleAlert={setError} />
        <hr />
        <RuleItems newSubmission={submission} />
      </Card>
    </div>
  )
}

export const CreateRuleForm = ({
  rule,
  setSubmission,
  setError,
  editExistingRule
}) => {
  const { user } = useContext(UserContext);

  const previewRef = useRef();

  const [cols, setCols] = useState({ columns: [], properties: "" });
  const [options, setOptions] = useState({ column: "", options: [] });
  const [filterRows, setFilterRows] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [ruleName, setRuleName] = useState("");
  const [ruleDesc, setRuleDesc] = useState("");

  const [preview, setPreview] = useState([]);
  const [previewCount, setPreviewCount] = useState({ total: null, problems: null });
  const [previewObj, setPreviewObj] = useState(null);

  const [refresh, setRefresh] = useState(0);

  const [loading, setLoading] = useState(false);
  const [optonsLoading, setOptionsLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);

  useEffect(() => {
    if (rule) {
      let tempFilterRows = []
      setRuleName(rule.rule_name)
      setRuleDesc(rule.description)

      rule.filters.forEach((filter, idx) => {
        if (cols["properties"][filter.name]) {
          if (cols["properties"][filter.name]["type"] === "date" && !filter.value.includes("[")) {
            filter["value"] = new Date(filter["value"]).toISOString().split("T")[0]
            if (filter["value2"]) filter["value2"] = new Date(filter["value2"]).toISOString().split("T")[0]
          }
          if (cols["properties"][filter.name]["type"] === "select")
            getOptionsForColumn(filter.name)
        }
        tempFilterRows.push(
          {
            name: filter.name,
            operator: filter.operator,
            value: filter.value,
            value2: filter.value2 ? filter.value2 : "",
            rollingDate: filter.rollingDate ? filter.rollingDate : false,
            identifier: false,
            idx: idx
          }
        )
      })
      if (cols["properties"][rule.identifier.name]) {
        if (cols["properties"][rule.identifier.name]["type"] === "date") {
          rule.identifier.value = new Date(rule.identifier.value).toISOString().split("T")[0]
          if (rule.identifier["value2"]) rule.identifier["value2"] = new Date(rule.identifier["value2"]).toISOString().split("T")[0]
        }
        if (cols["properties"][rule.identifier.name]["type"] === "select")
          getOptionsForColumn(rule.identifier.name)
      }
      tempFilterRows.push({
        name: rule.identifier.name,
        operator: rule.identifier.operator,
        value: rule.identifier.value,
        value2: rule.identifier.value2 ? rule.identifier.value2 : "",
        identifier: true,
        rollingDate: rule.rollingDate ? rule.rollingDate : false,
        idx: tempFilterRows.length
      })
      setFilterRows(tempFilterRows)
    }
  }, [rule, cols])

  useEffect(() => {
    postData('/billing_api/get_mds_cols', {}, setCols, setError, { setLoading: setLoading });
  }, [])

  useEffect(() => {
    if (options.column) {
      let temp = cols;
      temp["properties"][options["column"]]["options"] = options["options"]
      setCols(temp);
      setRefresh(refresh + 1);
    }
  }, [options])

  useEffect(() => {
    if (filterRows.filter(row => row.identifier).length !== 1) setDisableSubmit(true);
    else setDisableSubmit(false);
  }, [filterRows, refresh])

  useEffect(() => {
    if (previewObj) {
      setPreviewCount({ total: previewObj["count"], problems: previewObj["problem_count"] });
      setPreview(previewObj["rows"]);
    }
    else {
      setPreviewCount({ total: null, problems: null });
      setPreview([]);
    }
  }, [previewObj])

  const getOptionsForColumn = (column) => {
    if (column)
      postData('/billing_api/get_mds_column_options', { column: column }, setOptions, setError, { setLoading: setOptionsLoading });
  }

  const handleRowChange = (e, item, idx, clearValue) => {
    let colname = filterRows[idx]["name"];
    let temp = filterRows;
    if (clearValue && colname) {
      temp[idx]['value'] = cols["properties"][colname]["type"] === "select" ? null : "";
      setFilterRows(temp);
      setRefresh(refresh + 1);
    }

    if (item === "name" && e.value && cols["properties"][e.label]["type"] === "select") getOptionsForColumn(e.value)

    if (e.value || e.value === "")
      temp[idx][item] = e.value;
    else
      temp[idx][item] = e.map((i) => i.value);

    setFilterRows(temp);
    setRefresh(refresh + 1);
  }

  const removeRow = (idx) => {
    let temp = filterRows;
    temp = temp.filter(row => row.idx !== idx);
    temp.forEach((e, idx) => {
      temp[idx]["idx"] = idx
    })
    setFilterRows(temp)
  }

  const handleToggleIdentifier = (e, row, idx) => {
    let temp = filterRows;
    temp[idx]["identifier"] = e.target.checked;
    setFilterRows(temp);
    setRefresh(refresh + 1);
  }

  const handleSubmit = (e, option) => {
    e.preventDefault();
    if (editExistingRule && option !== "preview") option = "edit"
    let identifiers = JSON.parse(JSON.stringify(filterRows.filter((row) => row["identifier"])));
    let filters = JSON.parse(JSON.stringify(filterRows.filter((row) => !row["identifier"])));

    identifiers = identifiers.map(({ idx, identifier, ...keepAttrs }) => keepAttrs)[0];
    filters = filters.map(({ idx, identifier, ...keepAttrs }) => keepAttrs);

    let data = {
      rule_name: ruleName,
      description: ruleDesc,
      filters: filters,
      identifier: identifiers,
      created_by: user.uid
    }
    if (option === "preview")
      postData("/billing_api/preview_rule", data, setPreviewObj, setError, { setLoading: setPreviewLoading });
    else if (option === "create") {
      setPreviewObj(null)
      postData("/billing_api/add_billing_rule", data, setSubmission, setError, { setLoading: setPreviewLoading });
    }
    else if (option === "edit") {
      postData(`/billing_api/edit_rule/${rule.id}`, data, setSubmission, setError, { setLoading: setPreviewLoading })
    }
  }
  return (
    <Form onSubmit={(e) => handleSubmit(e, "preview")}>
      <Card>
        <h3>{editExistingRule ? "Edit an existing rule" : "Create a new rule"}</h3>
        You must have at least one identifier (id) selected. An identifier is an attribute that the rule relies upon. <br />
        Other attributes are filters or specifiers, to compliment and limit the identifier for the rule's scope.
        <Row>
          <Col md="3">
            <Form.Control
              placeholder="Enter a rule name"
              onChange={(e) => setRuleName(e.target.value)}
              value={ruleName}
              required
            />
          </Col>
          <Col>
            <Form.Control
              placeholder="Enter a rule description"
              onChange={(e) => setRuleDesc(e.target.value)}
              value={ruleDesc}
              required
            />
          </Col>
        </Row>

        <Card.Body>
          {filterRows && filterRows.map((row, idx) => {
            let row_operators = operators;
            let row_properties = cols["properties"][row["name"]];
            if (row_properties) {
              if (!["number", "date"].includes(cols["properties"][row["name"]]["type"]))
                row_operators = ["=", "!=", "in", "not in", "like", "not like"]
              else if (row_properties["type"] && row_properties["type"] === "date")
                row_operators = ["=", "!=", ">", "<", ">=", "<=", "between"]
            }
            return (
              <div key={idx}>
                <Row>
                  <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
                    <a onClick={() => removeRow(idx)}>
                      <FaIcons.FaMinusCircle style={{ color: "red" }} />
                    </a>
                  </Col>
                  <Col>
                    {!idx ? <Form.Label>Filter Property</Form.Label> : null}
                    <Select
                      required
                      isLoading={loading}
                      value={row["name"] ? { label: row["name"], value: row["name"] } : null}
                      onChange={(e) => handleRowChange(e, "name", idx)}
                      options={arrayToLabelValue(cols.columns).filter(col => !filterRows.find(row => row.name === col.label))}
                    />
                    <FakeInput value={row["name"] ? { label: row["name"], value: row["name"] } : null} />
                  </Col>
                  <Col md="2">
                    {!idx ? <Form.Label>Operator</Form.Label> : null}
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      <Col>
                        <Select
                          required
                          isDisabled={!filterRows[idx]["name"]}
                          value={row["operator"] ? { label: row["operator"], value: row["operator"] } : null}
                          onChange={(e) => handleRowChange(e, "operator", idx, true)}
                          options={arrayToLabelValue(row_operators)}
                        />
                      </Col>
                      {row["operator"] === "between" && row_properties && row_properties["type"] === "date" &&
                        <Col md="auto">
                          <Tooltip title={<p className="my-tooltip">Toggle a ROLLING DATE RANGE rather than a static date range.</p>}>
                            <span>
                              <Form.Check
                                checked={row["rollingDate"] ? row["rollingDate"] : false}
                                onChange={(e) => {
                                  if (e.target.checked)
                                    handleRowChange({ value: true }, "rollingDate", idx)
                                  else
                                    handleRowChange({ value: "" }, "rollingDate", idx)
                                }}
                              />
                            </span>
                          </Tooltip>
                        </Col>
                      }
                    </Row>
                    <FakeInput value={row["operator"] ? { label: row["operator"], value: row["operator"] } : null} />
                  </Col>
                  <Col>
                    {!idx ? <Form.Label>Filter Value</Form.Label> : null}
                    <ValueFormInput
                      row={row}
                      idx={idx}
                      handleRowChange={handleRowChange}
                      filterRows={filterRows}
                      cols={cols}
                      optonsLoading={optonsLoading}
                    />
                  </Col>
                  <Col md="auto" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    {!idx ? <Form.Label>id</Form.Label> : null}
                    <Switch
                      disabled={!row["identifier"] && filterRows.some(e => e["identifier"])}
                      checked={row["identifier"]}
                      onChange={(e) => handleToggleIdentifier(e, row, idx)}
                    />
                  </Col>
                </Row>
                <br />
              </div>
            )
          })}
          <div>
            <Button
              disabled={optonsLoading}
              onClick={() => setFilterRows((prev) => [...prev, { name: "", value: "", operator: "", identifier: false, idx: filterRows.length }])}>
              + New Filter
            </Button>
            <Button disabled={filterRows.length <= 0} variant="danger" onClick={() => setFilterRows([])}>Clear</Button>
          </div>
        </Card.Body>

        <Card.Footer>
          <div>
            <Button
              disabled={disableSubmit || previewLoading}
              type="submit"
            >
              {previewLoading ? <Spinner animation="border" /> : "Preview Rule"}
            </Button>
            {previewCount.total > 0 ?
              <Button style={{ float: "right" }} variant="success" onClick={(e) => handleSubmit(e, "create")}>
                {editExistingRule ? "Edit" : "Create"} Rule
              </Button>
              : null}
          </div>
          <Alert show={previewCount.total === 0} variant="warning" className="in">
            This rule seems to have no results. Found {previewCount.problems} problems out of {previewCount.total} total entries.
          </Alert>

          <Collapse in={previewCount.total > 0} onEntered={() => window.scrollTo(0, previewRef.current.offsetTop)}>
            <div ref={previewRef}>
              <RulesPreview preview={preview} count={previewCount} preview_columns={preview_columns} setPreview={setPreviewObj} />
            </div>
          </Collapse>
        </Card.Footer>
      </Card>
    </Form>
  )
}

const RulesPreview = ({ preview, count, preview_columns, setPreview }) => {
  return (
    <div>
      <Card>
        <Card.Header as="h3">
          Rule Preview Details
          <CloseButton style={{ float: "right" }} onClick={() => setPreview(null)} />
        </Card.Header>
        <Card.Body>
          Number of errors that this rule would catch (as of today): {count.problems} errors out of {count.total} entries.  <br />
          Here's a sample of the entries that would be caught by this rule. Only the top 100 are shown.
        </Card.Body>
        <Table
          columns={GetGenericColumns(preview, preview_columns)}
          data={preview}
          isExpand={true}
          singleExpand={true}
        />
      </Card>
    </div>
  )
}

const ValueFormInput = ({ row, idx, handleRowChange, filterRows, cols, optonsLoading }) => {
  let col = row["name"];
  if (cols["properties"][col] && cols["properties"][col]["type"] === "select" && !["like", "not like"].includes(row["operator"])) {
    // if the type is dropdown, and is not a 'like' statement
    // the purpose is to show dropdowns for all selectable types unless specified by a like statement
    // an in statement will provide a multiselect dropdown instead of a single select dropdown
    if (row["operator"] === "in" || row["operator"] === "not in") {
      // above, but also if it is an 'in' statement
      return (
        <>
          <WindowedSelect
            required
            isLoading={optonsLoading}
            onChange={(e) => handleRowChange(e, "value", idx)}
            value={arrayToLabelValue(filterRows[idx]["value"])}
            options={arrayToLabelValue(cols["properties"][col]["options"])}
            isMulti
          />
          <FakeInput value={arrayToLabelValue(filterRows[idx]["value"])} />
        </>
      )
    }
    else {
      // if not an in or like statement, but is a dropdown
      let obj = { label: filterRows[idx]["value"], value: filterRows[idx]["value"] }
      return (
        <>
          <WindowedSelect
            required
            isLoading={optonsLoading}
            onChange={(e) => handleRowChange(e, "value", idx)}
            value={obj["label"] ? obj : null}
            options={arrayToLabelValue(cols["properties"][col]["options"])}
          />
          <FakeInput value={obj["label"] ? obj : null} />
        </>
      )
    }
  }
  else {
    // inputs for regular text boxes (not selects)
    let type = "text"
    let placeholder1 = "From..."
    let placeholder2 = "To..."
    if (cols["properties"][col] && cols["properties"][col]["type"] === "date" && !row["rollingDate"])
      type = "date"
    else if (cols["properties"][col] && cols["properties"][col]["type"] === "number")
      type = "number"
    else if (row["rollingDate"]) {
      type = "number"
      placeholder1 = "Days Ago (Start)..."
      placeholder2 = "Days Ago (End)..."
    }
    if (row["operator"] === "between")
      return (
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col>
            <Form.Control
              placeholder={placeholder1}
              required
              disabled={!filterRows[idx]["name"]}
              type={type}
              value={filterRows[idx]["value"]}
              onChange={(e) => handleRowChange(e.target, "value", idx)}
            />
          </Col>
          <Col md="auto">and</Col>
          <Col>
            <Form.Control
              placeholder={placeholder2}
              required
              disabled={!filterRows[idx]["name"]}
              type={type}
              value={filterRows[idx]["value2"] || ""}
              onChange={(e) => handleRowChange(e.target, "value2", idx)}
            />
          </Col>
        </Row>
      )
    else {
      let placeholder = "Enter some filter value.";
      if (["in", "not in"].includes(row["operator"]))
        placeholder = "Enter a comma separated list of filters"
      return (
        <Form.Control
          placeholder={placeholder}
          required
          disabled={!filterRows[idx]["name"]}
          type={type}
          value={filterRows[idx]["value"] || ""}
          onChange={(e) => handleRowChange(e.target, "value", idx)}
        />
      )
    }
  }
}

const RuleItems = ({ newSubmission }) => {
  // pass in var with list of all rules
  // create copy to use to only show rules based on filters, without losing the original rule state
  // this copy is what the rows.map will list
  // filter via useeffect to check the state of all the filters whenever a filter input is made...
  // ... change the rule-copy state to reflect as a filtered list of the original

  const history = useHistory();
  const { user } = useContext(UserContext);

  const [rules, setRules] = useState([]);
  const [filteredRules, setFilteredRules] = useState([]);
  const [filters, setFilters] = useState({ rule_name: "", description: "", is_active: "" })

  const [toggleRule, setToggleRule] = useState(false);
  const [clickedRule, setClickedRule] = useState(null);
  const [clickedRuleDel, setClickedRuleDel] = useState(null);
  const [anchorEl, setAnchorEl] = useState({ element: null, id: null });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [refresh, setRefresh] = useState(0);


  useEffect(() => {
    postData('/billing_api/get_billing_rules', {}, setRules, setError, { setLoading: setLoading });
  }, [newSubmission])

  useEffect(() => {
    if (rules.length > 0) setFilteredRules(rules);
  }, [rules, refresh])

  useEffect(() => {
    if (toggleRule) {
      let temp = rules;
      let idx = temp.findIndex((e) => e.id === toggleRule.id);
      temp[idx] = toggleRule;
      setRules(temp);
      setRefresh(refresh + 1);
    }
  }, [toggleRule])

  useEffect(() => {
    if (Object.values(filters).every(value => !value)) {
      return setFilteredRules(rules);
    }
    else {
      let temp = filteredRules;
      temp = rules.filter(rule => {
        for (let filter in filters) {
          if (rule[filter] && typeof rule[filter] === "string") {
            if (!rule[filter].toLowerCase().includes(filters[filter].toLowerCase()))
              return false;
          }
          else if (typeof rule[filter] === "boolean") {
            if (filters[filter].length !== 0 && !filters[filter].map(f => f.value).includes(rule[filter]))
              return false;
          }
        }
        return true;
      })
      setFilteredRules(temp);
    }
  }, [filters.description, filters.is_active, filters.rule_name])


  const handleFilter = (e, key) => {
    let temp = filters;
    if (e.target && (e.target.value || e.target.value === ""))
      temp[key] = e.target.value;
    else
      temp[key] = e;
    setFilters(temp);
    setRefresh(refresh + 1)
  }

  const handleToggle = (e, rule, route) => {
    e.preventDefault();
    setClickedRule(rule.id)
    if (!loadingToggle) {
      postData(`/billing_api/${route}`, rule, setToggleRule, setError, { setLoading: setLoadingToggle })
    }
  }

  const handleDelete = (e, rule) => {
    e.preventDefault();
    postData(`/billing_api/delete_rule/${rule.id}`, {}, () => { }, setError, {
      setLoading: setModalLoading,
      successCallback: () => {
        let temp = filteredRules;
        temp = temp.filter(e => e.id !== rule.id)
        setRules(temp);
        setClickedRuleDel(null);
        setRefresh(refresh + 1);
      }
    })
  }

  if (loading) return <LinearProgress variant="indeterminate" />
  else
    return (
      <Card>
        <Modal
          centered
          onExited={() => setClickedRuleDel(null)}
          show={clickedRuleDel}>
          <Modal.Body>
            <Modal.Title as="h2">Confirm Deletion</Modal.Title>
            Are you sure you would like to delete the rule: <b>{clickedRuleDel ? clickedRuleDel.rule_name : ""}</b>? <br />
            This cannot be undone.
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-between" }}>
            <Button
              onClick={() => setClickedRuleDel(null)}
              disabled={modalLoading}
              className="btn-cls-danger">
              Cancel
            </Button>
            <Button
              onClick={(e) => handleDelete(e, clickedRuleDel)}
              disabled={modalLoading}
              style={{ float: "right" }}>
              {modalLoading ? <Spinner animation="border" /> : "Confirm"}
            </Button>
          </Modal.Footer>
        </Modal>
        <Row style={{ alignItems: "center" }}>
          <Col>
            <Form.Control
              placeholder="Filter Rules by Name"
              onChange={(e) => handleFilter(e, "rule_name")}
            />
          </Col>
          <Col>
            <Form.Control
              placeholder="Filter Rules by Description"
              onChange={(e) => handleFilter(e, "description")}
            />
          </Col>
          <Col>
            <Select
              isMulti
              placeholder="Filter Rules by Status"
              options={[{ label: "active", value: true }, { label: "inactive", value: false }]}
              onChange={(e) => handleFilter(e, "is_active")}
            />
          </Col>
        </Row>
        <br />
        {filteredRules.length === 0 && !loading &&
          <div style={{ textAlign: "center", color: "gray" }}>
            <hr />
            <h3>No Rules Found</h3>
            Sounds like Anarchy
          </div>
        }
        {filteredRules.map((rule, idx) => {
          const today = new Date();
          const expirationDate = new Date(rule.expires);
          const createdDate = new Date(rule.date_created);
          const daysUntilExpires = ((expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)).toFixed(0);
          const subs = rule.subscribers ? JSON.parse(rule.subscribers) : [];
          return (
            <div key={idx}>
              <Row style={{ display: "flex", alignItems: "center" }}>
                <Col md="2">
                  <h3 style={{ cursor: "pointer" }} onClick={() => history.push(`/BillingRules/Errors/${rule.id}`)}>{rule.rule_name}</h3>
                </Col>
                <Col md="4">
                  {rule.description}
                </Col>
                <Col md="4">
                  Rule created on: {rule.date_created.split("T")[0]}<br />
                  <a href="#" onClick={() => history.push(`/BillingRules/Errors/${rule.id}`)}>{rule.problems} errors caught by this rule <BiIcons.BiLink /></a><br />
                  <span style={{ fontWeight: "normal", color: daysUntilExpires < 15 ? "red" : "initial" }}>
                    {daysUntilExpires >= 0 ?
                      `Expires in ${daysUntilExpires} days` :
                      `Expired ${daysUntilExpires * -1} day(s) ago`}
                    <BiIcons.BiRefresh
                      onClick={(e) => handleToggle(e, rule, "refresh_rule_expiry")}
                      style={{ fontSize: "2rem", cursor: loadingToggle ? "" : "pointer", color: loadingToggle ? "gray" : "initial" }}
                      className={loadingToggle && rule.id === clickedRule ? "spin" : ""}
                    />
                    <br />
                  </span>
                  <a className={subs.length ? "gray-hover" : ""} onClick={(e) => setAnchorEl({ element: e.currentTarget, id: idx })}>
                    {subs ? subs.length : 0} subscriber{subs && subs.length === 1 ? "" : "s"} <FaIcons.FaEye />
                  </a>
                  {subs && subs.length > 0 &&
                    <Popover
                      open={(anchorEl && anchorEl.id === idx)}
                      onClose={() => setAnchorEl({ element: null, id: null })}
                      anchorEl={anchorEl.element}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      sx={{
                        '& .MuiPaper-root': {
                          boxShadow: "none",
                          border: "solid gainsboro 1px",
                          marginLeft: "1rem",
                          maxHeight: "25rem"
                        }
                      }}
                    >
                      <div style={{ padding: "1rem" }}>
                        {subs.map((sub, jdx) => {
                          return (
                            <div key={jdx}>
                              {sub}
                              {jdx < subs.length - 1 &&
                                <hr />}
                            </div>
                          )
                        })}
                      </div>
                    </Popover>
                  }
                </Col>
                <Col md="auto">
                  <Tooltip title={<p className="my-tooltip">{rule.is_active ? "Active" : "Disabled"}</p>}>
                    <span>
                      <FaIcons.FaCircle style={{ color: !rule.is_active ? "red" : "green" }} />
                    </span>
                  </Tooltip>
                  {" "}
                  <Tooltip title={<p className="my-tooltip">{rule.is_active ? "Disable this rule" : "Enable this rule"}</p>}>
                    <span>
                      <Switch
                        disabled={loadingToggle || daysUntilExpires < 0}
                        checked={rule.is_active}
                        onClick={(e) => handleToggle(e, rule, "toggle_rule")}>
                      </Switch>
                    </span>
                  </Tooltip>
                </Col>
                {user.role.includes("ADMIN") || user.role.includes("BILLING_ERROR_ADMIN") ?
                  <Col md="auto">
                    <BiIcons.BiTrash
                      onClick={() => setClickedRuleDel(rule)}
                      className="red-on-hover"
                      style={{ cursor: "pointer" }} />
                  </Col>
                  : null}
              </Row>
              {idx < (rules.length - 1) ? <hr /> : null}
            </div>
          )
        })}
      </Card >
    )
}

const FakeInput = ({ value }) => {
  return (
    <input
      tabIndex={-1}
      autoComplete="off"
      style={{ opacity: 0, height: 0, position: "absolute" }}
      onChange={() => { }}
      value={value ? value : ""}
      required={true}
    />
  )
}