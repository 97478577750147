import * as IoIcons from "react-icons/io";
import { useState, useEffect } from "react";

const NotificationCard = ({ severity, cardTitle, cardBody }) => {
  const [color, setColor] = useState("red_col");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setTitle(cardTitle);
      setBody(cardBody);
      checkValue();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const checkValue = () => {
    if (severity === "warning") setColor("#daca0f");
    else if (severity === "success") setColor("#198754");
    else if (severity === "error") setColor("#c31524");
    else setColor("#4a80af");
  };

  const SeverityIcon = () => {
    var style = { width: "70%", height: "70%", color: color };
    if (severity === "warning") return <IoIcons.IoMdWarning style={style} />;
    else if (severity === "success")
      return <IoIcons.IoMdCheckmarkCircleOutline style={style} />;
    else return <IoIcons.IoMdInformation style={style} />;
  };

  return (
    <div
      className="card mb-12 infusion-card"
      style={{ "backgroundColor": color + "19", "marginBottom": "1rem" }}
    >
      <div className="row g-0">
        <div
          className="col-md-1 red_col"
          style={{ "backgroundColor": color + "41" }}
        >
          {SeverityIcon()}
        </div>
        <div className="col">
          <div className="card-body">
            <h3 className="card-title infusion-title" style={{ "fontSize": "2.5rem" }}>
              {title}
            </h3>
            <p className="card-text" style={{ "fontSize": "1.5rem" }}>
              <strong>{body}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
