import React from "react";
import { Spinner } from "react-bootstrap";

const MySpinner = () => {
  return (
    <div
      className="mycontainer"
      style={{ marginBlock: "25vh", marginInline: "25vw" }}
    >
      <Spinner
        style={{ width: "13rem", height: "13rem" }}
        animation="border"
        size="sm"
      />
      <Spinner style={{ width: "25rem", height: "25rem" }} animation="border" />
      <Spinner
        style={{ width: "13rem", height: "13rem" }}
        animation="grow"
        size="sm"
      />
      <Spinner style={{ width: "25rem", height: "25rem" }} animation="grow" />
    </div>
  );
};

export default MySpinner;
