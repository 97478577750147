
import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import { BriefCollapse } from "../../Components/BriefCollapse";

const AppNav = () => {

  const applications = [
    {
      title: "Research",
      icon: (
        <img
          className="home-icons"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/research-icon.png"
          title="research icons"
        />
      ),
      url: "/showdata",
      className: "card-button",
    },
    {
      title: "Patient Portal",
      icon: (
        <img
          className="home-icons"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/patient-icon.png"
          title="patient icons"
        />
      ),
      url: "/patients",
      className: "card-button",
    },
    {
      title: "Billing",
      icon: (
        <img
          className="home-icons"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/billing-icon.png"
          title="billing icons"
        />
      ),
      url: "/billing",
      className: "card-button",
    },
    {
      title: "Billing Rules",
      icon: (
        <img
          className="home-icons"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/rules-icon.png"
          title="billing icons"
        />
      ),
      url: "/billingrules",
      className: "card-button",
    },

    // {
    //   title: "On-Call Schedule",
    //   icon: <FaIcons.FaAtlas />,
    //   url: "/oncall",
    //   className: "card-button",
    // },
    {
      title: "Infusion",
      icon: (
        <img
          className="home-icons"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/infusion-icon.png"
          title="billing icons"
        />
      ),
      url: "/infusion",
      className: "card-button",
    },
    {
      title: "Scorecards",
      icon: (
        <img
          className="home-icons"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/scorecard-icon.png"
          title="scorecard icons"
        />
      ),
      url: "/scorecards",
      className: "card-button",
    },
    // {
    //   title: "On-Call Admin",
    //   icon: <IoIcons.IoIosPaper />,
    //   url: "/on-call-admin",
    //   className: "card-button",
    // },
    // {
    //   title: "Admin",
    //   icon: <img className = "home-icons" src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/admin-con.png" title="billing icons"/>,
    //   url: "/admin",
    //   className: "card-button",
    // },
    // {
    //   title: "Profile",
    //   icon: <IoIcons.IoMdLaptop />,
    //   url: "/profile",
    //   className: "card-button",
    // },
    // {
    //   title: "Logout",
    //   icon: <IoIcons.IoMdExit />,
    //   url: "/logout",
    //   className: "card-logout",
    // },
  ];

  return (
    <>
      <Row>

        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                <h2>Portals</h2>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                {applications.map((apps, idx) => {
                  return (
                    <Col key={idx} md="6">
                      <a className="link" href={apps.url}>
                        <Card className={apps.className}>
                          {apps.icon}
                          {apps.title}
                        </Card>
                      </a>
                    </Col>
                  );
                })}
              </Row>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>
              <Card.Title>
                <h2>Latest Memo</h2>
              </Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Face Masks | 03/24/2022
              </Card.Subtitle>
            </Card.Header>
            <Card.Body>
              <Row>
                {/* <p style={{ whiteSpace: "pre-line" }}> */}
                <BriefCollapse>
                  CDC issued new COVID-19-related metrics that will allow many communities &
                  employers to ease their indoor masking requirements. Based on CDC guidelines, CLS
                  is making face masks optional unless provider deems otherwise for their clinic staff and
                  patient.

                  We are monitoring the ever-changing pandemic and will adjust this policy as needed to
                  protect both our employees and patients alike.
                  We again thank you for your continued efforts and dedication to protect our patients and
                  help in making CLS a safe working environment.
                </BriefCollapse>
                {/* </p> */}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AppNav;
