import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Table } from "../../../Components/Table";
import { GetUsersColumns } from "../../../Utilities/Columns";
import { RegisterUserFormCard } from "../../../Components/RegisterUserForm";
import MySpinner from "../../../Components/MySpinner";
import { UserContext } from "../../../App";
import getData from "../../../Utilities/getData";
import Edit from "../../../Utilities/Edit";
import { PopupHandler } from "../../../Components/PopupHandler";
import Delete from "../../../Utilities/Delete";
import { LoadingAlert } from "../../../Components/Alerts";

function AdminPage() {
  const { user } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const [fetchError, setFetchError] = useState("");

  const [changeSuccess, setChangeSuccess] = useState("");

  const history = useHistory();

  useEffect(() => {
    document.title = "Stream | Admin Panel"
    // Clean up controller
    // Prevents Memory leaks if closed before function finishes
    setLoadingData(true);
    let isSubscribed = true;
    if (isSubscribed)
      getData(
        setData,
        setFetchError,
        "/admin_api/get_users",
        setLoadingData,
        false
      );
    // cancel subscription to useEffect
    return () => (isSubscribed = false);
    // [id] prevents useEffect calls unless the id changes
  }, [changeSuccess, history]);

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    setSkipPageReset(false);
  }, []);

  if (user.email === null) {
    return <MySpinner />;
  }
  return (
    <div className="mycontainer">
      <div className="registerForm">
        <RegisterUserFormCard setMsg={setChangeSuccess} msg={changeSuccess} />
      </div>
      <Row>
        <Col>
          <PopupHandler
            handleTrueEdit={() => {
              setLoadingData(true);
              Edit(setChangeSuccess, history, "users", "/admin_api/edit_user");
            }}
            handleTrueDelete={() => {
              setLoadingData(true);
              Delete(
                setChangeSuccess,
                history,
                "users",
                "/admin_api/delete_user"
              );
            }}
          />
          <LoadingAlert isLoading={loadingData} />
          <Table
            columns={GetUsersColumns()}
            data={data}
            skipPageReset={skipPageReset}
            isEditable={true}
            isGlobalFilter={true}
          />
        </Col>
      </Row>
    </div>
  );
}

export default AdminPage;
