import React, { useState, useContext } from "react";
import { UserContext } from "../../App";
import { ChangeNameForm } from "./ProfileForms";
import {
  ErrorAlert,
  LoadingAlert,
  SuccessAlert,
} from "../../Components/Alerts";
import { postData } from "../../Utilities/apiRequests";

const ChangeNamePage = () => {
  const { user } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (formData) => {
    const data = {
      uid: user.uid,
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.curPassword,
    };
    if (
      user.first_name === formData.firstName &&
      user.last_name === formData.lastName
    ) {
      setError("Can't change name to current name.");
    } else {
      postData("/profile_api/change_name", data, setSuccess, setError, {
        setLoading: setLoading,
      });
    }
  };
  return (
    <>
      <ChangeNameForm onFormSubmit={handleSubmit} user={user} />
      <ErrorAlert error={error} setToggleAlert={setError} />
      <SuccessAlert msg={success} setToggleAlert={setSuccess} />
      <LoadingAlert isLoading={loading} />
    </>
  );
};

export default ChangeNamePage;
