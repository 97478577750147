export function trimJSON(input) {
  const keys = Object.keys(input);
  keys.forEach((key) => {
    if (typeof input[key] === "object") trimJSON(input[key]);
    else if (typeof input[key] === "string") {
      input[key] = input[key].trim();
    }
  });
  return input;
}
export function formatUpperCase(input) {
  var items = input.split(" ");
  var output = "";
  items.forEach((item, index) => {
    items[index] = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
  });
  for (var i = 0; i < items.length; i++) {
    if (i < items.length - 1) output += items[i] + " ";
    else output += items[i];
  }
  return output;
}

export const labelValueSerializer = (objects, label, value) => {
  var serializedObject = [];
  const isLabelArray = Array.isArray(label);
  const isValueArray = Array.isArray(value);
  objects.forEach((object) => {
    var objLabels = "";
    var objValues = "";
    if (isLabelArray) {
      objLabels = "";
      label.forEach((entry, idx) => {
        if (idx < label.length - 1 && object[entry])
          objLabels += object[entry] + " ";
        else if (object[entry]) objLabels += object[entry];
      });
    } else {
      typeof object[label] === "string"
        ? (objLabels = object[label].trim())
        : (objLabels = "N/A");
    }

    if (isValueArray) {
      objValues = "";
      value.forEach((entry, idx) => {
        if (idx < value.length - 1 && object[entry])
          objValues += object[entry] + " ";
        else if (object[entry]) objValues += object[entry];
      });
    } else {
      objValues = object[value];
    }

    serializedObject.push({
      value: objValues,
      label: objLabels,
    });
  });

  return serializedObject;
};

export const arrayToLabelValue = (objects) => {
  if (Array.isArray(objects)) {
    let l = []
    objects.forEach(e => {
      l.push({ label: e, value: e })
    })
    return l
  }
}

export const getValues = (arr, key) => {
  let values = [];
  arr.forEach((item) => {
    values.push(item[key]);
  });
  return values;
};

export const formatDate = (dateInput = null) => {
  var date;
  if (dateInput) {
    date = new Date(dateInput);
  } else {
    date = new Date();
  }

  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};
