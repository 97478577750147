import React, { useState, useEffect, useRef } from "react";
import { Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { formatUpperCase, labelValueSerializer } from "../../Utilities/Formaters";
import WindowedSelect, { createFilter } from "react-windowed-select";
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";
import * as IoIcons from "react-icons/io";
import { useForm } from "../../Utilities/useForm";
import { postData } from "../../Utilities/apiRequests";
import { SuccessAlert, ErrorAlert } from "../../Components/Alerts";

export const PatientForm = ({
  onFormSubmit,
  setPatientInfo,
  setInitials,
  handleChange,
  patients,
  patientInfo,
  initials,
  loading,
  patientLoading,
  staxLoading,
}) => {
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [lookupDisabled, setLookupDisabled] = useState(true);
  const secondInitialRef = useRef(null);
  const fullNameRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.removeItem("defaultFacility")
    localStorage.removeItem("defaultProvider")
    localStorage.removeItem("defaultResource")
    onFormSubmit();
  };

  useEffect(() => {
    var dob = new Date(patientInfo.dob);
    if ((initials.fname !== "" && initials.lname !== "") || dob.getFullYear() >= 1900) {
      setLookupDisabled(false);
    } else {
      setLookupDisabled(true);
    }
  }, [initials.fname, initials.lname, patientInfo.dob])

  useEffect(() => {
    if (!lookupDisabled) {
      fullNameRef.current.focus();
    }
  }, [lookupDisabled])

  return (
    <Card>
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Card.Header>
          <h2>
            <IoIcons.IoMdCheckmarkCircleOutline></IoIcons.IoMdCheckmarkCircleOutline>{" "}
            1. Patient Lookup
          </h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <p>
              Enter either the patient's date of birth, or their initials into
              the appropriate fields to get started.
            </p>
            <Row>
              <Col>
                <Form.Label> Patient's Date of Birth </Form.Label>
                <Form.Control
                  type="date"
                  value={patientInfo.dob}
                  onChange={(e) => {
                    setPatientInfo(
                      (prevValues) => ({
                        ...prevValues,
                        dob: e.target.value,
                      }),
                      handleChange(e.target.value)
                    );
                  }}
                />
              </Col>
              <Col md="6">
                <Row>
                  <Col>
                    <Form.Label>First Initial</Form.Label>
                    <Form.Control
                      maxLength="1"
                      placeholder="A-Z..."
                      value={initials.fname}
                      onChange={(e) => {
                        setInitials((prevSinglePost) => ({
                          ...prevSinglePost,
                          fname: e.target.value,
                        }));
                        if (e.target.value !== "")
                          secondInitialRef.current.focus();
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Last Initial</Form.Label>
                    <Form.Control
                      maxLength="1"
                      placeholder="A-Z..."
                      value={initials.lname}
                      ref={secondInitialRef}
                      onChange={(e) => {
                        setInitials((prevSinglePost) => ({
                          ...prevSinglePost,
                          lname: e.target.value,
                        }));
                      }}
                    />
                  </Col>
                </Row>
              </Col>

            </Row>
            <Row>
              <Col>
                <Form.Label> Patient's Name </Form.Label>
                <WindowedSelect
                  options={labelValueSerializer(
                    patients,
                    ["ufname", "ulname"],
                    "uid"
                  )}
                  placeholder="Name..."
                  autoComplete="none"
                  isDisabled={lookupDisabled}
                  isLoading={loading}
                  ref={fullNameRef}
                  value={labelValueSerializer(
                    patients,
                    ["ufname", "ulname"],
                    "uid"
                  ).filter((obj) => patientInfo.uid === obj.value)}
                  onChange={(e) => {
                    const patient = patients.filter(
                      (obj) => e.value == obj.uid
                    )[0];
                    setPatientInfo(patient);
                  }}
                />
              </Col>
            </Row>
          </Row>
          <div style={{ marginTop: "10px" }}>
            <MDBBtn className="margin-button" color="cls" type="submit">
              {(patientLoading || staxLoading) && <Spinner animation="grow" variant="light" />}
              {(!patientLoading && !staxLoading) ? "Submit" : " Loading..."}
            </MDBBtn>
            <MDBBtn
              className="margin-button"
              color="cls-danger"
              onClick={() => {
                setIsNewPatient(true);
              }}
            >
              New Patient
            </MDBBtn>
          </div>


        </Card.Body>
        <input
          type="text"
          autoComplete="on"
          value=""
          style={{ display: 'none', opacity: 0, position: 'absolute', left: '-100000px' }}
          readOnly={true}
        />

      </Form>
      <NewPatientForm
        setIsNewPatient={setIsNewPatient}
        isNewPatient={isNewPatient}
        setPatientInfo={setPatientInfo}
        showNewPatient={onFormSubmit}
      />
    </Card>
  );
};

export const NewPatientForm = ({
  isNewPatient,
  setIsNewPatient,
  showNewPatient,
  setPatientInfo,
}) => {
  const initialState = {
    ufname: "",
    ulname: "",
    dob: "",
  };
  const [formData, handleChange, setFormData] = useForm(initialState);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const resetForm = () => {
    setSuccess(`Added New Patient: ${formData.ufname} ${formData.ulname}`);
    setFormData(initialState);
    setIsNewPatient(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    formData.ufname = formatUpperCase(formData.ufname);
    formData.ulname = formatUpperCase(formData.ulname);
    postData("/patients_api/new_patient", formData, showNewPatient, setError, {
      successCallback: resetForm(),
    });
  };
  return (
    <MDBModal
      className="mycontainer"
      isOpen={isNewPatient}
      size="sm"
      toggle={() => setIsNewPatient(false)}
    >
      <MDBModalHeader titleClass="h2">Add a New Patient</MDBModalHeader>
      <MDBModalBody>
        <Form onSubmit={handleSubmit}>
          {/* <Row className="mb-3"> */}
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name="ufname"
              onChange={handleChange}
              // value={formData.ufname}
              placeholder="Enter First Name"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="ulname"
              onChange={handleChange}
              placeholder="Enter Last Name"
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label style={{ marginBottom: "1rem" }}>
              Date of Birth
            </Form.Label>
            <Form.Control
              type="date"
              className="form-control"
              name="dob"
              // value={formData.dob}
              onChange={handleChange}
            />
          </Form.Group>

          <MDBModalFooter>
            <MDBBtn onClick={() => setIsNewPatient(false)}>Close</MDBBtn>
            <MDBBtn color="primary" type="submit">
              Submit
            </MDBBtn>
          </MDBModalFooter>
        </Form>
        <ErrorAlert error={error} setToggleAlert={setError} />
        <SuccessAlert
          msg={success !== null ? JSON.stringify(success) : success}
          setToggleAlert={setSuccess}
        />
      </MDBModalBody>
    </MDBModal>
  );
};
