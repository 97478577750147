import React, { useState, useContext } from "react";
import { UserContext } from "../App";
import { useHistory } from "react-router-dom";
import Widgets from "@mui/icons-material/WidgetsRounded";
import {
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  ListItemIcon,
  IconButton,
  Divider,
  ButtonBase,
} from "@mui/material";

export const CustomizedMenus = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const pages = [
    {
      textContent: "Home",
      icon: (x) => (
        <img
          className="app-img"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/home-icon.png"
          title="Home"
        />
      ),
      action: "/home",
    },
    {
      textContent: "Research",
      icon: (x) => (
        <img
          className="app-img"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/research-icon.png"
          title="Research Query"
        />
      ),
      action: "/showdata",
    },
    {
      textContent: "Infusion",
      icon: (x) => (
        <img
          className="app-img"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/infusion-icon.png"
          title="Infusion"
        />
      ),
      action: "/infusion",
    },
    {
      textContent: "Patient Lookup",
      icon: (x) => (
        <img
          className="app-img"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/patient-icon.png"
          title="Patient Lookup"
        />
      ),
      action: "/patients",
      role: "PATIENT_MANAGER"
    },
    {
      textContent: "Billing",
      icon: (x) => (
        <img
          className="app-img"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/billing-icon.png"
          title="Billing"
        />
      ),
      action: "/billing",
    },
    {
      textContent: "Rules",
      icon: (x) => (
        <img
          className="app-img"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/rules-icon.png"
          title="Billing Rules"
        />
      ),
      action: "/billingrules",
      // role: ["BILLING_ERROR", "BILLING_ERROR_ADMIN"]
    },
    {
      textContent: "Scorecards",
      icon: (x) => (
        <img
          className="app-img"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/scorecard-icon.png"
          title="Scorecards"
        />
      ),
      action: "/scorecards",
      // role: "BILLING_ADMIN"
    },
    {
      textContent: "Admin",
      icon: (x) => (
        <img
          className="app-img"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/admin-icon.png"
          title="Admin"
        />
      ),
      action: "/admin",
      role: "ADMIN"
    },
    {
      textContent: "IAM Policies",
      icon: (x) => (
        <img
          className="app-img"
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/IAM-icon.png"
          title="IAM"
        />
      ),
      action: "/iam",
      role: "BILLING_ADMIN"
    },
  ];

  const settings = [
    // {
    //   textContent: "Report an Issue",
    //   icon: <EditIcon />,
    //   action: () => setShowBugReport(true),
    // },
    {
      textContent: "Logout",
      icon: (x) => (
        <img
          width={x}
          src="https://clshealthblob.blob.core.windows.net/stream-objects/icons/logout-icon.png"
          title="Logout"
        />
      ),
      action: () => {
        history.push("/logout");
        handleCloseUserMenu();
      },
    },
  ];

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Tooltip title={<p className="my-tooltip">Stream apps</p>}>
        <IconButton className="nav-icon-button" onClick={handleOpenUserMenu}>
          <Widgets color="primary" style={{ fontSize: "4rem" }} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{
          mt: "45px",
          p: "1rem",
        }}
        PaperProps={{
          style: {
            width: "314px",
            padding: "6px 0 0 14px",
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        style={{ padding: "1rem" }}
      >
        <div style={{ maxHeight: "40rem", overflow: "scroll" }}>
          {pages.map((page, idx) => {
            if (!page.role || (page.role && (user.role.includes(page.role))) || user.role.includes("ADMIN"))
              return (
                <MenuItem className="appnav" disableRipple key={idx}>
                  <ButtonBase
                    LinkComponent={"a"}
                    href={page.action}
                    className="app-link"
                    onClick={handleCloseUserMenu}
                  >
                    <div className="icon-left hidden-borders"></div>
                    <div className="icon-right hidden-borders"></div>
                    <div className="icon-bottom hidden-borders"></div>
                    <div className="icon-top hidden-borders"></div>
                    <div className="img-div">{page.icon("64")}</div>
                    <Typography className="app-title">{page.textContent}</Typography>
                  </ButtonBase>
                </MenuItem>
              )
          })}
        </div>

        {/* <Divider /> */}
        <hr />
        <div>
          {settings.map((setting, idx) => (
            <MenuItem
              className="appnav-settings"
              key={idx}
              onClick={setting.action}
            >
              <ListItemIcon>{setting.icon("20")}</ListItemIcon>
              <Typography fontSize={"1.5rem"} textAlign="center">
                {setting.textContent}
              </Typography>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </>
  );
};
