import React, { useState, useEffect, useRef } from "react";
import { Collapse, Card, Tabs, Tab } from "react-bootstrap";
import { MDBRow as Row, MDBCol as Col, MDBBtn as Button } from "mdbreact";
import getData from "../../Utilities/getData";
import { postData } from "../../Utilities/apiRequests";
import { ErrorAlert } from "../../Components/Alerts";
import { PatientForm } from "./PatientForm";
import { NewPatientCard, PatientCard } from "./PatientCard";
import { Transactions } from "./Transactions";
import { PaidTransactions } from "./PaidTransactions";
import { QuickLookup } from "./QuickLookup";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "../../Utilities/useForm";
import { AdditionalPayment } from "./AdditionalPayment";
import { getCookie, setCookie } from "../../Utilities/LoginUtil";
import { QRCodeCanvas } from "qrcode.react";
import { createPDF } from "./Checkout";

const PatientPage = () => {
  const { uid } = useParams();
  const history = useHistory();
  const [refresh, setRefresh] = useState(0);

  const [isPdf] = useState(window.location.pathname.split("/")[3] == "pdf");
  const [pdfOpened, setPdfOpened] = useState(false);

  const patientRef = useRef(null);
  const copayRef = useRef(null);
  const transRef = useRef(null);
  let defaultProvider = localStorage.getItem("defaultProvider");
  let defaultFacility = localStorage.getItem("defaultFacility");
  let defaultResource = localStorage.getItem("defaultResource");
  let defaultPaymentType = localStorage.getItem("defaultPaymentType");

  defaultProvider = defaultProvider
    ? JSON.parse(defaultProvider)
    : { label: undefined, value: undefined };

  defaultResource = defaultResource
    ? JSON.parse(defaultResource)
    : { label: "None", value: 50 };

  defaultResource =
    defaultResource.value != defaultProvider.value
      ? defaultResource
      : { label: "None", value: 50 };

  defaultFacility = defaultFacility
    ? JSON.parse(defaultFacility)
    : { facilityName: "", BusinessCode: "" };
  defaultPaymentType = defaultPaymentType ? defaultPaymentType : "COPAY";

  const initalPatientInfoState = {
    uid: "",
    Name: "",
    ufname: "",
    ulname: "",
    dob: "",
    phone: "",
    isNew: "",
  };

  const initialPatientIntialsState = { fname: "", lname: "" };
  const initialPatientPostData = { dob: "", initials: [] };

  const [initials, setInitials] = useState(initialPatientIntialsState);
  const [patientPostData, setPatientPostData] = useState(
    initialPatientPostData
  );

  const [patientLoading, setPatientLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [staxLoading, setStaxLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);
  const [transSuccess, setTransSuccess] = useState(false);

  const [showData, setShowData] = useState(false);
  const [showTrans, setShowTrans] = useState(false);
  const [showCopay, setShowCopay] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);

  const [patients, setPatients] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [paidTransactions, setPaidTransactions] = useState([]);

  const [patientInfo, setPatientInfo] = useState(initalPatientInfoState);
  const [facilities, setFacilities] = useState([]);
  const [patient, setPatient] = useState("");
  const [customer, setCustomer] = useState("");

  const [cart, setCart] = useState([]);
  const [sum, setSum] = useState(0.0);
  const [copay, setCopay] = useState(0.0);
  var billed = 0;
  var adjustment = 0;
  var inspaid = 0;
  var ptpaid = 0;
  var total = 0;
  var allowed = 0;
  const [paymentStatus, setPaymentStatus] = useState({
    billed: billed,
    adjustment: adjustment,
    inspaid: inspaid,
    ptpaid: ptpaid,
    total: total,
    allowed: allowed,
  });
  const [info, handleInfoChange, setInfo] = useForm({
    provider: defaultProvider.label,
    doctorId: defaultProvider.value,
    resourceName: defaultResource.label,
    resourceId: defaultResource.value,
    FacilityName: defaultFacility.label,
    Facility_ID: defaultFacility.value,
    paymentType: defaultPaymentType,
  });
  var initialState = {
    ProviderName: info.provider,
    doctorId: info.doctorId,
    FacilityName: info.FacilityName,
    Facility_ID: info.Facility_ID,
    FacilityAddress: "",
    DateOfService: new Date().toUTCString(),
    CPTCode: info.paymentType,
    PatientPaid: "",
    PatientUnpaid: "",
    Description: "",
    BusinessCode: "N/A",
    EntryNumber: "N/A",
    ChartNumber: "N/A",
    dispute: false,
    resourceName: info.resourceName,
    resourceId: info.resourceId,
  };
  const [formData, setFormData] = useState(initialState);
  const [providers, setProviders] = useState([]);

  const getProviders = (x = 0) => {
    getData(setProviders, setError, `/patients_api/get_providers/${1}`);
  };

  const getFacilities = (x = 0) => {
    getData(setFacilities, setError, `/patients_api/get_facilities`);
  };

  useEffect(() => {
    document.title = "Stream | Patient Lookup"
    getProviders();
    getFacilities();
    return () => { };
  }, []);

  useEffect(() => { //make api request on page load, using param as uid if available
    if (uid) {
      postData("/patients_api/patient_who", { uid: uid }, setPatient, setError, {
        setLoading: setPatientLoading,
        history: history,
        successCallback: async () => {
          setShowData(true)
          // await new Promise(r => setTimeout(r, 250));
          // setShowCopay(true)
          // await new Promise(r => setTimeout(r, 600));
          // setShowTrans(true)
        }
      });
    }
  }, [uid])

  useEffect(() => {
    if (transactions.length > 0 && patient && isPdf && !pdfOpened) {
      createPDF(null, { cart: transactions, patient: patient, copay: copay }, null, "_self");
      setPdfOpened(true);
    }
  }, [transactions])

  /// LOOOK UP
  useEffect(() => {
    if (patientPostData.dob !== "" || patientPostData.initials.length > 0) {
      setCustomer("");
      setLoading(true);
      postData(
        "/patients_api/get_patients",
        patientPostData,
        setPatients,
        setError,
        { setLoading: setLoading, history: history }
      );
    }
  }, [patientPostData]);

  /// LOOOK UP
  useEffect(() => {
    if (initials.fname !== "" && initials.lname !== "") {
      setPatientPostData({
        ...patientPostData,
        initials: [initials.fname, initials.lname],
      });
      resetForm();
    }
  }, [initials]);

  /// WHY???
  useEffect(() => {
    if (uid && patient) {
      handleSubmit({
        ufname: patient.ufname,
        ulname: patient.ulname,
        uid: patient.uid,
        Name: `${patient.ufname} ${patient.ulname}`,
        dob: patient.dob,
        phone: patient.phone,
        isNew: patient.is_new,
      })
    }
  }, [patient]);

  /// WHY???
  useEffect(() => {
    if (submitForm) {
      //Manage lookup history cookie states on submit.
      let historyCookie = JSON.parse(getCookie("LookupHistory"));
      historyCookie.forEach((e, idx) => {
        if (e.uid === patient.uid) {
          if (cart.length === 0) {
            historyCookie[idx].collected = false;
          } else {
            historyCookie[idx].collected = true;
          }
          setCookie("LookupHistory", JSON.stringify(historyCookie), 0.5);
        }
      });

      history.push({
        pathname: "/checkout",
        state: {
          cart: cart,
          patient: patient,
          total: sum,
          copay: copay,
          customer: customer,
          potential_collection: paymentStatus.total,
        },
      });
    }
  }, [submitForm]);

  const handleChange = (dob) => {
    dob = new Date(dob);
    if (dob.getFullYear() > 1900) {
      setPatientPostData({ ...patientPostData, dob: dob });
      resetForm();
    } else if (isNaN(Date.parse(dob))) {
      setPatientPostData({ ...patientPostData, dob: "" });
    }
  };

  const resetForm = () => {
    setShowData(false);
    setShowTrans(false);
    setShowCopay(false);
    setTransSuccess(false);
    setTransactions([]);
    setCart([]);
    setCopay(0);
    setSum(0);
  };

  const handleSubmit = (pat = patientInfo) => {
    resetForm();
    if (pat.uid == "") {
      setError("Enter a patient.");
      return;
    }
    if (!uid) { //only run this if there is no url param; already done in useeffect.
      postData("/patients_api/patient_who", pat, setPatient, setError, {
        setLoading: setPatientLoading,
        history: history,
        successCallback: () => setShowData(true)
      });
    }

    postData(
      "/patients_api/get_transactions",
      pat,
      (data) => {
        setTransactions(data);
        data.forEach((e) => {
          billed += e.Billed;
          adjustment += e.Adjustment;
          inspaid += e.Ins1Paid + e.Ins1Paid + e.Ins1Paid;
          ptpaid +=
            e.Remaining === undefined
              ? e.Billed + e.Adjustment - e.PatientUnpaid
              : e.Billed + e.Adjustment - e.Remaining;
          total += e.Remaining === undefined ? e.PatientUnpaid : e.Remaining;
        });
        allowed = billed + adjustment;
        setPaymentStatus({
          billed: billed,
          adjustment: adjustment,
          inspaid: inspaid,
          ptpaid: ptpaid,
          total: total,
          allowed: allowed,
        });
      },
      setError,
      {
        setLoading: setTableLoading,
        history: history,
        successCallback: () => {
          setTransSuccess(true);
        },
        timeout: 25000,
      }
    );
    postData(
      "/patients_api/get_paid_transactions",
      pat,
      setPaidTransactions,
      setError,
      {
        history: history,
        timeout: 25000,
      }
    );
    postData("/payment_api/find_customer", pat, setCustomer, setError, {
      setLoading: setStaxLoading,
    });
  };

  const handleCheckout = (event) => {
    event.preventDefault();
    if (formData.PatientPaid !== "" && !isNaN(formData.PatientPaid)) {
      setCart((cart) => [...cart, formData]);
    }

    setSubmitForm(true);
  };

  const handleShowTrans = () => {
    if (transactions.length === 0) {
      postData(
        "/patients_api/get_transactions",
        patientInfo,
        (data) => {
          setTransactions(data);
          data.forEach((e) => {
            billed += e.Billed;
            adjustment += e.Adjustment;
            inspaid += e.Ins1Paid + e.Ins1Paid + e.Ins1Paid;
            ptpaid +=
              e.Remaining === undefined
                ? e.Billed + e.Adjustment - e.PatientUnpaid
                : e.Billed + e.Adjustment - e.Remaining;
            total += e.Remaining === undefined ? e.PatientUnpaid : e.Remaining;
          });
          allowed = billed + adjustment;
        },
        setError,
        { setLoading: setTableLoading, history: history }
      );
    }
    setShowTrans(true);
  };

  const handleShowCopay = () => {
    setInfo({
      provider: defaultProvider.label,
      FacilityName: defaultFacility.label,
      Facility_ID: defaultFacility.value,
      doctorId: defaultProvider.value,
      resourceName: defaultResource.label,
      resourceId: defaultResource.value,
      Business_Code: "N/A",
      paymentType: defaultPaymentType,
    });
    initialState.Facility_ID = defaultFacility.value;
    initialState.FacilityName = defaultFacility.label;
    initialState.doctorId = defaultProvider.value;
    initialState.ProviderName = defaultProvider.label;
    initialState.resourceId = defaultResource.value;
    initialState.resourceName = defaultResource.label;
    setFormData(initialState);
    setShowCopay(true);
  };

  useEffect(() => {
    if (transSuccess) addToHistory();
  }, [transSuccess]);

  const addToHistory = () => {
    let lookupHistory = getCookie("LookupHistory")
      ? JSON.parse(getCookie("LookupHistory"))
      : [];
    let balance = 0;

    transactions.forEach((e) => {
      balance += e.Remaining;
    });

    let historyData = {
      uid: patient.uid,
      ufname: patient.ufname,
      ulname: patient.ulname,
      dob: patient.dob
        ? new Date(patient.dob).toISOString().split("T")[0]
        : patient.dob,
      balance: balance,
      collected: false,
    };
    if (!lookupHistory.find(({ uid }) => uid === patient.uid)) {
      //check if person already exists in history cookie
      lookupHistory.unshift(historyData);
      if (lookupHistory.length > 10) lookupHistory.pop();
      setCookie("LookupHistory", JSON.stringify(lookupHistory), 0.5);
      setRefresh(refresh + 1);
    }
  };

  return (
    <>
      <QRCodeCanvas style={{ display: "none" }} value={`${window.location.origin}/paymentportal/${patient.uid}`} />
      <QuickLookup
        onFormSubmit={handleSubmit}
        providers={providers}
        facilities={facilities}
        formData={formData}
        setPatientInfo={setPatientInfo}
      />
      <PatientForm
        onFormSubmit={handleSubmit}
        setPatientInfo={setPatientInfo}
        setInitials={setInitials}
        handleChange={handleChange}
        patients={patients}
        patientInfo={patientInfo}
        initials={initials}
        loading={loading}
        patientLoading={patientLoading}
        staxLoading={staxLoading}
      />
      <ErrorAlert error={error} setToggleAlert={setError} />
      <Collapse
        in={showData && !staxLoading /*&& transSuccess*/}
        onEntered={() => window.scrollTo(0, patientRef.current.offsetTop)}
      >
        <div ref={patientRef}>
          {patient.is_new ? (
            <NewPatientCard
              patient={patient}
              customer={customer}
              showCopay={handleShowCopay}
            />
          ) : (
            <PatientCard
              patient={patient}
              transactions={transactions}
              showCopay={handleShowCopay}
              loading={tableLoading}
              paymentStatus={paymentStatus}
            />
          )}
        </div>
      </Collapse>
      <Collapse
        in={showCopay}
        onEntered={() => window.scrollTo(0, copayRef.current.offsetTop)}
      >
        <div ref={copayRef}>
          <AdditionalPayment
            handleShowTrans={handleShowTrans}
            setSum={setCopay}
            setFormData={setFormData}
            formData={formData}
            setInfoChange={setInfo}
            info={info}
            getProviders={getProviders}
            providers={providers}
            getFacilities={getFacilities}
            facilities={facilities}
          />
        </div>
      </Collapse>
      <Collapse
        in={showTrans}
        onEntered={() => window.scrollTo(0, transRef.current.offsetTop)}
      >
        <div ref={transRef}>
          <Card>
            <Tabs defaultActiveKey={"unpaid"} style={{ marginBottom: "10px" }}>
              <Tab eventKey={"unpaid"} title="Unpaid">
                <Transactions
                  transactions={transactions}
                  setCart={setCart}
                  cart={cart}
                  setSum={setSum}
                  copay={copay}
                  patient={patient}
                  total={sum}
                />
              </Tab>
              <Tab eventKey={"paid"} title="Paid">
                <PaidTransactions
                  transactions={paidTransactions}
                  patient={patient}
                  copay={copay}
                />
              </Tab>
            </Tabs>
          </Card>
        </div>
      </Collapse>
      <Collapse in={cart.length > 0 || sum > 0 || copay > 0 || showTrans}>
        <div
          style={{ position: "fixed", left: "0", right: "0", bottom: "-2rem" }}
        >
          {/* make into css */}
          <Card>
            <Row style={{ alignItems: "center", justifyContent: "right" }}>
              {cart.length > 0 || sum > 0 || copay > 0 ? (
                <>
                  <Col>
                    <h3>Total Charge:</h3>
                  </Col>
                  <Col md="auto">
                    <h3>
                      <Row>
                        <Col>Charged</Col>
                        <Col>Copay</Col>
                      </Row>
                      <Row>
                        <Col>
                          $
                          {isNaN(sum)
                            ? parseFloat(0).toFixed(2)
                            : parseFloat(sum).toFixed(2)}
                        </Col>
                        <Col>
                          $
                          {isNaN(copay)
                            ? parseFloat(0).toFixed(2)
                            : parseFloat(copay).toFixed(2)}
                        </Col>
                      </Row>
                    </h3>
                  </Col>
                  <Col md="auto">
                    <Button color="cls-danger" onClick={handleCheckout}>
                      Checkout
                    </Button>
                  </Col>
                </>
              ) : (
                <Col md="auto">
                  <Button
                    style={{ width: "fit-content" }}
                    onClick={() => window.location.reload(true)}
                  >
                    Search New Patient
                  </Button>
                </Col>
              )}
            </Row>
          </Card>
        </div>
      </Collapse>
    </>
  );
};
export default PatientPage;
