import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import { Spinner } from "react-bootstrap";
import { ErrorAlert } from "../../Components/Alerts";
import { Table } from "../../Components/Table";
import { GetTransactionsColumns } from "../../Utilities/Columns";
import { postData } from "../../Utilities/apiRequests";

export const MerchantTransactions = ({ uid, range = 7 }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    setData([])
    postData(`/payment_api/get_merchant_transactions/${user.uid}`, { range: range }, setData, setError, { setLoading: setLoading })
  }, [])

  return (
    <>
      <section>
        {user.first_name} {user.last_name} (Merchant {user.uid}) <br />

        {range != 0 && (
          <>
            Here are all the payments that you've processed in the last {range} days. <br />
            You will only be able to void transactions made after a batch is settled.
          </>
        )
        }
      </section>
      {loading && <> <Spinner animation="border" variant="dark" /> Loading... </>}
      <ErrorAlert error={error} setToggleAlert={setError} />
      <Table columns={GetTransactionsColumns()} data={data} isPrintable={true} showTotal={"Total"} />
    </>
  );
};
