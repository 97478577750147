export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  else return "";
}

export function setCookie(cname, cvalue, exdays = undefined) {
  if (exdays !== undefined) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  } else {
    document.cookie = cname + "=" + cvalue + ";" + "path=/";
  }
}
// export function getCookie(name) {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(";").shift();
//   else return "";
// }

export const checkPasswordStrength = (input, user) => {
  var firstName, lastName;
  if (user.first_name) {
    firstName = user.first_name;
    lastName = user.last_name;
  } else {
    firstName = user.firstName;
    lastName = user.lastName;
  }
  var numOfCap = 0,
    numOfSpecial = 0,
    numOfNum = 0,
    containsName = false;
  var pattern = new RegExp(/[~`!@#$%^&*()+=\-_[\]\\';,/{}|\\":<>?]/); //special characters
  if (
    (input.toLowerCase().includes(firstName.toLowerCase()) ||
      input.toLowerCase().includes(lastName.toLowerCase()) ||
      input.toLowerCase().includes(user.email.toLowerCase())) &&
    firstName !== "" &&
    lastName !== "" &&
    user.email !== ""
  )
    containsName = true;
  [...input].forEach((letter) => {
    if (letter.toUpperCase() === letter && /[a-zA-Z]/.test(letter)) numOfCap++;
    if (pattern.test(letter)) numOfSpecial++;
    if (/[0-9]/.test(letter)) numOfNum++;
  });
  return (
    `${input.length < 8 ? "Password must be at least 8 characters long.\n" : ""
    }` +
    `${numOfCap < 1 ? "Password must contain at least 1 capital letter.\n" : ""
    }` +
    `${numOfSpecial < 1
      ? "Password must contain at least 1 special character.\n"
      : ""
    }` +
    `${numOfNum < 1
      ? "Password must contain at least 1 numeric character.\n"
      : ""
    }` +
    `${containsName ? "Password cannot contain your name or email." : ""}`
  );
};
