import { Form, Row, Col, Card, Collapse } from "react-bootstrap";
import { MDBBtn as Button } from "mdbreact";
import { labelValueSerializer } from "../../Utilities/Formaters";
import { useState, useEffect } from "react";
import WindowedSelect from "react-windowed-select";
export const InfusionForm = ({ insurance, medication, handleSubmit, showUnits }) => {
  const [values, setValues] = useState({
    CPTCode: "",
    Street1: "",
    City: "",
    State: "",
    ZipCode: "",
    Ins: "",
    units: "",
    dose: "",
    duration: "",
  });
  var decriptAddress = false;
  var decriptIns = false;
  if (values.Ins !== "") {
    decriptAddress = true;
    values.Street1 = "";
    values.City = "";
    values.State = "";
    values.ZipCode = "";
  }
  if (
    values.Street1 !== "" ||
    values.City !== "" ||
    values.State !== "" ||
    values.ZipCode !== ""
  ) {
    decriptIns = true;
    values.Ins = "";
  }

  useEffect(() => {
    if(values.CPTCode !== "" && values.Ins !== ""){
      handleSubmit(values);
    }
  }, [values.CPTCode, values.Ins]);
  

  const handleChange = (name, e) => {
    var set = "";
    if (e === null) set = e;
    else set = e.value;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: set,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(values);
  };
  const filterOptions = (candidate, input) => {
    let possible =
      medication
        .filter((med) =>
          med.Short_Description.toLowerCase().includes(input.toLowerCase())
        )
        .filter((opts) => opts.CPTCode === candidate.value).length > 0;
    let possibleAlso = candidate.value
      .toLowerCase()
      .includes(input.toLowerCase());
    let possibleAlsoAlso = candidate.label
      .toLowerCase()
      .includes(input.toLowerCase());
    return possible || possibleAlso || possibleAlsoAlso;
  };
  return (
    <Card className="stream-card">
      <Form autoComplete="off" onSubmit={handleFormSubmit}>
        <div style={{ marginBottom: "2rem" }}>
          <Row>
            <h2> Please Enter a CPT Code and Insurance </h2>
          </Row>
          <Row>
            <Col>
              <Form.Label className="registerForm">
                Medication Name:{" "}
              </Form.Label>
              <WindowedSelect
                name="CPTCode"
                options={labelValueSerializer(
                  medication,
                  "Drug_Description",
                  "CPTCode"
                )}
                value={labelValueSerializer(
                  medication,
                  "Drug_Description",
                  "CPTCode"
                ).filter((obj) => values.CPTCode === obj.value)}
                onChange={(e) => handleChange("CPTCode", e)}
                filterOption={filterOptions}
                isClearable
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="registerForm">Insurance: </Form.Label>
              <WindowedSelect
                name="Ins"
                options={labelValueSerializer(insurance, "InsName", "Ins")}
                // value={data.ins.filter((obj) => values.Ins === obj.value)}
                onChange={(e) => handleChange("Ins", e)}
                isClearable
                isDisabled={decriptIns}
              />
            </Col>
          </Row>
          <Collapse in={showUnits}>
            <div>
            <br/>
              <Row>
                <hr/>
                <h2>This infusion requires the following additional information:</h2>
                <Col>
                  <Form.Label className="registerForm">Units: </Form.Label>
                  <Form.Control
                    name="Units"
                    type="number"
                    value={values.units}
                    onChange={(e) => {
                      setValues((prevValues) => ({
                        ...prevValues,
                        units: e.target.value,
                      }));
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label className="registerForm">Doseage: </Form.Label>
                  <WindowedSelect
                    name="doseage"
                    options={[
                      { label: "Q4", value: 6 },
                      { label: "Q6", value: 4 },
                      { label: "Q8", value: 3 },
                      { label: "Q12", value: 2 },
                      { label: "Q24", value: 1 },
                    ]}
                    onChange={(e) => {
                      setValues((prevValues) => ({
                        ...prevValues,
                        dose: e.value,
                      }));
                    }}
                  />
                </Col>
                <Col>
                  <Form.Label className="registerForm">
                    Duration (Days):{" "}
                  </Form.Label>
                  <Form.Control
                    name="duration"
                    type="number"
                    value={values.duration}
                    onChange={(e) => {
                      setValues((prevValues) => ({
                        ...prevValues,
                        duration: e.target.value,
                      }));
                    }}
                  />
                </Col>
              </Row>
              <Button color="cls" type="submit">
                Submit
              </Button>
            </div>
          </Collapse>
        </div>

      </Form>
    </Card>
  );
};
