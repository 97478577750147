import React, { useState, useEffect } from "react";
import { Col, Row, Card, Alert, Form, Spinner } from "react-bootstrap";
import * as IoIcons from "react-icons/io";
import { PieChart, Pie, Legend, Tooltip } from "recharts";
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import { postData } from "../../Utilities/apiRequests";

export const PatientCard = ({
  patient,
  showCopay,
  loading,
  paymentStatus
}) => {
  const [discrepancies, setDiscrepancies] = useState([]);
  useEffect(() => {
    var missing = [];
    if (!patient.Age) missing.push("Age");
    if (!patient.dob) missing.push("Date of Birth");
    if (!patient.sex) missing.push("Sex");
    if (!patient.email) missing.push("Email");
    if (!patient.phone) missing.push("Phone Number");

    if (!patient.ZIP) missing.push("ZIP Code");
    if (!patient.address) missing.push("Address");
    if (!patient.city) missing.push("City");
    if (!patient.state) missing.push("State");

    setDiscrepancies(missing);
    return () => { };
  }, [patient]);


  const data = [
    {
      name: "Insurance Paid",
      value: Math.round(-1 * paymentStatus.inspaid),
      fill: "#3778cf",
    },
    {
      name: "Patient Paid",
      value: Math.round(paymentStatus.ptpaid),
      fill: "#4997ff",
    },
    {
      name: "Unpaid",
      value: Math.round(paymentStatus.total),
      fill: "#1e4476",
    },
  ];

  let renderLabel = function (entry) {
    return "$ " + String(entry.value);
  };

  let setMissingInfoAlert = function () {
    var missingInfo = "";

    discrepancies.forEach((entry, index, array) => {
      if (index < array.length - 1) missingInfo += `${entry}, `;
      else missingInfo += entry;
    });

    return missingInfo;
  };
  return (
    <Card>
      <Card.Header>
        <h2>
          <IoIcons.IoMdCheckmarkCircleOutline></IoIcons.IoMdCheckmarkCircleOutline>{" "}
          2. Confirm Patient Information
        </h2>
      </Card.Header>
      <Card.Body>
        <MDBRow>
          <MDBCol md="7">
            <MDBRow>
              <h1>
                {`${patient.ufname} ${patient.uminitial ? patient.uminitial + ". " : ""
                  } ${patient.ulname}`}
              </h1>
            </MDBRow>
            <MDBRow>
              <MDBCol className="col-md-2 col-sm-2">ID:</MDBCol>
              <MDBCol>{patient.uid}</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="col-md-2 col-sm-2">Age:</MDBCol>
              <MDBCol>
                {patient.Age} ({new Date(patient.dob).toLocaleDateString()})
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="col-md-2 col-sm-2">Sex:</MDBCol>
              <MDBCol>{patient.sex}</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="col-md-2 col-sm-2">Address:</MDBCol>
              <MDBCol>
                {patient.address}{" "}
                {patient.address2 ? ", " + patient.address2 : " "}{" "}
                {patient.city}, {patient.state} ({patient.ZIP})
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="col-md-2 col-sm-2">Email:</MDBCol>
              <MDBCol>{patient.email ? patient.email : "N/A"}</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="col-md-2 col-sm-2">Phone:</MDBCol>
              <MDBCol>{patient.phone}</MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol
            md="5"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <PieChart width={400} height={150}>
              <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={200}
                cy={120}
                outerRadius={80}
                fill="#8884d8"
                legendType="circle"
                label={renderLabel}
              />
              <Tooltip />
              <Legend verticalAlign="top" />
            </PieChart>
            <h1
              style={{
                color: "#9d2b2b",
                margin: "0rem",
                fontSize: "3rem !important",
              }}
            >
              $
              {loading ? (
                <Spinner animation="grow" variant="dark" />
              ) : (
                paymentStatus.total.toFixed(2)
              )}
            </h1>
            <span
              style={{
                fontFamily: "Dosis",
                fontWeight: "300",
                fontSize: "small",
              }}
            >
              Patient Balance
            </span>
          </MDBCol>
        </MDBRow>

        <Alert show={discrepancies.length > 0} variant="warning">
          The following required or recommended patient information is missing:{" "}
          <strong>{setMissingInfoAlert()}</strong>
          <hr />
          Update this patient's information directly in ECW.
        </Alert>
        <MDBBtn
          className="margin-button"
          color="cls-danger"
          onClick={showCopay}
        >
          Continue
        </MDBBtn>
        {/* <MDBBtn className="margin-button" color="cls" onClick={showCheckIn}>
          Check-In
        </MDBBtn> */}
      </Card.Body>
    </Card>
  );
};

export const NewPatientCard = ({ patient, customer, showCopay }) => {
  const [title, setTitle] = useState(`${patient.ufname} ${patient.ulname}`);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle(`${patient.ufname} ${patient.ulname}`);
  }, [patient]);

  useEffect(() => {
    if (error !== null) {
      setTitle(`${patient.ufname} ${patient.ulname}`);
    }
  }, [error]);

  const handleBlur = (newName, patient) => {
    if (newName.trim().split(" ").length !== 2) {
      setTitle(`${patient.ufname} ${patient.ulname}`);
      return setError("Please enter a space separated first and last name.");
    } else {
      var data = {
        newUfname: newName.split(" ")[0],
        newUlname: newName.split(" ")[1],
        patient: patient,
        customer: customer,
      };
      postData("/patients_api/edit_patient", data, setSuccess, setError, {
        setLoading: setLoading,
      });
    }
  };
  return (
    <Card>
      <Card.Header>
        <h2>
          <IoIcons.IoMdCheckmarkCircleOutline></IoIcons.IoMdCheckmarkCircleOutline>{" "}
          2. Confirm Patient Information
        </h2>
      </Card.Header>
      <Card.Body>
        <Alert variant="warning">
          This patient is newly created. Some information may not be available for up to 48 hours. Printing invoices/receipts may be limited until this time.
        </Alert>
        <Row>
          <Col md="7">
            <Row>
              <Col md="auto">
                <Form.Control
                  className="editableTitle"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onBlur={(e) => {
                    handleBlur(e.target.value, patient);
                  }}
                  onKeyPress={(e) => {
                    if (e.key == "Enter") {
                      e.target.blur();
                    }
                  }}
                />
              </Col>
              <Col
                md="1"
                style={{
                  display: "flex",
                  placeContent: "center",
                  alignItems: "center",
                }}
              >
                {loading && <Spinner animation="grow" />}
              </Col>
            </Row>
            <Row>
              <Col className="col-md-2 col-sm-2 h3">Age:</Col>
              <Col className="h3">
                {patient.Age} ({new Date(patient.dob).toLocaleDateString()})
              </Col>
            </Row>
          </Col>
        </Row>

        <MDBBtn
          className="margin-button"
          color="cls-danger"
          onClick={showCopay}
        >
          Continue
        </MDBBtn>
        {/* <MDBBtn
          className="margin-button"
          color="cls-danger"
          onClick={showCopay}
        >
          Check-In
        </MDBBtn> */}
      </Card.Body>
    </Card>
  );
};
