import React, { useState, useEffect } from "react";
import { Modal, Form, Carousel, Spinner, Row } from "react-bootstrap";
import { MDBBtn as Button } from "mdbreact";
import { checkPasswordStrength } from "../../../Utilities/LoginUtil";
import * as IoIcons from "react-icons/io";
import commonPassword from "common-password-checker";
import { ErrorAlert, SuccessAlert } from "../../../Components/Alerts";
import { postData } from "../../../Utilities/apiRequests";

export function ForgotPasswordDiv({ show, setShow }) {
  const [index, setIndex] = useState(0);
  const [msg, setMsg] = useState("");
  const [error, setError] = useState(null);

  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");

  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [success, setSuccess] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error !== "") {
      setLoading(false);
    }
  }, [error]);

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);
    const data = { email: email };
    postData(
      "/admin_api/get_user_by_email",
      data,
      setUser,
      setError,
      {
        setLoading: setLoading
      }
    );
  };

  useEffect(() => {
    if (user !== "") {
      sendVerificationCode();
      setIndex(1);
    }
  }, [user]);

  const sendVerificationCode = () => {
    setError(null);
    setSuccess(null);
    setLoading(true);
    setCodeSent(false);
    const data = { uid: user.id, email: user.email };
    postData(
      "/auth_api/send_verification",
      data,
      setMsg,
      setError,
      {
        successCallback: () => setCodeSent(true)
      }
    );
  };

  useEffect(() => {
    if (codeSent) {
      setLoading(false);
      setSuccess("Sent a verification code!");
    }
  }, [codeSent]);

  const handleCodeSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = { uid: user.id, code: code };
    postData(
      "/auth_api/check_verification_code",
      data,
      setMsg,
      setError,
      {
        setLoading: setLoading
      }
    );
  };

  useEffect(() => {
    if (msg === "Verified") {
      setIndex(2);
    }
    if (msg === "Password Changed") {
      //change when changing success message
      setSuccess("Password changed! Try logging in.");
    }
  }, [msg]);

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords don't match.");
    } else if (checkPasswordStrength(password, user) !== "") {
      setError("Please follow the password guidelines.");
    } else if (commonPassword(password)) {
      setError("Don't use common passwords like 'password'.");
    } else {
      const data = { uid: user.id, newPassword: password, curPassword: false };
      setLoading(true);
      postData(
        "/profile_api/change_password",
        data,
        setMsg,
        setError,
        {
          setLoading: setLoading
        }
      );
    }
  };

  return (
    <div>
      <Modal className="in" show={show} centered onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h2">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel
            indicators={false}
            interval={null}
            activeIndex={index}
            controls={false}
          >
            <Carousel.Item>
              <Form onSubmit={handleEmailSubmit}>
                <Form.Group>
                  <Form.Label as="h3">
                    Enter the Email associated with your account
                  </Form.Label>
                  <Form.Control
                    placeholder="your@email.com..."
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                  <Button color="cls" type="submit" disabled={loading}>
                    {loading && <Spinner animation="grow" variant="light" />}
                    {!loading ? "Next" : "Verifying..."}
                  </Button>
                </Form.Group>
              </Form>
            </Carousel.Item>

            <Carousel.Item>
              <Form onSubmit={handleCodeSubmit}>
                <Form.Group>
                  <Form.Label as="h3">
                    A verification code has been sent to your email.
                  </Form.Label>
                  <p>
                    No email?{" "}
                    <a href="#" onClick={() => sendVerificationCode()}>
                      Click here to resend.
                    </a>
                  </p>
                  <Form.Control
                    placeholder="123456..."
                    type="number"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  ></Form.Control>
                  <Button color="cls" type="submit">
                    {loading && <Spinner animation="grow" variant="light" />}
                    {!loading ? "Send" : "Checking..."}
                  </Button>
                </Form.Group>
              </Form>
            </Carousel.Item>

            <Carousel.Item>
              <Form onSubmit={handlePasswordSubmit}>
                <Form.Group>
                  <Form.Label as="h3">Create your new password.</Form.Label>
                  <Form.Control
                    placeholder="New Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></Form.Control>
                  {password !== "" &&
                    (checkPasswordStrength(password, user) !== "" ? (
                      <div className="error pwerror">
                        {checkPasswordStrength(password, user)}
                      </div>
                    ) : (
                      <IoIcons.IoMdCheckmarkCircle color="green" />
                    ))}
                  <Form.Control
                    placeholder="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  ></Form.Control>
                  {confirmPassword !== "" &&
                    (password !== confirmPassword ? (
                      <IoIcons.IoMdCloseCircle color="red" />
                    ) : (
                      <IoIcons.IoMdCheckmarkCircle color="green" />
                    ))}
                  <Row>
                    <Button color="cls" type="submit" disabled={loading}>
                      {loading && <Spinner animation="grow" variant="light" />}
                      {!loading ? "Submit" : "Submitting..."}
                    </Button>
                  </Row>
                </Form.Group>
              </Form>
            </Carousel.Item>
          </Carousel>
          <ErrorAlert error={error} setToggleAlert={setError} />
          <SuccessAlert msg={success} setToggleAlert={setSuccess} />
        </Modal.Body>
      </Modal>
    </div>
  );
}
