import { useState, useEffect } from "react";
import { Button, Card, Form, Row, Alert, Spinner } from "react-bootstrap";
import { MDBInput } from "mdbreact";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { postData } from "../../Utilities/apiRequests";
import squiggly from "./images/Squiggly.png"

const VerificationPage = () => {
  const { uid } = useParams();
  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [formDob, setFormDob] = useState("");
  const [dob, setDob] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Stream | Payment Portal"
  }, [])

  const handlesubmit = (e) => {
    e.preventDefault();
    let data = {
      uid: uid,
      ufname: firstName,
      ulname: lastName,
      dob: dob,
    }
    postData("/patients_api/confirm_patient", data, setConfirmed, setError,
      {
        setLoading: setLoading,
        history: history,
        redirect: `/patientpay/${uid}`
      })
  }

  return (
    <>
      <div style={{ top: 0, position: "absolute", width: "100%" }}>
        <img src={squiggly} style={{ width: "100%" }} />
      </div>
      <div className="mycontainer">
        <h2 className="h-align">Secure Login</h2>
        <Alert className="in" show={error} variant="danger" dismissible onClose={() => setError(false)}>
          Invalid Credentials: enter the correct name and date of birth to continue.
        </Alert>
        <Card>
          Please verify your information to view and pay your bills.
          <hr />
          <Form onSubmit={handlesubmit}>
            <Card.Body>
              <Row>
                <MDBInput autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" label="First Name" hint="Enter your first name" value={firstName} onChange={(e) => setFirstName(e.target.value.trim())} />
              </Row>
              <Row>
                <MDBInput autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" label="Last Name" hint="Enter your last name" value={lastName} onChange={(e) => setLastName(e.target.value.trim())} />
              </Row>
              <Row>
                <MDBInput label="Date of Birth" hint="MM/DD/YYYY" value={formDob} inputMode="numeric" maxLength={10}
                  onChange={(e) => {
                    let input = e.target.value;

                    if (input.length < 3) {
                      if (input.length === 1 && input[0] > 1) e.target.value = `0${input}`
                    }
                    else {
                      if (input.length === 4 && input[3] > 3) e.target.value = `${e.target.value.split("/")[0]}/0${e.target.value[3]}`
                    }

                    if ((e.target.value.length == 2 || e.target.value.length == 5) && e.nativeEvent.inputType !== "deleteContentBackward") {
                      e.target.value = `${e.target.value}/`
                    }
                    if ((e.target.value.length == 3 && e.target.value[2] !== "/") || (e.target.value.length == 6 && e.target.value[5] !== "/"))
                      e.target.value = `${input.slice(0, -1)}/${input.slice(-1)}`

                    setFormDob(e.target.value)
                    if (e.target.value.match(/\d\d\/\d\d\/\d\d\d\d/)) {
                      setDob(new Date(e.target.value).toISOString().split("T")[0])
                    }
                  }}
                />
              </Row>
            </Card.Body>
            <Button style={{ width: "100%" }} variant="primary" type="submit"> {loading || confirmed ? <Spinner animation="border" /> : "Continue"} </Button>
          </Form>
        </Card>
      </div>
    </>
  );
}

export default VerificationPage;