import images from './FacilityIcons.json'

export function FacilitySVG({ Text, Code }) {
  if (Code in images && images[Code] !== "") {
    return (
      <img src={images[Code]} width="auto" style={{ maxHeight: 115 }} />
    )
  }
  else {
    return (
      <DefaultSVG Text={Text ? Text : Code} />
    )
  }

}

function DefaultSVG({ Text }) {
  return (
    <svg width="200" height="100">
      <rect x="0" y="0" width="147" height="100" fill="rgb(60,60,60)" rx={8} />
      <foreignObject x="4%" y="20%" width="130" height="100">
        <div xmlns="http://www.w3.org/1999/xhtml" style={{ color: "whitesmoke", textAlign: "center" }}>
          {Text}
        </div>
      </foreignObject>
    </svg>
  )
}