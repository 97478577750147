import "react-tabs/style/react-tabs.css";
import React, { useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import PatientPage from "./PatientPage";
import { MerchantTransactions } from "./MerchantTransactions";

export const PatientTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="mycontainer">
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Patient Lookup</Tab>
          <Tab>Void/Return Transaction</Tab>
        </TabList>

        <TabPanel>
          <PatientPage />
        </TabPanel>
        <TabPanel>
          <MerchantTransactions />
        </TabPanel>
      </Tabs>
    </div>
  );
};
