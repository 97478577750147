import { getCookie } from "./LoginUtil";

export const postData = (url, data, setData, setError, options = null) => {
  setError(null);

  options = options || {};
  const setLoading = options.setLoading || null;
  const initialState = options.initialState || null;
  const successCallback = options.successCallback || null;
  const errorCallback = options.errorCallback || null;
  const history = options.history || null;
  const redirect = options.redirect || null;
  const timeout = options.timeout || 18000;

  const requireLoading = setLoading !== null;
  const requireResultReset = initialState !== null;

  if (requireLoading) setLoading(true);
  if (requireResultReset) setData(initialState);

  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort();
    setError("Operation timed out. Refresh the page and try again.");
    if (requireLoading) setLoading(false);
  }, timeout);

  const opts = options.opts || {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": getCookie("csrf_access_token"),
    },
    body: typeof data === "object" ? JSON.stringify(data) : data,
    signal: controller.signal,
  };

  const response = fetch(url, opts);

  response.then((response) => {
    if (response.status < 300) {
      response.json().then((data) => {
        setData(data);
        if (requireLoading) setLoading(false);
        if (successCallback) successCallback();
        if (redirect) history.push(redirect);
      });
    } else if (response.status === 401 && history !== null) {
      history.push("/not-authorized");
    } else {
      response.json().then((error) => {
        setError(error);
        if (requireLoading) setLoading(false);
        if (errorCallback) errorCallback();
      });
    }
    clearTimeout(timeoutId);
  });
};
