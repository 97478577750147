import { Alert } from "react-bootstrap";

export const ErrorAlert = ({ error, setToggleAlert }) => {
  var msg = error;
  if (typeof error === typeof {} && error !== null)
    msg = `${error.title} [${error.code}]: ${error.message}`;

  return (
    <Alert
      show={error !== null}
      variant="danger"
      dismissible
      className="in"
      onClose={() => setToggleAlert(null)}
    >
      {msg}
    </Alert>
  );
};

export const LoadingAlert = ({ isLoading, message = "Loading..." }) => {
  return (
    <Alert show={isLoading} variant="secondary" className="in">
      {message}
    </Alert>
  );
};

export const SuccessAlert = ({ msg, setToggleAlert }) => {
  return (
    <Alert
      show={msg !== null}
      variant="success"
      dismissible
      className="in"
      onClose={() => setToggleAlert(null)}
    >
      {msg}
    </Alert>
  );
};
