import React, { useState, useContext } from "react";
import { ChangeRoleForm } from "./ProfileForms";
import { UserContext } from "../../App";
import { postData } from "../../Utilities/apiRequests";
import { Alert } from "react-bootstrap";
import {
  ErrorAlert,
  SuccessAlert,
  LoadingAlert,
} from "../../Components/Alerts";

const ChangeRolePage = () => {
  const { user } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const verified = !user.role.includes("UNVERIFIED");

  const handleSubmit = (formData) => {
    if (formData.request.length === 0) {
      setError("You must select a role to request.");
    } else if (formData.reason === "") {
      setError("Your request must include a reason.");
    } else {
      const data = {
        uid: user.uid,
        name: user.first_name + " " + user.last_name,
        request: formData.request,
        reason: formData.reason,
      };
      postData("/profile_api/request_role", data, setSuccess, setError, {
        setLoading: setLoading,
      });
    }
  };
  return (
    <>
      {!verified && (
        <Alert variant="danger" className="in">
          Verify your account before making a role change request.
        </Alert>
      )}
      <ChangeRoleForm
        onFormSubmit={handleSubmit}
        verified={verified}
        loading={loading}
      />
      <ErrorAlert error={error} setToggleAlert={setError} />
      <SuccessAlert msg={success} setToggleAlert={setSuccess} />
      <LoadingAlert isLoading={loading} />
    </>
  );
};

export default ChangeRolePage;
