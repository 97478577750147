import * as Fa from "react-icons/fa";
import { useState } from "react";
import { MDBBtn } from "mdbreact";
import Popup from "./Popup";
export const PopupHandler = ({ handleTrueEdit, handleTrueDelete }) => {
  const [popupDelete, setPopupDelete] = useState(false);
  const [popupEdit, setPopupEdit] = useState(false); // initial values set to false and null

  const handleEdit = () => {
    setPopupEdit(true);
    setPopupDelete(false);
  };
  const handleDelete = () => {
    setPopupDelete(true);
    setPopupEdit(false);
  };
  return (
    <>
      <MDBBtn
        className="submitedits"
        color="cls"
        onClick={() => {
          handleEdit();
        }}
      >
        <Fa.FaUserEdit />
      </MDBBtn>
      <MDBBtn
        className="submitedits"
        color="cls-danger"
        onClick={() => {
          handleDelete();
        }}
      >
        <Fa.FaTrashAlt />
      </MDBBtn>
      {popupDelete ? (
        <Popup
          show={"delete"}
          handleTrue={() => {
            handleTrueDelete();
            setPopupDelete(false);
          }}
          handleFalse={setPopupDelete}
        />
      ) : null}
      {popupEdit ? (
        <Popup
          show={"edit"}
          handleTrue={() => {
            handleTrueEdit();
            setPopupEdit(false);
          }}
          handleFalse={setPopupEdit}
        />
      ) : null}
    </>
  );
};
