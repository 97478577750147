import { useState, useContext, useEffect } from "react";
import { CSVLink } from "react-csv";
import { SearchForm } from "./SearchForm";
import { GetPatientsColumns } from "../../../Utilities/Columns";
import { UserContext } from "../../../App";
import getData from "../../../Utilities/getData";
import { Table } from "../../../Components/Table";
import { ErrorAlert, LoadingAlert } from "../../../Components/Alerts";
import { LinearProgress, Fade } from "@mui/material";
import { postData } from "../../../Utilities/apiRequests";
import { labelValueSerializer } from "../../../Utilities/Formaters";

function SearchPage() {
  const { setUser } = useContext(UserContext);

  const [problems, setProblems] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [provider, setProvider] = useState([]);
  const [codes, setCodes] = useState([]);
  const [meds, setMeds] = useState([]);

  const [codesLoading, setCodesLoading] = useState(false);
  const [medsLoading, setMedsLoading] = useState(false);

  const [serializedCodes, setSerializedCodes] = useState([]);
  const [serializedMeds, setSerializedMeds] = useState([]);

  useEffect(() => {
    document.title = "Stream | Research"
    let isSubscribed = true;
    if (isSubscribed) {
      postData("/research_api/get_codes", {}, setCodes, setError, { setLoading: setCodesLoading })
      postData("/research_api/get_medications", {}, setMeds, setError, { setLoading: setMedsLoading })
      getData(setProvider, setError, "/research_api/get_providers");
      // getData(setCodes, setError, "/research_api/get_codes")
      // getData(setMeds, setError, "/research_api/get_medications")
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    if (codes[0] && !codes[0]['value']) {
      setSerializedCodes(labelValueSerializer(codes, "itemName", "code"))
    }
  }, [codes])

  useEffect(() => {
    if (meds[0] && !meds[0]['value']) {
      setSerializedMeds(labelValueSerializer(meds, "itemName", "itemID"))
    }
  }, [meds])

  //////////////////////////////////////
  // CALLED ON SEARCH FORM SUBMISSION //
  //////////////////////////////////////
  const handleFormSubmit = (values) => {
    const searchCriteria = {
      codesToInclude: values.codesToInclude,
      codesToExclude: values.codesToExclude,
      medsToInclude: values.medsToInclude,
      medsToExclude: values.medsToExclude,
      fromAge: values.fromAge,
      toAge: values.toAge,
      onlyFemale: values.patientSex.includes("female"),
      onlyMale: values.patientSex.includes("male"),
      providerName: values.providerName,
      dateTo: values.dateTo,
      dateFrom: values.dateFrom,
    };
    getData(setUser, setError, "/auth_api/is_logged_in");
    postData(
      "/research_api/filter-search",
      searchCriteria,
      setProblems,
      setError,
      {
        setLoading: setLoading,
        timeout: 600000
      }
    );
  };
  return (
    <div className="mycontainer">
      <div className="searchForm">
        <SearchForm onFormSubmit={handleFormSubmit}
          provider={provider}
          medlist={serializedMeds}
          codelist={serializedCodes}
          meds={serializedMeds}
          codes={serializedCodes}
          setMeds={setSerializedMeds}
          setCodes={setSerializedCodes}
          loading={medsLoading || codesLoading} />
        <ErrorAlert error={error} setToggleAlert={setError} />
        <Fade in={loading}>
          <LinearProgress variant="indeterminate" />
        </Fade>
        <br />
        <CSVLink className="download" data={problems}>
          Download Data
        </CSVLink>
        <Table
          data={problems}
          columns={GetPatientsColumns()}
          isGlobalFilter={true}
        />
      </div>
    </div>
  );
}
export default SearchPage;
