import React, { useState, useContext } from "react";
import { UserContext } from "../../App";
import { checkPasswordStrength } from "../../Utilities/LoginUtil";
import { ChangePasswordForm } from "./ProfileForms";
import {
  ErrorAlert,
  LoadingAlert,
  SuccessAlert,
} from "../../Components/Alerts";
import commonPassword from "common-password-checker";
import { postData } from "../../Utilities/apiRequests";

const ChangePasswordPage = () => {
  const { user } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const [showGuidelines, setShowGuidelines] = useState(false);

  const handleSubmit = (formData) => {
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords don't match.");
    } else if (checkPasswordStrength(formData.password, user) !== "") {
      setError("Please follow the password guidelines.");
    } else if (commonPassword(formData.password)) {
      setError("Don't use common passwords like 'password'.");
    } else if (formData.password === formData.curPassword) {
      setError("Password can't be the same as your current password.");
    } else {
      ChangePassword(formData);

      setShowGuidelines(false);
    }
  };
  const ChangePassword = (formData) => {
    const data = {
      uid: user.uid,
      email: user.email,
      curPassword: formData.curPassword,
      newPassword: formData.password,
    };
    postData("/profile_api/change_password", data, setSuccess, setError, {
      setLoading: setLoading,
    });
  };
  return (
    <>
      <ChangePasswordForm
        onFormSubmit={handleSubmit}
        setShowGuidelines={setShowGuidelines}
        user={user}
        showGuidelines={showGuidelines}
      />
      <ErrorAlert error={error} setToggleAlert={setError} />
      <SuccessAlert msg={success} setToggleAlert={setSuccess} />
      <LoadingAlert isLoading={loading} />
    </>
  );
};

export default ChangePasswordPage;
