import { useParams } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { Stepper, Step, StepLabel, LinearProgress, Tooltip } from '@mui/material/';
import { CheckCircleOutline, ArrowBackIos } from "@mui/icons-material";
import { Card, Row, Col, Button, ProgressBar, Carousel, Form, Spinner, FloatingLabel, } from "react-bootstrap";
import { postData } from "../../Utilities/apiRequests";
import { formatUpperCase } from "../../Utilities/Formaters";
import { UserContext } from "../../App";
import * as FaIcons from "react-icons/fa";
import squiggly from "./images/Squiggly.png"
import bluepill from "./images/BluePillBL.png"
import redpill from "./images/RedPillTR.png"

import states from '../../Components/states_titlecase.json';
import { ErrorAlert } from "../../Components/Alerts";
import { BriefCollapse } from "../../Components/BriefCollapse";
const PatientPaymentPage = () => {
  const { user } = useContext(UserContext);
  const { uid } = useParams();
  const [patient, setPatient] = useState({});
  const [patientFound, setPatientFound] = useState(false);
  const [payments, setPayments] = useState([]);
  const [cart, setCart] = useState([]);
  const [staxCustomer, setStaxCustomer] = useState(0);

  const [balance, setBalance] = useState(0);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const [token, setToken] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Stream | Patient Portal"
    postData("/patients_api/confirm_patient_token", uid, () => { }, setToken, { setLoading: setLoading });

    user.role = ["PATIENT"];
    let data = { "uid": uid };
    postData("/patients_api/patient_who", data, setPatient, setError, { setLoading: setLoading, successCallback: () => setPatientFound(true) });
    postData("/patients_api/get_transactions", data, setPayments, setError, { setLoading: setLoading });
  }, [])

  useEffect(() => {
    if (payments.length > 0) {
      let total = payments.reduce((total, payment) => {
        return total + payment.Remaining;
      }, 0)
      setBalance(total);
    }
  }, [payments])

  useEffect(() => {
    if (patientFound) {
      let data = {
        "uid": uid,
        "ufname": patient.ufname,
        "ulname": patient.ulname,
        "dob": patient.dob ? new Date(patient.dob).toISOString().split("T")[0] : patient.dob,
      }
      postData("/payment_api/find_customer", data, setStaxCustomer, setError, { setLoading: setLoading });
    }
  }, [patientFound])

  if (loading || patient === {}) return (
    <div className="mycontainer">
      <LinearProgress variant="indeterminate" />
    </div>
  )
  else if (token && token.code == "403") return (
    <div className="mycontainer">
      Not authorized
    </div>
  )
  else if (balance === 0)
    return (
      <div className="mycontainer">
        <Card>
          <Card.Header>
            <Card.Title style={{ textAlign: "center" }}>
              {patient.ufname} {patient.ulname}
            </Card.Title>
            <Card.Text style={{ textAlign: "center" }}>
              You have a <strong> ${balance} </strong> balance.
            </Card.Text>
          </Card.Header>
          <Card.Body>
            Congrats! You have no outstanding balance.
          </Card.Body>
        </Card>
      </div>
    )
  else
    return (
      <div>
        <div style={{ top: 0, position: "absolute", width: "100%" }}>
          <img src={squiggly} style={{ width: "100%" }} />
        </div>
        <div className="mycontainer" style={{ fontFamily: "Poppins", marginTop: 0 }}>
          <div style={{ textAlign: "center", fontSize: "24px", fontWeight: 400, paddingLeft: "5.5rem", paddingRight: "5.5rem", paddingBottom: "2rem", lineHeight: "2.68rem" }}>
            Hello <span className="poppins-text"> {patient.ufname}</span>, here are your Bills
          </div>
          <br />
          <Stepper activeStep={step} style={{ fontSize: "2rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
            <Step key={0}>
              <StepLabel>Overview</StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel>Confirm</StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>Pay</StepLabel>
            </Step>
          </Stepper>
          <Carousel
            className="payment-carousel"
            indicators={false}
            interval={null}
            activeIndex={step}
            controls={false}
            onSlid={() => window.scrollTo(0, 0)}
          >
            <Carousel.Item><Overview payments={payments} cart={cart} setCart={setCart} setStep={setStep} step={step} /></Carousel.Item>
            <Carousel.Item><Billing patient={patient} setStep={setStep} setPatient={setPatient} /></Carousel.Item>
            <Carousel.Item><Pay patient={patient} setStep={setStep} cart={cart} customer={staxCustomer} /></Carousel.Item>
            <Carousel.Item><SuccessfulPayment /></Carousel.Item>
          </Carousel>
        </div >
      </div>
    )
}
const Pay = ({ patient, setStep, cart, customer = null }) => {
  const [expMonth, setExpMonth] = useState("01");
  const [expYear, setExpYear] = useState(String(new Date().getFullYear()));
  const [error, setError] = useState(null);

  const [stax, setStax] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [items, setItems] = useState([]);

  let totalToPay = cart.reduce((partialSum, a) => partialSum + a.Remaining, 0);
  const createLineItems = (cart) => {
    var itemsArr = [];
    for (let item in cart) {
      itemsArr.push({
        id: cart[item].CPTCode,
        item: `${cart[item].FacilityName}: ${cart[item].Description}`,
        details: `${cart[item].ProviderName} ${cart[item]["Diagnosis 1 Description"] ? ": " + cart[item]["Diagnosis 1 Description"] : ""}`,
        quantity: 1,
        price: cart[item].Remaining
      })
    }
    setItems(itemsArr);
  }

  useEffect(() => {
    createLineItems(cart)
  }, [cart])

  useEffect(() => {
    const StaxJs = window.StaxJs;
    const s = new StaxJs("CLS-Health-f25559925b0d", {
      price: String(totalToPay),
      number: {
        id: 'card-number',     // the html id of the div you want to contain the credit card number field
        placeholder: '0000 0000 0000 0000',    // the placeholder the field should contain
        style: 'height: 1.58rem; width: 100%; font-size: 15px;',    // the style to apply to the field
        type: 'text',    // the input type (optional)
        format: 'prettyFormat'    // the formatting of the CC number (prettyFormat || plainFormat || maskedFormat)
      },
      cvv: {
        id: 'card-cvv',    // the html id of the div you want to contain the cvv field
        placeholder: 'CVV',    // the placeholder the field should contain
        style: 'height: 1.58rem; width: 100%; font-size: 15px;',    // the style to apply to the field
        type: 'text'    // the input type (optional)
      }
    })

    setStax(s);
  }, [])

  useEffect(() => {
    if (stax !== null) {
      stax
        .showCardForm()
        .then((handler) => {
          console.log("form was loaded");
          // for quick testing, you can set a test number and test cvv here
          // handler.setTestPan("4111111111111111");
          // handler.setTestCvv("123");
        })
        .catch((err) => {
          console.log("there was an error loading the form: ", err);
        });
      stax.on("card_form_complete", message => {
        console.log("card_form_complete", message);
        // activate pay button
        setDisabled(false);
      });

      stax.on("card_form_incomplete", message => {
        console.log("card_form_incomplete", message);
        // deactivate pay button
        setDisabled(true);
      });
    }
  }, [stax])

  const extraDetails = {
    total: totalToPay, //TODO: totalToPay,
    firstname: patient.ufname,
    lastname: patient.ulname,
    company: patient.dob ? new Date(patient.dob).toISOString().split("T")[0] : "",
    month: expMonth,
    year: expYear,
    phone: patient.phone,
    address_1: patient.address,
    address_2: patient.address2,
    address_city: patient.city,
    address_state: patient.state,
    address_zip: patient.ZIP,
    address_country: "USA",
    send_receipt: false,
    validate: false,
    method: "card",
    meta: {
      lineItems: items
    }
  }
  if (customer) extraDetails['customer_id'] = customer

  return (
    <div>
      <Card>
        <Card.Body>
          <Form.Label>Summary</Form.Label>
          <BriefCollapse lines={0} defaultShow={true}>
            {cart.map((item, idx) => {
              return (
                <div key={idx}>
                  ${item.Remaining.toFixed(2)}: {item.Description}
                </div>
              )
            })}
          </BriefCollapse>
        </Card.Body>
        <Card.Footer>
          <Form.Label>Cart Total: ${(totalToPay).toFixed(2)}</Form.Label>
        </Card.Footer>
      </Card>
      <hr />
      <Card className="RowPaddedCard">
        <Row>
          <Row>
            <Col sm="1"><Form.Label>Contact Information</Form.Label></Col>
            <Col sm="4" style={{ textAlign: "right" }}><a className="changeText" onClick={() => setStep(1)}>Change</a></Col>
          </Row>
          <Row>
            <Col>
              {patient.ufname} {patient.ulname} <br />
              {patient.phone}
            </Col>
          </Row>

        </Row>
        <hr />
        <Row>
          <Row>
            <Col sm="1"><Form.Label>Billing Information</Form.Label></Col>
            <Col sm="4" style={{ textAlign: "right" }}><a className="changeText" onClick={() => setStep(1)}>Change</a></Col>
          </Row>
          <Row>
            <Col>
              {formatUpperCase(patient.address)}{patient.address2 ? `, ${patient.address2}` : null} <br />
              {formatUpperCase(patient.city)} {patient.state} {patient.ZIP}, United States
            </Col>
          </Row>
        </Row>
      </Card>
      <Card>
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <div id="pay-with-apple"></div>
        <div id="pay-with-google"></div>
        <div class="strike">
          <span>or</span>
        </div> */}
          <Form.Label>Enter Card Information </Form.Label>
          <div style={{ display: "flex", flexDirection: "column", paddingBottom: "1.5rem" }}>
            <div id="card-number" style={{ width: "100%", height: "4rem", fontSize: "3.8rem", border: "1px lightgray solid", background: "white", borderRadius: 6, marginRight: "1rem" }}></div>
          </div>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col>
              <Form.Label>Expiration</Form.Label>
              <Form.Control
                style={{ marginBlock: "unset", height: "4rem" }}
                placeholder="MM/YY"
                maxLength={5}
                inputMode="numeric"
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^/\d]/gm, "")
                  if (e.target.value[0] > 1 && e.target.value.length == 1) {
                    e.target.value = `0${e.target.value}/`
                  }
                  else if (e.target.value.length == 2 && e.nativeEvent.inputType !== "deleteContentBackward") {
                    e.target.value = `${e.target.value}/`
                  }
                  setExpMonth(e.target.value.split("/")[0])
                  setExpYear(`20${e.target.value.split("/")[1]}`)
                }}
              />
            </Col>
            <Col>
              <Form.Label>
                Security Code
                <Tooltip enterTouchDelay={0} leaveTouchDelay={5000} placement="top"
                  title={<p className="my-tooltip">3 digit code usually found on the back of your card.</p>}>
                  <span>
                    <FaIcons.FaQuestionCircle style={{ color: "#4285f4", marginLeft: "0.3rem" }} />
                  </span>
                </Tooltip>
              </Form.Label>
              <div id="card-cvv" style={{ height: "4rem", fontSize: "3.8rem", border: "1px lightgray solid", background: "white", borderRadius: "0.75rem" }}></div>
            </Col>
          </Row>

          <ErrorAlert error={error} setToggleAlert={setError} />
          <hr />
          <Button style={{ float: "left" }} variant="secondary" onClick={() => setStep(1)}><ArrowBackIos /> Back </Button>
          {/* Make the payment in stax. Post the cart into sql using processpayment api route */}
          <Button style={{ float: "right", display: "flex", alignItems: "center" }} disabled={loading || disabled} id="paybutton" onClick={() => {
            console.log("submitting...")
            setLoading(true);

            stax
              .pay(extraDetails)
              .then((response) => {
                setError(null)
                console.log("invoice object:", response);
                console.log("transaction object:", response.child_transactions[0]);
                for (let item in cart) {
                  cart[item]['PatientPaid'] = cart[item]['Remaining']
                }
                // on success call api to process payment and then continue
                let data = {
                  cart: cart,
                  patient: patient,
                  validation_code: `${response.child_transactions[0].payment_method.card_type}: ${response.child_transactions[0].last_four}`,
                  transaction_id: response.child_transactions[0].id,
                  invoice_id: response.child_transactions[0].invoice_id,
                  merchantId: patient.uid,
                  paymentType: "portal", //originally "credit", changed to easily distinguish patient portal payments
                  total_collected: response.total_paid,
                  total: cart.reduce((prev, cur) => prev + cur.Remaining, 0)
                }
                let receipt_data = {
                  invoice_id: data.invoice_id,
                  customer: customer,
                  patient: patient,
                  receipt: {
                    isPhone: true,
                    isEmail: false,
                    phone: patient['phone']
                  }
                }
                postData("/payment_api/add_payment_to_db", data, () => { }, () => { })
                postData("/payment_api/resend_receipt", receipt_data, () => { }, () => { })
                setLoading(false);
                setStep(3)
              })
              .catch((err) => {
                console.log("unsuccessful payment:", err);
                setError(`Could not process payment: ${err.message ?
                  err.message : err.payment_attempt_message ?
                    err.payment_attempt_message : "Make sure your information is correct"}`)
                setLoading(false);
              });
          }}>
            {loading ? <><Spinner animation="border" /> </> : <><FaIcons.FaLock style={{ marginRight: "1rem" }} /> Pay </>}
          </Button>
        </form>
      </Card>
      <div style={{ textAlign: "center" }}>
        <FaIcons.FaLock style={{ marginRight: "1rem", color: "green" }} /> Secure Online Payments Powered by StaxPay
      </div>
    </div >
  )
}
const Overview = ({ setStep, payments, setCart, cart, step }) => {
  const [balance, setBalance] = useState(0);
  const [adjustment, setAdjustment] = useState(0);
  const [ins, setIns] = useState(0);
  const [patientPaid, setPatientPaid] = useState(0);
  const [unadjusted, setUnadjusted] = useState(0);
  const [selected, setSelected] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [showFloatingPay, setShowFloatingPay] = useState(false);
  const [wiggly, setWiggly] = useState(false);
  const listRef = useRef(null);

  useEffect(() => {
    var target = document.getElementById("bills");
    var targetHeight = target.offsetTop + target.offsetHeight
    const onScroll = e => {
      setShowFloatingPay(window.scrollY > targetHeight);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    setSelected(Array(payments.length).fill(false))

    if (payments.length > 0) {
      let unadjusted = payments.reduce((total, payment) => {
        return total + payment.Billed;
      }, 0)
      setUnadjusted(unadjusted);

      let total = payments.reduce((total, payment) => {
        return total + payment.Remaining;
      }, 0)
      setBalance(total);

      let adjusted = payments.reduce((total, payment) => {
        return total + payment.Adjustment;
      }, 0)
      setAdjustment(adjusted * -1);

      let ins = payments.reduce((total, payment) => {
        return total + payment.Ins1Paid + payment.Ins2Paid + payment.Ins3Paid;
      }, 0)
      setIns(ins * -1);

      let paid = payments.reduce((total, payment) => {
        return total + payment.PatientPaid;
      }, 0)
      setPatientPaid(paid * -1);
    }
  }, [payments])
  return (
    <>
      <div
        style={{
          position: "fixed",
          zIndex: 999,
          bottom: showFloatingPay || cart.length > 0 ? "2%" : "-6rem",
          display: step != 1 ? "flex" : "none",
          width: "89%",
          justifyContent: "center",
          transitionDuration: "0.2s"
        }}>
        <Button
          variant="secondary"
          size="lg"
          onClick={() => {
            if (cart.length === 0) {
              setWiggly(true);
              window.scrollTo(0, listRef.current.offsetTop)
            }
            else
              setStep(1)
          }}>
          Pay For Selected {cart.length > 0 ? `$${cart.reduce((partialSum, a) => partialSum + a.Remaining, 0).toFixed(2)}` : ""}
        </Button>
      </div>
      <Card className="RowPaddedCard">
        <Row style={{ textAlign: "center" }}>
          <div className="boldTitle">Amount Due: ${balance.toFixed(2)}</div>
          <div className="subtitle">For {payments.length} open bills</div>
        </Row>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Row style={{ width: "100%", fontSize: "large" }}>
            <Button
              onClick={() => {
                setSelected(Array(payments.length).fill(true))
                setCart(payments);
                setStep(1)
              }}>
              Pay Full <strong>${balance.toFixed(2)}</strong>
            </Button>
          </Row>
          <Row style={{ width: "fit-content" }}>
            {/* Scroll down to select all the transactions they wish to pay, then continue */}
            <Button variant="secondary"
              onClick={() => {
                if (cart.length === 0 || cart.length === payments.length)
                  window.scrollTo(0, listRef.current.offsetTop + 120)
                else
                  setStep(1)
              }}>
              {cart.length > 0 && cart.length !== payments.length ? `Pay only $${cart.reduce((partialSum, a) => partialSum + a.Remaining, 0).toFixed(2)}` : "Pay partial amount"}

            </Button>
          </Row>
        </Row>
      </Card>

      <Card className="RowPaddedCard" id="bills" style={{ zIndex: 0, paddingBottom: "5rem" }}>
        <div style={{ zIndex: -1, bottom: 0, left: 0, position: "absolute" }}>
          <img src={bluepill} style={{ height: "3rem", width: "8rem", borderRadius: "0 0.75rem 0 0" }} />
        </div>
        <div style={{ zIndex: -1, top: 0, right: 0, position: "absolute" }}>
          <img src={redpill} style={{ height: "3rem", width: "8rem", borderRadius: "0 0.75rem 0 0" }} />
        </div>
        <p className="billingInfoTitle">Bill Summary</p>
        <br />
        <ProgressBar style={{ height: "1.5rem", fontSize: "1.5rem" }}>
          <ProgressBar now={(ins / (unadjusted - adjustment)) * 100} style={(patientPaid > 0 || balance > 0) && ins > 0 ? { borderRight: "3px solid aliceblue" } : {}} variant="secondary" />
          <ProgressBar now={(patientPaid / (unadjusted - adjustment)) * 100} style={balance > 0 && patientPaid > 0 ? { borderRight: "3px solid aliceblue" } : {}} variant="primary" />
          <ProgressBar now={((unadjusted - adjustment - patientPaid - ins) / (unadjusted - adjustment)) * 100} variant="success" />
        </ProgressBar>
        <br />
        <Row>
          <Col style={{ paddingLeft: "2rem" }}>
            <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
              <div className="circle bg-secondary rounded-circle"> </div>
              <Col sm="1"> Insurance Paid </Col>
              <Col sm="2"> ${ins.toFixed(2)} </Col>
            </Row>

            <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
              <Col className="circle bg-primary rounded-circle"> </Col>
              <Col sm="1"> You previously Paid </Col>
              <Col sm="2"> ${patientPaid.toFixed(2)} </Col>
            </Row>

            <Row style={{ alignItems: "center", marginBottom: "1rem" }}>
              <div className="circle bg-success rounded-circle"> </div>
              <Col sm="1"> <span>Amount Due</span> </Col>
              <Col sm="2"> <span>${balance.toFixed(2)}</span> </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      {/* insert all their payments here, allowing them to select which ones to pay for if "pay partial" is selected. */}
      <div>
        <p className="billingInfoTitle">Statement Details</p>
        <h3>Tap an item below to add it to your cart.</h3>
        <Card ref={listRef} className={wiggly ? "wiggly" : ""} onAnimationEnd={() => setWiggly(false)}>
          {payments.map((payment, i) => {
            return (
              <div key={i}>
                <Card.Body key={i}
                  style={{
                    padding: selected[i] ? "1rem" : "0",
                    backgroundColor: selected[i] ? "#4285f4" : "initial",
                    transitionDuration: "0.15s",
                    borderRadius: "0.6rem"
                  }}
                  onClick={() => {
                    let temp = selected;
                    temp[i] = !temp[i];
                    setSelected(temp);
                    setRefresh(refresh + 1);
                    if (temp[i])
                      setCart(cart => [...cart, payments[i]])
                    else
                      setCart(cart => {
                        return cart.filter(item => item.EntryNumber !== payments[i].EntryNumber)
                      })
                  }}>

                  <h4> {new Date(payment.DateOfService).toLocaleDateString()} </h4>
                  <Row style={{ alignItems: "center" }}>
                    <Col sm="1">
                      <h4> Dr. {formatUpperCase(`${payment.ProviderFirstName} ${payment.ProviderLastName}`)} </h4>
                    </Col>
                    <Col sm="2">
                      <h4 className="priceText"> ${payment.Remaining.toFixed(2)} </h4>
                    </Col>
                    <Col>
                      {selected[i] ? <FaIcons.FaCartPlus />
                        : <FaIcons.FaPlus />}
                    </Col>
                  </Row>
                  <h6 style={{ color: selected[i] ? "black" : "gray" }}>
                    {formatUpperCase(payment.Description)} <br />
                  </h6>
                </Card.Body>
                {i < payments.length - 1 ? <hr /> : null}
              </div>
            )
          })}
        </Card>
      </div>
    </>
  )
}
const Billing = ({ patient, setStep, setPatient }) => {
  return (
    <Form onChange={(e) => {
      let value = e.target.value;
      if (e.target.id === "state")
        value = states.filter(state => state.label === e.target.value)[0].value;
      setPatient((prev) => ({ ...prev, [e.target.id]: value }))
    }}>
      <Form.Group>
        <h3 className="h-align">Confirm your billing information</h3>
        <Card>
          <Card.Title>Contact</Card.Title>
          <FloatingLabel label="First Name">
            <Form.Control id="ufname" type="text" placeholder={patient.ufname} defaultValue={patient.ufname} />
          </FloatingLabel>

          <FloatingLabel label="Last Name">
            <Form.Control id="ulname" type="text" placeholder={patient.ulname} defaultValue={patient.ulname} />
          </FloatingLabel>

          <FloatingLabel label="Phone #">
            <Form.Control id="phone" type="text" placeholder={patient.phone} defaultValue={patient.phone} />
          </FloatingLabel>
        </Card>
        <Card>
          <Card.Title>Address</Card.Title>

          <FloatingLabel label="Address">
            <Form.Control id="address" type="text" placeholder={"Address"} defaultValue={patient.address} />
          </FloatingLabel>

          <FloatingLabel label="Address 2 (optional)">
            <Form.Control id="address2" type="text" placeholder={"Address 2 (Optional)"} defaultValue={patient.address2} />
          </FloatingLabel>

          <FloatingLabel label="Zip">
            <Form.Control id="ZIP" type="text" placeholder={"Zip code"} defaultValue={patient.ZIP} />
          </FloatingLabel>

          <FloatingLabel label="City">
            <Form.Control id="city" type="text" placeholder={"City"} defaultValue={patient.city} />
          </FloatingLabel>

          <FloatingLabel label="State">
            <Form.Select
              id="state"
              defaultValue={"Texas"} //make generic later idc
              style={{ width: "25%", fontSize: "1.4rem", marginLeft: 0, marginTop: "5px" }}
            >
              {states.map((state) => {
                return <option key={state.value}>{state.label}</option>
              })}
            </Form.Select>
          </FloatingLabel>
        </Card>
        <hr />
        <Button style={{ float: "left" }} variant="secondary" onClick={() => setStep(0)}><ArrowBackIos /> Back </Button>
        <Button style={{ float: "right" }} onClick={() => setStep(2)}>Continue</Button>
      </Form.Group>
    </Form >
  )
}
const SuccessfulPayment = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h2>Payment Successful!</h2>
      <CheckCircleOutline style={{ color: "green", fontSize: "10rem" }} />
      <p>You can safely close this page.</p>
    </div>
  )
}
export default PatientPaymentPage;