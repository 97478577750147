import Card from "react-bootstrap/Card";

export const PatientCard = ({ patientData }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          {patientData.FirstName + " " + patientData.LastName}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Subtitle className="mb-2 text-muted">
          Date of Birth: {patientData.DateOfBirth}
        </Card.Subtitle>
        <Card.Subtitle className="mb-2 text-muted">
          Age: {patientData.Age}
        </Card.Subtitle>
        <Card.Subtitle className="mb-2 text-muted">
          Gender: {patientData.Gender}
        </Card.Subtitle>
      </Card.Body>
    </Card>
  );
};
