import { Card } from "react-bootstrap"
import { useHistory } from "react-router"

export const RulesErrors = () => {
  const history = useHistory();
  return (
    <div className="mycontainer">
      <Card>
        <a href="#" onClick={() => history.push("/BillingRules")}>Back</a>
        <Card.Body>
          <h2>Billing Errors</h2>
          Here you will find an itemized list of all found errors. Click an error for more details.<br />
          [Under construction.]
        </Card.Body>
      </Card>
    </div>
  )
}