import { Alert } from "react-bootstrap";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../App";
import VerificationPopup from "./VerificationPopup";
import { postData } from "../Utilities/apiRequests"
import * as IoIcons from "react-icons/io";

export const UnverifiedAlert = ({ }) => {
  const { user } = useContext(UserContext);
  const [showVerification, setVerification] = useState(false);
  const [showExternal, setShowExternal] = useState(user.role.includes("EXTERNAL"));
  const [expData, setExpData] = useState({});

  const verified = !(user.role.includes("UNVERIFIED"));
  const external = user.role.includes("EXTERNAL");

  useEffect(() => {
    // check if password is close to expiring
    if (user.uid) {
      postData(`/auth_api/check_password_expired`, user.uid, setExpData, () => { },)
    }
  }, [user])

  return (
    <div>
      <Alert show={!verified} variant="danger" className="in">
        Please verify your account.
        <a
          href="#"
          onClick={() => {
            setVerification(true);
          }}
        >
          Click here
        </a>
      </Alert>
      <Alert show={showExternal} variant="warning" className="in" dismissible onClose={() => setShowExternal(false)}>
        Your role is set to External. If you need access to a role locked application (Infusion, Research...), please review and select the role that best suits you.
        <hr />
        <b> <IoIcons.IoMdSettings /> Settings &gt; <IoIcons.IoIosRibbon />Request Role Change</b>
      </Alert>
      <VerificationPopup show={showVerification} setShow={setVerification} />
      <Alert show={expData && "days_until_expired" in expData && expData["days_until_expired"] < 10} variant="warning" className="in">
        Your password will expire in <strong>{"days_until_expired" in expData ? expData["days_until_expired"] : "null"} days</strong>. To avoid interruptions change your password in the settings tab above.
      </Alert>
    </div>
  );
}
