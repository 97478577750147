import React, { useEffect, useState } from "react";
import { Table } from "../../../Components/Table";
import getData from "../../../Utilities/getData";
import { GetIamColumns } from "../../../Utilities/Columns";
import { CurdPermissions } from "./CurdPermissions";
import { MDBCard, MDBCardBody } from "mdbreact";

export const MainBillingAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [billers, setBillers] = useState([]);
  const [providers, setProviders] = useState([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    document.title = "Stream | IAM"
    getData(setBillers, setError, "/admin_api/get_list_of_billers");
    getData(
      (x) => {
        x.unshift({
          "First Name": "*",
          "Last Name": "",
          doctorId: "*",
        });
        setProviders(x);
      },
      setError,
      "/patients_api/get_providers/0"
    );
  }, []);

  return (
    <div className="mycontainer">
      <h2>Identity Authentication Management</h2>
      <h3>Restrict User Access</h3>
      <MDBCard>
        <MDBCardBody>
          <Table
            columns={GetIamColumns()}
            data={billers}
            loading={loading}
            isExpand={true}
            singleExpand={true}
            renderExpandedRow={(row) => (
              <div>
                <CurdPermissions
                  row={row}
                  providers={providers}
                  refresh={() => setRefresh(Math.random() * 1000)}
                />
              </div>
            )}
          />
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};
