import React, { useEffect, useState, useContext } from "react"
import { Card, Collapse, Form, Row, Col, Spinner, Button } from "react-bootstrap"
import { Tooltip } from "@mui/material";
import { postData } from "../../Utilities/apiRequests"
import { ReportCards } from "./ReportCardsPage";
import * as FaIcons from "react-icons/fa";
import { UserContext } from "../../App";


export const ReportsPage = () => {
  const { user } = useContext(UserContext);

  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [emailOpts, setEmailOpts] = useState([]);
  const [filter, setFilter] = useState("");

  const [show, setShow] = useState({});
  const [isRunning, setIsRunning] = useState({});

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    postData("/billing_api/reports", {}, setReports, setError, { setLoading: setLoading });
    postData("/billing_api/get_subscriber_options", {}, setEmailOpts, setError);
  }, [])

  useEffect(() => {
    if (reports.length > 0) setFilteredReports(reports);
  }, [reports])

  useEffect(() => {
    let temp = reports.filter(report => (report.name.toLowerCase().includes(filter)) || report.cards.some((e) => e.name.toLowerCase().includes(filter)));
    setFilteredReports(temp);
  }, [filter])

  const toggleShowCard = (reportId) => {
    setShow(show[reportId] ?
      {
        [reportId]: !show[reportId]
      } :
      {
        [reportId]: true
      })
  }

  return (
    <div className="mycontainer">
      <Card>
        <Card.Header as="h2"> Scorecards and Reports </Card.Header>
        <Card.Body>
          <Form.Control
            placeholder="Filter Scorecards by Scorecard Name"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <Card style={{ backgroundColor: "white", boxShadow: "none" }}>
            {reports.length > 0 && reports.map((report, idx) => {
              let ref = React.createRef();
              return (
                <div key={idx} ref={ref} style={{ display: filteredReports.some(r => r.id === report.id) ? "initial" : "none" }}>
                  <div>
                    <Row>
                      {user.role && user.role.includes("ADMIN") &&
                        <Col md="auto" style={{ display: "flex", alignItems: "center", color: "#31447c" }}>
                          <RunButton id={report.id} setError={setError} state={isRunning} setState={setIsRunning} />
                        </Col>
                      }
                      <Col>
                        <div onClick={() => toggleShowCard(report.id)} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", cursor: "pointer" }}>
                          <h3> {report.name} </h3>
                          <div className="hover-bg">
                            {show[report.id] ? <FaIcons.FaChevronUp /> : <FaIcons.FaChevronDown />}
                          </div>
                        </div>
                        <div>
                          {report.cards.length} associated scorecard{report.cards.length !== 1 && "s"}
                        </div>
                        <div className="subtitle">
                          {report.description}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Collapse in={show[report.id]} onEntered={() => window.scrollTo(0, ref.current.offsetTop)}>
                    <div>
                      <ReportCards id={report.id} emailOpts={emailOpts} report={report} isRunning={isRunning} setIsRunning={setIsRunning} />
                    </div>
                  </Collapse>
                  {idx < reports.length - 1 && <hr />}
                </div>
              )
            })}
          </Card>
        </Card.Body>
      </Card>
    </div>
  )
}

const RunButton = ({ id, setError, state, setState }) => {
  const [status, setStatus] = useState({ [id]: "LOADING" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id)
      postData(`/billing_api/reports/${id}/status`, {}, setStatus, setError, { setLoading: setLoading })
  }, [id])

  useEffect(() => {
    if (status[id] == "OPEN") setState((prev) => ({ ...prev, [id]: false }))
    const interval = setInterval(() => {
      if (status[id] !== "OPEN" && !loading) {
        setState((prev) => ({ ...prev, [id]: true }))
        postData(`/billing_api/reports/${id}/status`, {}, setStatus, setError, { setLoading: setLoading })
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [status[id]])

  useEffect(() => {
    if (state && state[id]) {
      setStatus({ [id]: "RUNNING" })
    }
  }, [state])

  const runScorecard = () => {
    setStatus({ [id]: "RUNNING" })
    postData(`/billing_api/reports/${id}/run`, {}, setStatus, setError)
  }

  return (
    <Tooltip title={<p className="my-tooltip">{status[id]}</p>}>
      <div>
        <Button
          onClick={() => runScorecard()}
          disabled={status[id] !== "OPEN" || loading}
          style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "1rem" }}>
          {status && status[id] === "OPEN" ?
            <FaIcons.FaPlay style={{ fontSize: "2rem" }} />
            :
            <Spinner animation="border" variant="light" />
          }
        </Button>
      </div>
    </Tooltip>
  )
}