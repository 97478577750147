import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MySpinner from "../../../Components/MySpinner";
import { ShowMore } from "./ShowMore";
import { postData } from "../../../Utilities/apiRequests";
import { ErrorAlert, LoadingAlert } from "../../../Components/Alerts";

function ShowMorePage() {
  const [loading, setLoading] = useState(true);
  // Stores error messages
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    patient_data: {
      FirstName: null,
      LastName: null,
      Age: null,
      Gender: null,
      DateOfBirth: null,
    },
    encounter_data: [],
    diagnosis_data: [],
    medication_data: []
  });

  // Takes the /"___" used to render this page and assigns that value to id
  const { uid } = useParams();

  useEffect(() => {
    // Clean up controller
    let isSubscribed = true;

    // Prevents Memory leaks if closed before function finishes
    if (isSubscribed) {
      postData(`/research_api/${uid}`, { blank: null }, setData, setError, {
        setLoading: setLoading,
      });
    }
    // cancel subscription to useEffect
    return () => (isSubscribed = false);

    // [id] prevents useEffect calls unless the id changes
  }, [uid]);

  // Conditionally renders the Search Page if logged in
  // ELSE renders a Please Login message and Redirects to Login Page
  if (loading) return <MySpinner />;
  else {
    return (
      <div className="mycontainer">
        <ErrorAlert error={error} setToggleAlert={setError} />
        <LoadingAlert isLoading={loading} />
        <ShowMore
          patientData={data.patient_data}
          encounterData={data.encounter_data}
          diagnosisData={data.diagnosis_data}
          medicationData={data.medication_data}
          error={error}
        />
      </div>
    );
  }
}

export default ShowMorePage;
