import React, { useEffect, useState } from "react";
import { rowUidHolder } from "../Utilities/TableUtilities";
import { matchSorter } from "match-sorter";
import {
  useTable,
  usePagination,
  useRowSelect,
  useAsyncDebounce,
  useGlobalFilter,
  useFilters,
  useExpanded,
  useSortBy,
} from "react-table";
import { MDBBtn as Button } from "mdbreact";
import { Col, Form, FormControl, Row, Collapse } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import { CSVLink } from "react-csv";
import { formatDate } from "../Utilities/Formaters";

// Be sure to pass our updateMyData and the skipPageReset option
export function Table({
  columns,
  data,
  skipPageReset,
  isEditable = false,
  isGlobalFilter = false,
  loading,
  isColumnFilter = false,
  isPrintable = false,
  isExpand = false,
  renderExpandedRow = null,
  singleExpand = false,
  showTotal = false,
}) {
  const all = Object.keys(data).length;
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const filterTypes = React.useMemo(
    () => ({
      dateBetween: dateBetweenFilterFn,
      todayDate: todayDateFilterFn,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    toggleAllRowsExpanded,
    setSortBy,
    setAllFilters,
    rows,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter, expanded },
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      autoResetFilters: false,
      autoResetSortBy: false,
      disableFilters: !isColumnFilter,
      DefaultColumnFilter,
      filterTypes,
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect, // useGlobalFilter!
    (hooks) => {
      if (isEditable) {
        hooks.visibleColumns.push((columns) => [
          ...columns,
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            // Header: () => <div>Edit</div>,
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
        ]);
      }
      if (isExpand) {
        hooks.visibleColumns.push((columns) => [
          {
            Header: () => null, // No header
            id: "expander",
            Cell: ({ row }) => (
              // Use Cell to render an expander for each row.
              // We can use the getToggleRowExpandedProps prop-getter
              // to build the expander.
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (singleExpand) toggleAllRowsExpanded(false);
                  row.toggleRowExpanded(!row.isExpanded);
                }}
              >
                {row.isExpanded ? (
                  <FaIcons.FaCaretDown fontSize={20} />
                ) : (
                  <FaIcons.FaCaretRight fontSize={20} />
                )}
              </span>
            ),
          },
          ...columns,
        ]);
      }
    }
  );
  rows.forEach((row) => (row.collapse = false));
  const [csv, setCsv] = useState([]);
  const [copied, setCopied] = useState(false);
  const [rowStates, setRowStates] = useState(() => {
    let states = [];
    rows.forEach(() => {
      states.push(false);
    });
    return states;
  });

  // Render the UI for your table
  return (
    <>
      {isPrintable && !loading ? (
        <CSVLink
          onClick={() => {
            let CSVData = [];
            rows.forEach((entry) => {
              let rowData = {};
              for (var key in entry.values) {
                if (
                  typeof entry.values[key] === "string" ||
                  entry.values[key] instanceof Date
                ) {
                  if (entry.values[key] instanceof Date)
                    rowData[key] = formatDate(
                      entry.values[key].toLocaleDateString("en-US", {
                        timeZone: "UTC",
                      })
                    );
                  else rowData[key] = entry.values[key];
                }
              }
              CSVData.push(rowData);
            });
            setCsv(CSVData);
          }}
          data={csv}
        >
          {" "}
          Download CSV{" "}
        </CSVLink>
      ) : null}
      {showTotal && !loading ? (
        <div style={{ fontSize: 18 }}>
          <strong>Total Collection (Based on Filters):</strong>
          {" $"}
          {rows
            .reduce(
              (previousValue, currentValue) =>
                previousValue + (!currentValue.original.Is_Voided * parseFloat(currentValue.original[showTotal])),
              0
            )
            .toFixed(2)}
        </div>
      ) : null}
      {headerGroups.map((headerGroup) => (
        <Row {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.some((column) => column.filter) ?
            <Col
              md="auto"
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
              }}
            >
              <FaIcons.FaFilter
                onClick={() => {
                  setSortBy([]);
                  setAllFilters([]);
                }}
              />
            </Col>
            : null}

          {headerGroup.headers.map((column, idx) =>
            column.filter ? (
              <Col key={idx}>
                <Form.Label
                  {...column.getHeaderProps()}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {column.hideHeader ? <br /> : column.render("Header")}
                </Form.Label>
                {column.filter === "dateBetween"
                  ? column.render(DateRangeFilter)
                  : column.filter === "todayDate"
                    ? column.render(TodayDateFilter)
                    : column.render(DefaultColumnFilter)}
              </Col>
            ) : null
          )}
          <p />
          {headerGroup.headers.some((column) => column.filter) ? <hr /> : null}
        </Row>
      ))}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FaIcons.FaSortAlphaUp />
                    ) : (
                      <FaIcons.FaSortAlphaDown />
                    )
                  ) : (
                    ""
                  )}
                </th>
              ))}
            </tr>
          ))}
          {isGlobalFilter && (
            <GlobalFilterHeader
              visibleColumns={visibleColumns}
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            let open = false;
            if (expanded[row.id] && !open) rowStates[i] = true;
            return (
              <React.Fragment key={rowProps.key}>
                <tr {...rowProps}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
                {rowStates[i] ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      <Collapse
                        in={row.isExpanded}
                        appear={true}
                        onExited={() => {
                          let newArr = [...rowStates];
                          newArr[i] = false;
                          setRowStates(newArr);
                          open = false;
                          setCopied(false);
                        }}
                        onEnter={() => setCopied(false)}
                        onEntered={() => {
                          open = true;
                        }}
                      >
                        {renderExpandedRow ? (
                          renderExpandedRow(row)
                        ) : (
                          <pre
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            <a
                              style={{ float: "right" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  JSON.stringify(row.original, null, 2)
                                );
                                setCopied(true);
                              }}
                            >
                              {!copied ? (
                                <FaIcons.FaRegClone fontSize={"2.7rem"} />
                              ) : (
                                <>
                                  {" "}
                                  Copied to clipboard <FaIcons.FaCheck />{" "}
                                </>
                              )}
                            </a>
                            <code>{JSON.stringify(row.original, null, 2)}</code>
                          </pre>
                        )}
                      </Collapse>
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
          {isEditable &&
            rowUidHolder(selectedFlatRows.map((d) => d.original.id))}
          <tr>
            <LoadingHeader page={page} all={all} loading={loading} />
          </tr>
        </tbody>
      </table>
      <PaginationButtons
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        setPageSize={setPageSize}
      />
    </>
  );
}

const PaginationButtons = ({
  gotoPage,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageIndex,
  pageSize,
  pageCount,
  setPageSize,
}) => {
  return (
    <div className="pagination">
      <Button
        color="cls"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        {"<<"}
      </Button>{" "}
      <Button
        color="cls"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        {"<"}
      </Button>{" "}
      <Button color="cls" onClick={() => nextPage()} disabled={!canNextPage}>
        {">"}
      </Button>{" "}
      <Button
        color="cls"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        {">>"}
      </Button>
      <span>
        Page{" "}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>{" "}
      </span>
      <span>
        | Go to page:{" "}
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          style={{ width: "100px" }}
        />
      </span>{" "}
      <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  return (
    <FormControl
      // style={{ width: "auto" }}
      // className="filter-input"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search...`}
    />
  );
}

const LoadingHeader = ({ loading, page, all }) => {
  if (loading) {
    return <td colSpan="10000">Loading...</td>;
  } else {
    return (
      <td colSpan="10000">
        Showing {page.length} of ~{all} results
      </td>
    );
  }
};

const GlobalFilterHeader = ({
  visibleColumns,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  return (
    <tr>
      <th
        className="searchBar"
        colSpan={visibleColumns.length}
        style={{
          textAlign: "left",
        }}
      >
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </th>
    </tr>
  );
};

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange1 = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange1(e.target.value);
        }}
        placeholder={`Showing ${count} user(s)...`}
        style={{
          fontSize: "1.1rem",
          border: "2",
          color: "black",
        }}
      />
    </span>
  );
}

function DateRangeFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [showTo, setShowTo] = useState(false);

  useEffect(() => {
    if (filterValue[0] === undefined && filterValue.length !== 0) {
      setFilter([])
    }
  }, [filterValue])

  return (
    <span>
      <div
        style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}
      >
        <Form.Control
          style={{ margin: 0 }}
          value={filterValue[0] || ""}
          onChange={(e) => {
            let val = e.target.value;
            setFilter((old = []) => [
              val ? val : undefined,
              old[1] ? old[1] : new Date().toISOString().substring(0, 10),
            ]);
          }}
          type="date"
        />
        <a onClick={() => setShowTo(!showTo)} style={{ marginLeft: "1rem" }}>
          {!showTo ? <FaIcons.FaPlus /> : <FaIcons.FaMinus />}
        </a>
      </div>

      <Collapse in={showTo}>
        <div>
          <Form.Control
            value={filterValue[1] || new Date().toISOString().substring(0, 10)}
            onChange={(e) => {
              let val = e.target.value;
              setFilter((old = []) => [
                old[0] ? old[0] : "1970-01-01",
                val ? val : undefined,
              ]);
            }}
            type="date"
          />
        </div>
      </Collapse>
    </span>
  );
}

function TodayDateFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (isChecked) {
      setFilter(new Date().toISOString().substring(0, 10));
    } else {
      setFilter();
    }
  }, [isChecked]);

  return (
    <span>
      <div
        style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}
      >
        <Form.Check
          label="only show today"
          checked={isChecked}
          onChange={() => {
            setIsChecked(!isChecked);
          }}
        />
      </div>
    </span>
  );
}

function dateBetweenFilterFn(rows, id, filterValues) {
  let sd = new Date(filterValues[0]);
  let ed = new Date(filterValues[1]);

  //this super sucks
  sd.setHours(sd.getHours() + 5);
  ed.setHours(ed.getHours() + 28);

  return rows.filter((r) => {
    var time = new Date(r.values[id]);
    if (filterValues.length === 0) return rows;
    return time >= sd && time <= ed;
  });
}

dateBetweenFilterFn.autoRemove = (val) => !val;

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function todayDateFilterFn(rows, id, filterValues) {
  return matchSorter(rows, filterValues, {
    keys: [
      (row) => {
        return new Date(row.values[id]).toISOString().substring(0, 10);
      },
    ],
  });
}

todayDateFilterFn.autoRemove = (val) => !val;
