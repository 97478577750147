import React, { useState, useEffect } from "react";
import getData from "../Utilities/getData";

const OtherAPI = () => {
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Clean up controller
    // Prevents Memory leaks if closed before function finishes
    let isSubscribed = true;
    if (isSubscribed) {
      getData(setMsg, setError, "/other_api/its_time");
    }
    // cancel subscription to useEffect
    return () => (isSubscribed = false);
    // [id] prevents useEffect calls unless the id changes
  }, []);

  return (
    <div className="mycontainer">
      <div className="error">{error}</div>
      <div className="otherDiv">{msg}</div>
    </div>
  );
};

export default OtherAPI;
