import {
  MDBBtn,
  MDBCard,
  MDBCardHeader,
  MDBCardText,
  MDBCol,
  MDBProgress,
  MDBRow,
} from "mdbreact";
import { Form, InputGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FacilitySVG } from "../../Components/SVGComponents";
import * as IoIcons from "react-icons/io";
import { formatUpperCase } from "../../Utilities/Formaters";
import { MemoPrintInvoice } from "./Checkout";

export const Transactions = ({
  transactions,
  setCart,
  cart,
  setSum,
  patient,
  copay,
  total,
}) => {
  const [pay, setPay] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (transactions.length === 0) {
      setLoaded(false);
    }
  }, [transactions.length]);

  useEffect(() => {
    var x = 0;
    cart.forEach((item) => {
      var toPay = parseFloat(item.PatientPaid);
      item.PatientPaid = parseFloat(item.PatientPaid);
      if (isNaN(toPay)) toPay = 0;
      if (!item.dispute) x += toPay;
    });
    setSum(x);

    return () => { };
  }, [cart, pay]);

  const addToCart = (trans) => {
    setCart([...cart, trans]);
  };

  const removeFromCart = (trans) => {
    trans.dispute = false;
    setCart(cart.filter((item) => item !== trans));
  };
  if (transactions.length > 0) {
    return (
      <>
        <MDBCardHeader
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2>
            <IoIcons.IoMdCheckmarkCircleOutline /> 4. Collect Unpaid Balances
          </h2>
          <MemoPrintInvoice
            invoice={{ cart: transactions, patient: patient, copay: copay }}
          />
        </MDBCardHeader>
        {transactions.map((trans, idx) => {
          var allowedAmount = trans.Billed + trans.Adjustment;
          var remaining = parseFloat(trans.Remaining);

          const facility = trans.FacilityName
            ? formatUpperCase(trans.FacilityName)
            : "N/A";
          // const facility = trans.FacilityName ? trans.FacilityName.toLowerCase().split(" ") : ["N/A"];
          // for (let i = 0; i < facility.length; i++) {
          //   facility[i] = facility[i][0].toUpperCase() + facility[i].substr(1);
          // }

          if (!loaded) {
            trans.PatientPaid = remaining.toFixed(2);
            setLoaded(true);
          }
          return (
            <MDBCard key={idx}>
              <MDBRow
                style={{
                  marginBottom: "20px",
                  borderBottom: "1px solid #dfdfdf",
                }}
              >
                <MDBCol
                  md="12"
                  className="v-align"
                  style={{ fontSize: "20px", fontWeight: "150" }}
                >
                  {facility}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol
                  md="2"
                  className="v-align"
                  style={{ justifyContent: "center" }}
                >
                  <FacilitySVG
                    Text={trans.FacilityName}
                    Code={trans.BusinessCode}
                  />
                </MDBCol>
                <MDBCol md="4">
                  <MDBRow>
                    <MDBCol md="5">
                      <strong>Physician</strong>
                    </MDBCol>
                    <MDBCol md="7" className="right-col-text">
                      {trans.ProviderName ? trans.ProviderName : `${trans.ProviderFirstName} ${trans.ProviderLastName}`}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="5">
                      <strong>DOS</strong>
                    </MDBCol>
                    <MDBCol md="7" className="right-col-text">
                      {new Date(trans.DateOfService).toLocaleDateString(
                        "en-US",
                        { timeZone: "GMT" }
                      )}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="5">
                      <strong>Visit Description</strong>
                    </MDBCol>
                    <MDBCol md="7" className="right-col-text">
                      {trans["Description"]}
                    </MDBCol>

                    <MDBCardText>
                      <i>
                        {trans["Diagnosis 1 Description"]},{" "}
                        {trans["Diagnosis 2 Description"]},{" "}
                        {trans["Diagnosis 3 Description"]}
                        {/* <br /> */}
                        {/* {trans["Diagnosis 4 Description"]} */}
                        {/* <br /> */}
                      </i>
                    </MDBCardText>
                  </MDBRow>
                </MDBCol>
                <MDBCol md="4" style={{ padding: "0rem 2.5rem" }}>
                  <MDBRow>
                    <div className="progress-label">
                      <span className="fw-150">Total Cost:</span>
                      <span className="fw-300">
                        <strong>${allowedAmount.toFixed(2)}</strong>
                      </span>
                    </div>
                    <MDBProgress material value={100} height="1.5rem" />
                  </MDBRow>
                  <MDBRow>
                    <div className="progress-label">
                      <span className="fw-150">Insurance Paid:</span>
                      <span className="fw-300">
                        $
                        {(
                          -1 *
                          (Number(trans.Ins1Paid) +
                            Number(trans.Ins2Paid) +
                            Number(trans.Ins3Paid))
                        ).toFixed(2)}
                      </span>
                    </div>
                    <MDBProgress
                      material
                      value={
                        ((-1 *
                          (Number(trans.Ins1Paid) +
                            Number(trans.Ins2Paid) +
                            Number(trans.Ins3Paid))) /
                          allowedAmount) *
                        100
                      }
                      height="1.5rem"
                      color="info"
                    />
                  </MDBRow>
                  <MDBRow>
                    <div className="progress-label">
                      <span className="fw-150">Remaining Balance:</span>
                      <span className="fw-300">${remaining.toFixed(2)}</span>
                    </div>
                    <MDBProgress
                      material
                      value={(remaining / allowedAmount) * 100}
                      height="1.5rem"
                      color="warning"
                    />
                  </MDBRow>
                </MDBCol>
                <MDBCol md="2">
                  {cart.includes(trans) ? (
                    <MDBBtn onClick={() => removeFromCart(trans)}>
                      {trans.dispute ? "Undispute" : "Uncharge"}
                    </MDBBtn>
                  ) : (
                    <div>
                      <MDBBtn
                        color="cls"
                        className="portal-btn"
                        onClick={() => {
                          trans.dispute = false;
                          addToCart(trans);
                        }}
                      >
                        Charge
                      </MDBBtn>
                      <MDBBtn
                        color="cls-danger"
                        disabled={trans.isDispute}
                        className="portal-btn"
                        onClick={() => {
                          trans.dispute = true;
                          addToCart(trans);
                        }}
                      >
                        Dispute
                      </MDBBtn>
                    </div>
                  )}
                  <div>
                    <InputGroup>
                      <InputGroup.Text>
                        Total{trans.isDispute ? "*" : ""} $
                      </InputGroup.Text>

                      <Form.Control
                        type="number"
                        className="editableField"
                        value={trans.PatientPaid}
                        onChange={(e) => {
                          trans.PatientPaid = e.target.value;
                          setPay(
                            transactions.map((item, index) =>
                              index === idx ? (item = trans) : (item = item)
                            )
                          );
                        }}
                        onBlur={(e) => {
                          if (
                            isNaN(parseFloat(e.target.value)) ||
                            parseFloat(e.target.value) <= 0 ||
                            parseFloat(e.target.value) > remaining
                          ) {
                            trans.PatientPaid = remaining.toFixed(2);
                            setPay(
                              transactions.map((item, index) =>
                                index === idx ? (item = trans) : (item = item)
                              )
                            );
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key == "Enter") {
                            e.target.blur();
                          }
                        }}
                      />
                    </InputGroup>
                  </div>
                  <div>{trans.isDispute ? "Disputed" : ""}</div>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          );
        })}
      </>
    );
  } else {
    return (
      <MDBCard>
        <MDBCardHeader>Patient has no unpaid balances</MDBCardHeader>
      </MDBCard>
    );
  }
};
