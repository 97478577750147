import { useState, useEffect, useContext, memo, useMemo, useCallback } from "react";
import { Button, Card, Row, Col, Collapse, Form, Tabs, Tab } from "react-bootstrap";
import { Avatar, Popover, Tooltip, Switch } from "@mui/material";
import { useParams } from "react-router"
import { postData } from "../../Utilities/apiRequests";
import { UserContext } from "../../App";
import * as FaIcons from "react-icons/fa";
import * as GiIcons from "react-icons/gi";
import Select from "react-windowed-select";
import { labelValueSerializer } from "../../Utilities/Formaters";
import { ErrorAlert } from "../../Components/Alerts";

export const ReportCardsPage = () => {
  const { id } = useParams();

  return (
    <div className={"mycontainer"}>
      <ReportCards id={id} />
    </div>
  )
}

const BaseReportCards = ({ id, emailOpts, report, isRunning, setIsRunning }) => {
  const { user } = useContext(UserContext);

  const [cards, setCards] = useState([]);
  const [subPics, setSubPics] = useState({});

  const [showCardDetails, setShowCardDetails] = useState({});
  const [anchorEl, setAnchorEl] = useState({ element: null, id: null });

  const [filter, setFilter] = useState("");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const filteredCards = useMemo(() => {
    if (cards.length > 0 && filter.length === 0)
      return cards
    else
      return cards.filter(card => (card.name.toLowerCase().includes(filter)))

  }, [filter, cards])

  useEffect(() => {
    postData(`/billing_api/reports/${id}/cards`, {}, setCards, setError);
    setupCache();
  }, [])

  useEffect(() => {
    if (cards.length > 0) {
      const cachedSubPics = localStorage.getItem('subPics');
      let subPicsFromStorage = cachedSubPics ? JSON.parse(cachedSubPics) : {};

      let subscribers = [...new Set(cards.flatMap((s) => s.subscribers.map(obj => obj.email)))];
      subscribers = subscribers.filter(sub => !subPicsFromStorage[sub]);

      if (subscribers.some(sub => !Object.keys(subPics).includes(sub)))
        postData("/billing_api/get_subscriber_pics", subscribers, setSubPics, setError)
      else
        setSubPics(subPicsFromStorage);
    }
  }, [cards, refresh])

  useEffect(() => {
    let cachedSubPics = JSON.parse(localStorage.getItem("subPics"));
    cachedSubPics = { ...cachedSubPics, ...subPics };
    localStorage.setItem("subPics", JSON.stringify(cachedSubPics));
  }, [subPics])

  const setupCache = () => {
    // Handles checking and setup of profile pic cache
    let currentTime = JSON.stringify(new Date().getTime());

    if (!localStorage.getItem("subPics")) {
      localStorage.setItem("subPics", JSON.stringify({}));
      localStorage.setItem("lastCached", currentTime);
    }
    else {
      let lastCached = localStorage.getItem("lastCached");
      if (lastCached) {
        // Invalidate cache if it's been more than 48 hours since the last check.
        if ((new Date().getTime() - JSON.parse(lastCached)) > 48 * 60 * 60 * 1000) {
          localStorage.setItem("lastCached", currentTime);
          localStorage.setItem("subPics", JSON.stringify({}));
        }
      }
      else {
        localStorage.setItem("lastCached", currentTime);
      }
    }
  }

  const ToggleSubscription = useCallback((card, email, setForm) => {
    let subscribers = card['subscribers'].map(obj => obj.email)
    let route = "";

    if (subscribers && subscribers.includes(email))
      route = "unsubscribe"
    else if (subscribers && !subscribers.includes(email))
      route = "subscribe"

    postData(
      `/billing_api/cards/${card['id']}/${route}`,
      { email: email },
      () => { },
      setError,
      { setLoading: setLoading, successCallback: () => OptimisticUpdateSubscribers(card, route === "subscribe", email) }
    )
    if (setForm !== undefined) setForm(null);
  }, [refresh, cards, loading, error]);

  const toggleShowCard = (card) => {
    setShowCardDetails(card.id ?
      {
        [card.id]: !showCardDetails[card.id]
      } :
      {
        [card.id]: true
      })
  }

  const OptimisticUpdateSubscribers = (card, subscribe, email) => {
    let temp = cards;

    cards.forEach((c, idx) => {
      if (c['id'] === card['id']) {
        if (subscribe) {
          temp[idx]['subscribers'].push({ "email": email, "is_escalated": false })
        }
        else {
          temp[idx]['subscribers'] = temp[idx]['subscribers'].filter(e => e.email !== email)
        }
      }
    })
    setCards(temp);
    setRefresh(refresh + 1);
  }

  const RunChildCard = (card) => {
    setIsRunning({ [id]: true })
    postData(`/billing_api/reports/${id}/run/${card.id}`, {}, () => { }, setError)
  }

  return (
    <Card style={{ boxShadow: "none" }}>
      {user.role && user.role.includes("ADMIN") &&
        <AddCard
          setCards={setCards}
          report={report}
        />
      }
      <Form.Control
        placeholder="Filter cards by Report Card Name"
        onChange={(e) => setFilter(e.target.value)}
      />
      {cards && cards.map((card, idx) => {
        let subscribers = card['subscribers'].map(obj => obj.email)
        return (
          <div key={idx} style={{ display: filteredCards.some(r => r.id === card.id) ? "initial" : "none" }}>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col md="10">
                <span>{card.name}</span> <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  Status
                  <FaIcons.FaCircle style={{
                    color: card.is_valid ? "green" : "red",
                    marginLeft: "1rem",
                    marginRight: "1rem"
                  }}
                  />
                  <div style={{
                    fontSize: "1.2rem",
                    fontStyle: "italic",
                    fontWeight: "bold",
                    color: card['logs'][0] && card['logs'][0]['arg'] === "Failed" ? "red" : "initial"
                  }}>
                    {(card['logs'][0] && card['logs'][0]['timestamp']) !== undefined ? "Last run at " + card['logs'][0]['timestamp'] : "Never ran"}
                    {(card['logs'][0] && card['logs'][0]['arg'] === "Failed" ? " (Failed run)" : "")}
                  </div>
                </div>

                <span
                  style={{ color: "darkslateblue", cursor: "pointer" }}
                  onClick={(e) => subscribers.length > 0 ? setAnchorEl({ element: e.currentTarget, id: idx }) : null}
                >
                  {subscribers.length} subscriber{subscribers.length !== 1 ? "s" : ""}
                </span>

                <SubscriberPreview
                  subs={subscribers}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                  idx={idx}
                />
                <br />
                {user.role && user.role.includes("ADMIN") &&
                  <a onClick={() => toggleShowCard(card)}>
                    <FaIcons.FaCog /> {" "} Manage Settings and Subscriptions {" "} {showCardDetails[card.id] ? <FaIcons.FaChevronUp /> : <FaIcons.FaChevronDown />}
                  </a>
                }
              </Col>
              <Col md={user.role && user.role.includes("ADMIN") ? "1" : "2"} style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className={subscribers.includes(user.email) ? "btn-cls-danger" : ""}
                  onClick={() => ToggleSubscription(card, user.email)}
                  disabled={loading}>
                  {subscribers && subscribers.includes(user.email) ?
                    "Unsubscribe" : "Subscribe"}
                </Button>
                {user.role && user.role.includes("ADMIN") &&
                  <Button
                    disabled={isRunning && isRunning[id]}
                    onClick={() => RunChildCard(card)}
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "1rem" }}
                  >
                    <FaIcons.FaPlay style={{ fontSize: "2rem" }} />
                  </Button>
                }
              </Col>
              <br />
            </Row>
            <SettingsComponent
              card={card}
              ToggleSubscription={ToggleSubscription}
              emailOpts={emailOpts}
              cards={cards}
              setCards={setCards}
              subPics={subPics}
              setRefresh={setRefresh}
              showCardDetails={showCardDetails}
            />
            {idx < cards.length - 1 && <hr />}
          </div>
        )
      })}
    </Card>
  )
}

const SubscriberPreview = ({ subs, anchorEl, setAnchorEl, idx }) => {
  return (
    <>
      {subs && subs.length > 0 &&
        <Popover
          open={(anchorEl && anchorEl.id === idx)}
          onClose={() => setAnchorEl({ element: null, id: null })}
          anchorEl={anchorEl.element}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{
            '& .MuiPaper-root': {
              boxShadow: "none",
              border: "solid gainsboro 1px",
              marginLeft: "1rem",
              maxHeight: "25rem"
            }
          }}
        >
          <div style={{ padding: "1rem" }}>
            {subs.map((sub, jdx) => {
              return (
                <div key={jdx}>
                  {sub}
                  {jdx < subs.length - 1 &&
                    <hr />}
                </div>
              )
            })}
          </div>
        </Popover>
      }
    </>
  )
}

const BaseSubscriberManager = ({ opts, card, ToggleSubscription, subPics }) => {
  let subscribers = card['subscribers'].map(obj => obj.email)
  const [selectedUser, setSelectedUser] = useState();

  return (
    <div>
      <Row style={{ display: "flex", alignItems: "center" }}>
        <Col>
          <Select
            isOptionDisabled={(option) => subscribers.includes(option.value)}
            options={labelValueSerializer(opts, "displayName", "mail")}
            value={selectedUser}
            onChange={(e) => setSelectedUser(e)}
          />
        </Col>
        <Col md="auto">
          <Button
            disabled={!selectedUser}
            onClick={() => ToggleSubscription(card, selectedUser.value, setSelectedUser)}
          >
            Add
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          {subscribers.length === 0 &&
            <div style={{ textAlign: "center" }}>
              <h3>
                No subscribers... yet.
              </h3>
              <div style={{ color: "gray", textAlign: "center" }}>
                Subscribers will be listed here when we find some.
              </div>
              <GiIcons.GiNothingToSay style={{ fontSize: "10rem", color: "lightgray" }} />
            </div>
          }
          <UserList
            users={card.subscribers}
            DeleteFunction={ToggleSubscription}
            card={card}
            subPics={subPics}
          />
        </Col>
      </Row>
    </div>
  )
}

const UserList = ({ users, DeleteFunction, card, subPics }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(0);

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name,
    };
  }

  function toggleEscalation(user) {
    postData(
      `/billing_api/cards/${card['id']}/subscribers/escalate`,
      { email: user['email'], is_escalated: !user['is_escalated'] },
      () => { },
      setError,
      {
        setLoading: setLoading, successCallback: () => {
          user['is_escalated'] = !user['is_escalated']
          setRefresh(refresh + 1);
        }
      }
    )
  }

  return (
    <>
      {users && users.map((user, idx) => {
        let firstInitial = user['email'][0].toUpperCase();
        let lastInitial = user['email'].split("@")[0].includes(".") ? user['email'].split(".")[1][0].toUpperCase() : ""
        let name = `${firstInitial}${lastInitial}`
        return (
          <div key={idx}>
            <Row style={{ display: "flex", alignItems: "center", paddingBottom: "1rem" }}>
              <Col md="auto">
                <Avatar {...stringAvatar(name)} src={subPics[user['email']] ? `data:image/jpeg;base64,${subPics[user['email']]}` : ""}>{name}</Avatar>
              </Col>
              <Col>
                {user['email']}
              </Col>
              <Col md="1">
                <Tooltip title={<div className="my-tooltip">Toggle subscriber as Escalation only</div>}>
                  <Switch
                    disabled={loading}
                    checked={user['is_escalated']}
                    onChange={() => toggleEscalation(user)}
                  />
                </Tooltip>
              </Col>
              <Col md="1">
                <FaIcons.FaTrash
                  className="red-on-hover"
                  style={{ cursor: "pointer" }}
                  onClick={() => DeleteFunction(card, user['email'])}
                />
              </Col>
            </Row>
          </div>
        )
      })}
    </>
  )
}

const BaseSettingsComponent = ({ card, ToggleSubscription, emailOpts, subPics, setRefresh, cards, setCards, showCardDetails }) => {
  return (
    <Collapse in={showCardDetails[card.id]}>
      <div>
        <hr />
        <Tabs defaultActiveKey="subs">
          <Tab eventKey="subs" title="Subscribers">
            <SubscriberManager
              card={card}
              opts={emailOpts}
              ToggleSubscription={ToggleSubscription}
              subPics={subPics}
            />
          </Tab>
          <Tab eventKey="settings" title="Settings">
            <SettingsTab
              card={card}
              cards={cards}
              setCards={setCards}
              setRefresh={setRefresh}
            />
          </Tab>
          <Tab eventKey="logs" title="Logs">
            <LogsTab
              card={card}
            />
          </Tab>
        </Tabs>
      </div>
    </Collapse>
  )
}

const BaseSettingsTab = ({ card, cards, setCards, setRefresh }) => {
  const [name, setName] = useState("");
  const [arg, setArg] = useState(null);
  const [valid, setValid] = useState("");

  const [error, setError] = useState(null);

  useEffect(() => {
    if (card) {
      setName(card.name)
      setArg(card.args)
      setValid(Boolean(card.is_valid))
    }
  }, [card])

  useEffect(() => {
    if (arg === "") {
      setArg(null);
    }
  }, [arg])

  const HandleSubmit = () => {
    let data = {
      id: card.fk_report_id,
      card_id: card.id,
      name: name,
      arg: arg,
      is_valid: valid
    }
    postData(`/billing_api/cards/${card.id}/edit`, data, () => { }, setError, { successCallback: () => OptimisticUpdateCards() })
  }

  const OptimisticUpdateCards = () => {
    let temp = cards;
    temp.forEach((c, idx) => {
      if (c.id === card.id) {
        temp[idx]['name'] = name;
        temp[idx]['arg'] = arg;
        temp[idx]['is_valid'] = valid;
      }
    })
    setCards(temp);
    setRefresh((r) => r + 1);
  }

  const HandleDelete = () => {
    postData(`/billing_api/cards/${card.id}/delete`, {}, () => { }, setError, {
      successCallback: () => {
        let temp = cards;
        temp = temp.filter(c => c.id !== card.id)
        setCards(temp);
      }
    })
  }

  return (
    <div style={{ width: "50%" }}>
      <Form.Label>Name</Form.Label>
      <Form.Control
        placeholder={card.name}
        defaultValue={card.name}
        onChange={(e) => setName(e.target.value)}
      />
      <Form.Label>Argument</Form.Label>
      <Form.Control
        placeholder={card.args ? card.args : "None"}
        defaultValue={card.args}
        onChange={(e) => setArg(e.target.value)}
      />
      <Form.Label>Is Active</Form.Label>
      <Switch
        defaultChecked={Boolean(card.is_valid)}
        onChange={(e) => setValid(e.target.checked)}
      />
      <br />
      <Button
        disabled={name === card.name && arg === card.args && valid === Boolean(card.is_valid)}
        onClick={HandleSubmit}
      >
        Save Changes
      </Button>
      or {" "}
      <Button className="btn-cls-danger" onClick={HandleDelete}>
        DELETE
      </Button>
      <ErrorAlert error={error} setToggleAlert={setError} />
    </div>
  )
}

const BaseLogsTab = ({ card }) => {
  return (
    <>
      <h3> Showing the last 10 runs of this Card </h3>
      <hr />
      {card['logs'] && card['logs'].map((log, idx) => (
        <div key={idx} style={{ color: log['arg'] === 'Failed' ? "red" : "initial" }}>
          Run at {log['timestamp']} {log['arg'] === "Failed" ? " (RUN FAILED)" : ""}
        </div>
      ))}
    </>
  )
}

const AddCard = ({ report, setCards, setRefresh }) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [arg, setArg] = useState("");

  const [newCard, setNewCard] = useState({});

  const [valid, setValid] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (name.length < 5)
      setValid(false)
    else
      setValid(true)
  }, [name])

  useEffect(() => {
    if (newCard.id) {
      setCards((cards) => [...cards, newCard])
    }
  }, [newCard])


  const HandleSubmit = () => {
    if (valid) {
      let data = {
        name: name,
        args: arg
      }
      postData(`/billing_api/reports/${report.id}/cards/new`, data, setNewCard, setError, { successCallback: () => setShow(false) })
    }
  }

  return (
    <div>
      <a onClick={() => setShow(!show)}>
        Add Card +
      </a>
      <Collapse in={show}>
        <div>
          <Form.Control
            placeholder={`Name for new ${report.name} scorecard`}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Form.Control
            placeholder="Argument; special database identification value"
            value={arg}
            onChange={(e) => setArg(e.target.value)}
          />
          <Button disabled={!valid} onClick={HandleSubmit}>Add</Button>
          <ErrorAlert setToggleAlert={setError} error={error} />
        </div>
      </Collapse>
      <hr />
    </div>
  )
}

export const ReportCards = memo(BaseReportCards);

const SettingsComponent = memo(BaseSettingsComponent);
const SubscriberManager = memo(BaseSubscriberManager);
const SettingsTab = memo(BaseSettingsTab);
const LogsTab = memo(BaseLogsTab);