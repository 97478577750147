import { Row, Col, Tabs, Tab } from "react-bootstrap";

import { PatientCard } from "./PatientCard";
import {
  GetEncounterColumns,
  GetDiagnosisColumns,
  GetMedicationColumns,
} from "../../../Utilities/Columns";
import { Table } from "../../../Components/Table";

export function ShowMore({ patientData, encounterData, diagnosisData, medicationData }) {
  return (
    <>
      <Row>
        <Col xs={6} md={4}>
          <PatientCard patientData={patientData} />
        </Col>
        <Col xs={12} md={8}>
          <Tabs defaultActiveKey={"codes"}>
            <Tab eventKey={"codes"} title={"Diagnoses"}>
              <Table
                columns={GetDiagnosisColumns()}
                data={diagnosisData}
                isGlobalFilter={true}
              />
            </Tab>
            <Tab eventKey={"meds"} title={"Medications"}>
              <Table
                columns={GetMedicationColumns()}
                data={medicationData}
                isGlobalFilter={true}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table columns={GetEncounterColumns()} data={encounterData} />
        </Col>
      </Row>
    </>
  );
}
