import { MDBBtn } from "mdbreact";
import { useMemo, useState } from "react";
import {
  Modal,
  ModalBody,
  Spinner,
} from "react-bootstrap";
import {
  EditableCell,
  EditableEmailCell,
  EditableSelectionCell,
  EditablePasswordCell,
  LinkCell,
  OnCallDateCell,
  MoreInfoHoverCell,
} from "../Components/CellTypes";
import getData from "./getData";
import { postData } from "./apiRequests";
import { BriefCollapse } from "../Components/BriefCollapse";
import { Tooltip } from "@mui/material";
import { createPDF } from "../Pages/PatientPages/Checkout";
import { ErrorAlert } from "../Components/Alerts";

export const GetGenericColumns = (cols, limits, rename) => {
  let temp = Object.keys(cols[0] ? cols[0] : {});
  temp = limits ? limits : temp;
  const columns = useMemo(
    () => temp.map((col, idx) => {
      let filter = "fuzzyText";
      let sortType = "alphanumeric";
      let filterType = "";
      let header = col;

      if (col.toLowerCase().includes("date")) {
        filter = "dateBetween"
        sortType = "datetime"
        filterType = "between"
      }

      if (rename && rename[col]) header = rename[col];

      return {
        Header: header,
        accessor: (row) => {
          return col.toLowerCase().includes("date") ? new Date(row[col]) : `${row[col]}`
        },
        id: idx,
        Cell: ({ cell: { value } }) => {
          return col.toLowerCase().includes("date") ?
            value.toLocaleDateString("en-US", { timeZone: "UTC" }) :
            value
        },
        filter: filter,
        sortType: sortType,
        filterType: filterType,
      }
    }), [cols]
  );
  return columns;
}

export const GetIamColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },
      {
        Header: "Permission",
        accessor: (row) => (
          <BriefCollapse lines={2.5}>
            {"Read:" +
              row.read_scope.map((provider) => {
                return (
                  " " + provider["First Name"] + " " + provider["Last Name"]
                );
              }) +
              " | " +
              "Write Unresolve: " +
              row.unresolve_scope.map((provider) => {
                return provider["First Name"] + " " + provider["Last Name"];
              }) +
              " | " +
              "Write Resolve: " +
              row.resolve_scope.map((provider) => {
                return provider["First Name"] + " " + provider["Last Name"];
              })}
          </BriefCollapse>
        ),
      },
    ],
    []
  );
  return columns;
};

export const GetBillingColumns = ({ setData }, data) => {
  const [loading, setLoading] = useState(false);
  const handleResolve = (row) => {
    postData(
      "/payment_api/set_resolved_descriptors",
      row,
      () => { },
      () => { },
      {
        setLoading: setLoading,
        successCallback: () => {
          setData(data.filter((e) => e !== row));
        },
      }
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "Desc_ID",
      },
      {
        Header: "Practice",
        accessor: (row) =>
          `${row.Business_Code && row.Business_Code !== "N/A"
            ? row.Business_Code
            : row.Facility_Name
          }`,
        filter: "fuzzyText",
      },
      {
        Header: "Chart Number",
        accessor: "Chart_Number",
        filter: "fuzzyText",
      },
      {
        Header: "Provider",
        accessor: "Provider",
        filter: "fuzzyText",
      },
      {
        Header: "Resource",
        accessor: "Resource_Name",
        filter: "fuzzyText",
      },
      {
        Header: "Patient Name",
        accessor: (row) => `${row.First_Name} ${row.Last_Name}`,
        filter: "fuzzyText",
      },
      {
        Header: "Patient DoB",
        accessor: (row) => new Date(row.dob),
        Cell: ({ cell: { value } }) =>
          value.toLocaleDateString("en-US", { timeZone: "UTC" }),
        sortType: "datetime",
      },
      {
        Header: "Date of Service",
        accessor: (row) => new Date(row.Date_of_Service),
        Cell: ({ cell: { value } }) =>
          value.toLocaleDateString("en-US", { timeZone: "UTC" }),
        filter: "dateBetween",
        sortType: "datetime",
        filterType: "between",
      },
      {
        Header: "Payment Date",
        accessor: (row) => new Date(row.Timestamp),
        Cell: ({ cell: { value } }) => value.toLocaleString("en-US"),
        filter: "dateBetween",
        sortType: "datetime",
        filterType: "between",
      },
      {
        Header: "Item",
        accessor: "Item",
      },
      {
        Header: "Desc",
        accessor: "Item_Desc",
      },
      {
        Header: "Mode",
        accessor: (row) => {
          return `${row.Transaction_Type} ${row.Verification_Value !== null
            ? ` (${row.Verification_Value})`
            : ""
            }`;
        },
        filter: "fuzzyText",
      },
      {
        Header: "Amount",
        accessor: (row) => `$${row.Patient_Paid}`,
        sortType: "alphanumeric",
      },
      {
        Header: "Action",
        accessor: (row) => {
          return (
            <MDBBtn
              disabled={loading}
              onClick={() => {
                handleResolve(row);
              }}
            >
              {loading ? (
                <Spinner variant="light" animation="border" />
              ) : row.Is_Resolved ? (
                "Unresolve"
              ) : (
                "Resolve"
              )}
            </MDBBtn>
          );
        },
      },
    ],
    [loading, data]
  );
  return columns;
};

export const GetTransactionsColumns = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [confVoid, setConfVoid] = useState(null);
  const [error, setError] = useState(null);


  const columns = useMemo(
    () => [
      {
        Header: "Payment Date",
        filter: "todayDate",
        hideHeader: true,
        accessor: (row) => {
          return new Date(row.Timestamp).toLocaleString();
        },
      },
      {
        Header: "Patient",
        accessor: "Patient_Name",
        filter: "fuzzyText",
      },
      {
        Header: "Method",
        accessor: (row) =>
          `${row.Transaction_Type} ${row.Verification_Value ? row.Verification_Value : ""
          }`,
        filter: "fuzzyText",
      },
      {
        Header: "Total",
        accessor: "Total",
      },
      {
        Header: "Print",
        accessor: (row) => {
          return (
            <MDBBtn
              onClick={() => {
                setLoading(true);
                postData(
                  "/payment_api/print_payment",
                  row,
                  (data) => {
                    createPDF(data, null);
                  },
                  () => { },
                  {
                    setLoading: setLoading,
                  }
                );
              }}
            >
              {loading ? (
                <Spinner variant="light" animation="border" />
              ) : (
                "Print"
              )}
            </MDBBtn>
          );
        },
      },
      {
        Header: "Void",
        accessor: (row) => {
          let hoursSincePayment =
            (Date.now() - new Date(row.Timestamp).getTime()) / (1000 * 60 * 60);
          return (
            <div>
              <Modal
                show={confVoid == row.Payment_ID}
                onHide={() => setConfVoid(false)}
                centered
              >
                <ModalBody>
                  <h3>Are you sure you would like to "Void" this transaction?</h3>
                  <div style={{ display: "inline-block" }}>
                    <MDBBtn color="cls" onClick={() => setConfVoid(false)}>
                      Cancel
                    </MDBBtn>
                    <MDBBtn
                      onClick={() =>
                        getData(
                          () => { },
                          setError,
                          `/payment_api/void_transaction/${row.Payment_ID}`,
                          () => { },
                          false,
                          {
                            setLoading: setLoading,
                            successCallback: () => {
                              row.Is_Voided = true;
                              setRefresh((refresh) => refresh + 1);
                              setConfVoid(false)
                            },
                          }
                        )
                      }
                    >
                      {loading && <Spinner variant="light" animation="border" />}
                      Confirm Void
                    </MDBBtn>
                    <ErrorAlert error={error} setToggleAlert={setError} />
                  </div>
                </ModalBody>
              </Modal>
              <Tooltip
                followCursor
                title={
                  hoursSincePayment > 24 && row.Transaction_Type === 'credit' ? (
                    <p className="my-tooltip"> Payment too old to void </p>
                  ) : (
                    ""
                  )
                }
              >
                <span>
                  <MDBBtn
                    disabled={
                      row.Is_Voided || (hoursSincePayment > 24 && row.Transaction_Type === 'credit') || loading
                    }
                    onClick={() => {
                      setConfVoid(row.Payment_ID);
                    }}
                  >
                    {loading && <Spinner variant="light" animation="border" />}
                    {row.Is_Voided ? "Voided" : "Void"}
                  </MDBBtn>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [loading, refresh, confVoid]
  );
  return columns;
};
export const GetTransactionColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Transaction ID",
        accessor: "Pk_TransactionsId",
      },
      {
        Header: "Date",
        accessor: (row) => {
          return new Date(row.Date).toLocaleDateString("en-US", {
            timeZone: "GMT",
          });
        },
      },
      {
        Header: "Billed",
        accessor: "Amount",
      },
      {
        Header: "Adjustment",
        accessor: "Adjustment Amount",
      },
      {
        Header: "Insurances Paid",
        id: "totalInsPaid",
        accessor: (row) => {
          return (
            row["Insurance 1 Amount Paid"] +
            row["Insurance 2 Amount Paid"] +
            row["Insurance 3 Amount Paid"]
          ).toFixed(2);
        },
      },
      {
        Header: "Patient Paid",
        accessor: "Patient Paid",
      },
      {
        Header: "Patient Unpaid",
        accessor: "Patient Unpaid",
      },
      {
        Header: "CPT",
        accessor: "CPT Code",
      },
      {
        Header: "Units",
        accessor: "Units",
      },
      {
        Header: "Procedure",
        accessor: "Procedure Description",
        Cell: MoreInfoHoverCell,
      },
    ],
    []
  );
  return columns;
};
export const GetInfusionColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Date Of Service",
        accessor: "dos",
      },
      {
        Header: "Insurance",
        accessor: "ins",
      },
      {
        Header: "Cost/Unit",
        accessor: "cost",
      },
      // {
      //   Header: "Billed/Unit",
      //   accessor: "billed",
      // },
      {
        Header: "Payment/Unit",
        accessor: "total",
      },
      {
        Header: "Profit/Unit",
        accessor: "profit",
      },
    ],
    []
  );
  return columns;
};
export const GetUsersColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "UID",
        accessor: "id",
        filter: "fuzzyText",
      },

      {
        Header: "First Name",
        accessor: "firstName",
        filter: "fuzzyText",
        Cell: EditableCell,
      },

      {
        Header: "Last Name",
        accessor: "lastName",
        filter: "fuzzyText",
        Cell: EditableCell,
      },

      {
        Header: "Email",
        accessor: "email",
        filter: "fuzzyText",
        Cell: EditableEmailCell,
      },

      {
        Header: "Role",
        accessor: "role",
        filter: "fuzzyText",
        Cell: EditableSelectionCell,
      },

      {
        Header: "Password",
        accessor: "password",
        Cell: EditablePasswordCell,
      },

      {
        Header: "Confirm Password",
        accessor: "confirmPassword",
        Cell: EditablePasswordCell,
      },
    ],
    []
  );

  return columns;
};

export const GetPatientsColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "UID",
        accessor: "UID",
        Cell: (props) => {
          return <LinkCell namespace={"id"} querystr={"UID"} {...props} />;
        },
      },
      {
        Header: "First Name",
        accessor: "FirstName",
        Cell: (props) => {
          return <LinkCell namespace={"id"} querystr={"UID"} {...props} />;
        },
      },

      {
        Header: "Last Name",
        accessor: "LastName",
        Cell: (props) => {
          return <LinkCell namespace={"id"} querystr={"UID"} {...props} />;
        },
      },

      {
        Header: "Date of Birth",
        accessor: "DateOfBirth",
        Cell: (props) => {
          return <LinkCell namespace={"id"} querystr={"UID"} {...props} />;
        },
      },

      {
        Header: "Phone Number",
        accessor: "PhoneNumber",
        Cell: (props) => {
          return <LinkCell namespace={"id"} querystr={"UID"} {...props} />;
        },
      },
      {
        Header: "Gender",
        accessor: "Gender",
        Cell: (props) => {
          return <LinkCell namespace={"id"} querystr={"UID"} {...props} />;
        },
      },
      {
        Header: "Age",
        accessor: "Age",
        Cell: (props) => {
          return <LinkCell namespace={"id"} querystr={"UID"} {...props} />;
        },
      },
    ],
    []
  );
  return columns;
};
export const GetDiagnosisColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "Code",
      },

      {
        Header: "Description",
        accessor: "Description",
      },
    ],
    []
  );

  return columns;
};
export const GetMedicationColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Description",
        accessor: "Description",
      },
    ],
    []
  );

  return columns;
};
export const GetEncounterColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "Date",
      },

      {
        Header: "Provider Name",
        accessor: "ProviderName",
      },

      {
        Header: "Facility Name",
        accessor: "FacilityName",
      },

      {
        Header: "Reason",
        accessor: "Reason",
      },
    ],
    []
  );

  return columns;
};

export const GetOncallColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Doctor",
        accessor: "doctor",
        Cell: (props) => {
          return (
            <LinkCell namespace={"doctor"} querystr={"doctor_id"} {...props} />
          );
        },
      },

      {
        Header: "Specialty",
        accessor: "specialty",
        Cell: (props) => {
          return (
            <LinkCell namespace={"doctor"} querystr={"doctor_id"} {...props} />
          );
        },
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: (props) => {
          return (
            <LinkCell namespace={"doctor"} querystr={"doctor_id"} {...props} />
          );
        },
      },
      {
        Header: "Note",
        accessor: "note",
        Cell: (props) => {
          return (
            <LinkCell namespace={"doctor"} querystr={"doctor_id"} {...props} />
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "start_time",
        Cell: OnCallDateCell,
      },
      {
        Header: "End Time",
        accessor: "end_time",
        Cell: (props) => {
          return (
            <LinkCell namespace={"doctor"} querystr={"doctor_id"} {...props} />
          );
        },
      },
    ],
    []
  );
  return columns;
};
export const GetDoctorColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Doctor",
        accessor: "doctor",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
      {
        Header: "Cell Number",
        accessor: "number_cell",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
      {
        Header: "Office Number",
        accessor: "number_office",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
      {
        Header: "Answering Number",
        accessor: "number_answering",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
      {
        Header: "Email",
        accessor: "email",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
    ],
    []
  );

  return columns;
};

export const GetSpecialtyColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Specialty",
        accessor: "specialty",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
    ],
    []
  );

  return columns;
};

export const GetLocationColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Location",
        accessor: "location",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
    ],
    []
  );

  return columns;
};
export const GetOnCallColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Doctor",
        accessor: "doctor",
        filter: "fuzzyText",
        Cell: EditableCell,
      },

      {
        Header: "Specialty",
        accessor: "specialty",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
      {
        Header: "Location",
        accessor: "location",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
      {
        Header: "Notes",
        accessor: "note",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
      {
        Header: "Start Time",
        accessor: "start_time",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
      {
        Header: "End Time",
        accessor: "end_time",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
      {
        Header: "End Date",
        accessor: "end_date",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
    ],
    []
  );

  return columns;
};
export const GetCategoryColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Catagory",
        accessor: "catagory",
        filter: "fuzzyText",
        Cell: EditableCell,
      },
    ],
    []
  );

  return columns;
};

export const GetQuestionsColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Catagory",
        accessor: "catagory",
        filter: "fuzzyText",
        Cell: EditableCell,
      },

      {
        Header: "Question Type",
        accessor: "question_type",
        filter: "fuzzyText",
        Cell: EditableCell,
      },

      {
        Header: "Question",
        accessor: "question",
        filter: "fuzzyText",
        Cell: EditableCell,
      },

      {
        Header: "Answer",
        accessor: "answer",
        filter: "fuzzyText",
        Cell: EditableCell,
      },

      {
        Header: "Possible Answer 1",
        accessor: "possible_ans1",
        Cell: EditableCell,
      },

      {
        Header: "Possible Answer 2",
        accessor: "possible_ans2",
        Cell: EditableCell,
      },

      {
        Header: "Possible Answer 3",
        accessor: "possible_ans3",
        Cell: EditableCell,
      },
    ],
    []
  );

  return columns;
};
export const GetResultsColumns = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "ufname",
        filter: "fuzzyText",
      },

      {
        Header: "Last Name",
        accessor: "ulname",
        filter: "fuzzyText",
      },

      {
        Header: "Email",
        accessor: "email",
        filter: "fuzzyText",
      },
      {
        Header: "Form",
        accessor: "user_view",
        filter: "fuzzyText",
      },

      {
        Header: "Created",
        accessor: "date_created",
        filter: "fuzzyText",
      },
      {
        Header: "Updated",
        accessor: "date_updated",
        filter: "fuzzyText",
      },
      {
        Header: "Results",
        accessor: "formId",
        filter: "fuzzyText",
      },

      {
        Header: "Status",
        accessor: "status",
        filter: "fuzzyText",
      },

      {
        Header: "Assessment",
        accessor: "assessment",
        filter: "fuzzyText",
      },
    ],
    []
  );

  return columns;
};
