import { Tooltip } from "@mui/material";
import * as FaIcons from "react-icons/fa";
export const TooltipIcon = ({ content }) => {
  return (
    <div style={{ display: "inline-block", alignItems: "stretch" }}>
      <Tooltip
        title={
          <p className="my-tooltip">
            {content}
          </p>
        }
      >
        <span>
          <FaIcons.FaQuestionCircle />
        </span>
      </Tooltip>
    </div>
  )
}