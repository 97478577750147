import { getCookie } from "../Utilities/LoginUtil";


const getItem = (url, data, setData, setError, optionalCallback, optionalValue) => {
  if(optionalValue === undefined) optionalValue = ""
  if(optionalCallback === undefined) optionalCallback = function(optionalValue){}
  const opts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": getCookie("csrf_access_token"),
    },
    body: JSON.stringify(data),
  };
  fetch(url, opts).then((response) => {
    // if POST was successfull
    // Set data recieved to appropriate state
    if (response.status === 200) {
      response.json().then((data) => {
        setData(data, optionalCallback(optionalValue));
      });
    } else {
      response.json().then((data) => {setError(data.message)})
    }
  });
};

export default getItem;
