import React, { useState } from "react";
import * as IoIcons from "react-icons/io";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBRow,
  MDBCol,
} from "mdbreact";
import { Form, InputGroup } from "react-bootstrap";
import { labelValueSerializer } from "../../Utilities/Formaters";
import WindowedSelect from "react-windowed-select";
import { Tooltip } from "@mui/material";

export const AdditionalPayment = ({
  handleShowTrans,
  setSum,
  setFormData,
  formData,
  setInfoChange,
  info,
  getProviders,
  providers,
  getFacilities,
  facilities,
}) => {
  const [refresh, setRefresh] = useState(0);
  const handleFocus = () => {
    if (providers.length === 0) getProviders();
    if (facilities.length === 0) getFacilities();
  };

  const handleChange = (e, item) => {
    if (e.target.name === "PatientPaid") {
      // var pay = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
      item[e.target.name] = parseFloat(e.target.value);
      item["PatientUnpaid"] = item["PatientPaid"];
      var x = 0;
      if (!isNaN(parseFloat(formData.PatientPaid))) {
        x += parseFloat(formData.PatientPaid);
      } else {
        x += 0;
        item[e.target.name] = ""
      }
      setSum(x);
    } else item[e.target.name] = e.target.value;
    setRefresh(refresh + 1); //STOP THIS >:C // NVEGF
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleShowTrans();
  };

  return (
    <MDBCard style={{ margin: 0 }}>
      <MDBCardHeader>
        <h2>
          <IoIcons.IoMdCheckmarkCircleOutline /> 3. Payments
        </h2>
        <Tooltip title={<p className="my-tooltip">Select whether the payment is a copay (default) or statement</p>}>
          <Form.Check
            inline label={formData.CPTCode === "COPAY" ? "Copay" : "Patient Statement"} type="switch"
            defaultChecked={formData.CPTCode === "STATEMENT"}
            onChange={() => {
              let newArr = formData;
              newArr.CPTCode = newArr.CPTCode === "COPAY" ? "STATEMENT" : "COPAY";
              setFormData(newArr);
              localStorage.setItem("defaultPaymentType", newArr.CPTCode);
              setInfoChange((prevInfo) => ({
                ...prevInfo, paymentType: newArr.CPTCode
              }));
              setRefresh(refresh + 1);
            }}
          />
        </Tooltip>
      </MDBCardHeader>
      <MDBCardBody>
        <form onSubmit={handleSubmit}>
          <MDBRow>
            <MDBCol md="4">
              <label>Provider:</label>
              <WindowedSelect
                options={labelValueSerializer(
                  providers.filter((x) => x.is_provider == 1),
                  ["First Name", "Last Name"],
                  "doctorId"
                )}

                value={labelValueSerializer(
                  providers.filter((x) => x.is_provider == 1),
                  ["First Name", "Last Name"],
                  "doctorId"
                ).filter((obj) => obj.value === info.doctorId)}

                onChange={(e) => {
                  localStorage.setItem(
                    "defaultProvider",
                    JSON.stringify(e)
                  );

                  setInfoChange((prevInfoChange) => ({
                    ...prevInfoChange,
                    provider: e.label,
                    doctorId: e.value,
                  }));
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    ProviderName: e.label,
                    provider: e.label,
                    doctorId: e.value,
                  }))
                  setRefresh(refresh + 1);
                }}
                onFocus={handleFocus}
              />
            </MDBCol>
            <MDBCol md="4">
              <label>Resources:</label>
              <WindowedSelect
                options={labelValueSerializer(
                  providers.filter((x) => x.is_provider == 0),
                  ["First Name", "Last Name"],
                  "doctorId"
                )}

                value={labelValueSerializer(
                  providers.filter((x) => x.is_provider == 0),
                  ["First Name", "Last Name"],
                  "doctorId"
                ).filter((obj) => obj.value === info.resourceId)}

                onChange={(e) => {
                  localStorage.setItem(
                    "defaultResource",
                    JSON.stringify(e)
                  );
                  setInfoChange((prevInfoChange) => ({
                    ...prevInfoChange,
                    resourceName: e.label,
                    resourceId: e.value,
                  }));
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    resourceName: e.label,
                    resourceId: e.value,
                  }))
                  setRefresh(refresh + 1);
                }}
                onFocus={handleFocus}
              />
            </MDBCol>

            <MDBCol md="4">
              <label>Facility:</label>
              <WindowedSelect
                value={labelValueSerializer(
                  facilities,
                  "Facility_Name",
                  "Facility_ID"
                ).filter((obj) => obj.value === info.Facility_ID)}

                onChange={(e) => {

                  setInfoChange((prevInfoChange) => ({
                    ...prevInfoChange,
                    Facility_ID: e.value,
                    FacilityName: e.label,
                  }));

                  localStorage.setItem(
                    "defaultFacility",
                    JSON.stringify(e)
                  );
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    FacilityName: e.label,
                    Facility_ID: e.value,
                  }))
                  setRefresh(refresh + 1);
                }}
                options={labelValueSerializer(
                  facilities,
                  "Facility_Name",
                  "Facility_ID"
                )}
                onFocus={handleFocus}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow
            className="InputRow"
            style={{ alignItems: "center" }}
          >
            <MDBCol md="auto">
              <label>Collection Amount:</label>
              <Tooltip title={
                !formData.ProviderName || !formData.FacilityName ?
                  <p className="my-tooltip">Select a provider and facility first.</p>
                  : ""}>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    disabled={!formData.ProviderName || !formData.FacilityName}
                    label="Copay Amount"
                    placeholder="Copay Amount"
                    name="PatientPaid"
                    size="lg"
                    value={formData.PatientPaid}
                    type="number"
                    step="0.01"
                    min="0"
                    onChange={(e) => {
                      handleChange(e, formData);
                    }}
                  />
                </InputGroup>
              </Tooltip>
            </MDBCol>
            <MDBCol>
              <label>Description:</label>
              <Form.Control
                disabled={!formData.ProviderName || !formData.FacilityName}
                label="Description"
                name="Description"
                placeholder="Description"
                size="lg"
                value={formData.Description}
                type="text"
                onChange={(e) => {
                  handleChange(e, formData);
                }}
              />
            </MDBCol>
          </MDBRow>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <MDBBtn color="cls-danger" type="submit">
              Continue
            </MDBBtn>
          </div>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
};