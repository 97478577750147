import "react-tabs/style/react-tabs.css";
import React, { useState, useEffect } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { PastDueUnresolved } from "./PastDueUnresolved";
import { RestUnresolved } from "./RestUnresolved";
import { Resolved } from "./Resolved";

export const BillingTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    document.title = "Stream | Billing"
  }, [])

  return (
    <div className="mycontainer" style={{ marginInline: "10%", width: "80%" }}>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Past Due</Tab>
          <Tab>Unposted</Tab>
          <Tab>Posted</Tab>
        </TabList>

        <TabPanel>
          <PastDueUnresolved />
        </TabPanel>
        <TabPanel>
          <RestUnresolved />
        </TabPanel>
        <TabPanel>
          <Resolved />
        </TabPanel>
      </Tabs>
    </div>
  );
};
