import { BillingSummary } from "./Summary"
import { Card } from "react-bootstrap"

export const RulesHome = () => {
  return (
    <div className="mycontainer">
      <Card>
        <h2>Billing Rules Panel</h2>
        <Card.Body>
          <BillingSummary />
        </Card.Body>
      </Card>
    </div>
  )
}