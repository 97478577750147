import { Navigation } from "./Navigation";
import { MDBBtn } from "mdbreact";
import { useHistory, useLocation } from "react-router-dom";
export function PleaseLogin() {
  const history = useHistory()
  const location = useLocation()
  const params = location.state
  return (
    <>
      <Navigation />
      <div className="mycontainer">
        <div className="error">
          <h1>Something went wrong...</h1>
          You are not authorized to view this page. Sign in, or request the appropriate role from your profile dashboard.<br />
          If you do not have an account, you can register an account at the sign in page.<br />
          Additional Details:
          {params && params.error ?
            <li>{params.error}</li>
            :
            <div>
              <li>You may have been logged out due to inactivity.</li>
              <li>You may not have the appropriate roles to view this page.</li>
              <li>We may be experiencing issues on our end.</li>
              {/* <br/> */}
              If the problem persists, please submit a bug report using the 'problems?' button on the top right of this webpage.
            </div>
          }
          <MDBBtn color="cls" onClick={() => { history.push("/", params) }}>Please Login!</MDBBtn>
        </div>
      </div>
    </>
  );
}
