import React, { useEffect, useState } from "react";
import { Table } from "../../Components/Table";
import { GetBillingColumns } from "../../Utilities/Columns";
import getData from "../../Utilities/getData";
import { Spinner } from "react-bootstrap";

export const Resolved = () => {
  const [resolvedData, setResolvedData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData(
      setResolvedData,
      setError,
      "/payment_api/get_descriptors/resolved",
      () => { },
      false,
      {
        setLoading: setLoading,
      }
    );
    return () => { };
  }, []);

  return (
    <>
      {loading ?
        <Spinner animation="border" variant="dark" /> : null
      }
      <Table
        columns={GetBillingColumns({ setData: setResolvedData }, resolvedData)}
        data={resolvedData}
        isPrintable={true}
        loading={loading}
        isExpand={true}
      />
    </>
  );
};
