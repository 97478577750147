import { Card } from "react-bootstrap"
import { useHistory } from "react-router"
export const BillingSummary = () => {
  const history = useHistory();
  return (
    <>
      This is the Billing Rules portal. You can preview, create, and manage billing rules to catch billing errors by specifying
      conditions to be met as expected of a rule. This feature is in beta and is subject to change.
      <Card>
        <Card.Body>
          <a onClick={() => history.push("/BillingRules/Rules")} href="#">Link to rules management page</a> <br />
          View, manage, preview, or create new billing rules.
          <hr />
          <a onClick={() => history.push("/BillingRules/Errors")} href="#">Link to view all errors</a><br />
          View a summary of and get a detailed look at all errors found by a particular rule.
        </Card.Body>
      </Card>
    </>
  )
}