import React, { useState, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ReactPDF, {
  Page,
  Text,
  Document,
  StyleSheet,
  Font,
  View,
  Image,
} from "@react-pdf/renderer";
import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import * as IoIcons from "react-icons/io";
import * as FaIcons from "react-icons/fa";

import logo from "../../InvoiceLogo.png";

import { MDBBtn, MDBCard, MDBCardHeader, MDBRow, MDBCol } from "mdbreact";
import { Spinner, Alert, Form } from "react-bootstrap";
import { postData } from "../../Utilities/apiRequests";
import { ReceiptForm } from "./Receipt";
import terminals from "./Registers.json";
import { ErrorAlert } from "../../Components/Alerts";
import WindowedSelect from "react-windowed-select";
import { UserContext } from "../../App";
import { OnlinePayment } from "./OnlinePayment";
import { Accordion } from "react-bootstrap";
import { useRef } from "react";
import { Tooltip } from "@mui/material";

export const Checkout = () => {
  let defaultRegister = localStorage.getItem("registerId");
  defaultRegister = defaultRegister ? JSON.parse(defaultRegister) : "";
  const isManualRef = useRef(null);

  const location = useLocation();
  const history = useHistory();
  const { user } = useContext(UserContext);

  // const patient = location.state.patient;
  const customer = location.state.customer;
  const cart = location.state.cart;
  const copay = parseFloat(parseFloat(location.state.copay).toFixed(2));
  const total = parseFloat(parseFloat(location.state.total).toFixed(2));

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [isManual, setIsManual] = useState(false);
  const [paymentType, setPaymentType] = useState("credit");
  const [cash, setCash] = useState(false);
  const [check, setCheck] = useState(false);
  const [checkNumber, setCheckNumber] = useState("");
  const [patient, setPatient] = useState(location.state.patient);
  const [infoWarning, setInfoWarning] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [theAlert, setTheAlert] = useState({ remaining: 0, invoice_id: "" });
  const [submitLoading, setLoading] = useState(false);
  const [registerId, setRegisterId] = useState(defaultRegister);
  const [receiptInfo, setReceiptInfo] = useState({
    print: false,
    email: patient.email,
    phone: patient.phone,
    isEmail: false,
    isPhone: true,
  });

  useEffect(() => {
    if (isManual) window.scrollTo(0, isManualRef.current.offsetTop);
  }, [isManual]);

  useEffect(() => {
    setCash(paymentType === "cash");
    setCheck(paymentType === "check");
  }, [paymentType]);
  useEffect(() => {
    if (error && error.invoice_id) {
      setShowAlert(true);
      setTheAlert(error);
    }
  }, [error]);

  useEffect(() => {
    if (
      receiptInfo.email !== patient.email ||
      receiptInfo.phone !== patient.phone
    )
      setInfoWarning(true);
    else setInfoWarning(false);
  }, [receiptInfo]);

  function handlePartialPayment(data) {
    if (data.invoice_id) {
      setShowAlert(true);
      setTheAlert(data);
    } else {
      setError(data);
    }
  }

  function handleSubmit() {
    setSuccess(null);
    setError(null);
    if (registerId.value === null && paymentType === "credit") {
      return setError("Please select a terminal");
    }
    if (paymentType === "check" && checkNumber.length === 0) {
      return setError("Please enter the check number.");
    }
    const data = {
      cart: cart,
      patient: patient,
      customer: customer,
      receipt: receiptInfo,
      merchantId: user.uid,
      total: total + copay,
      paymentType: paymentType,
      potential_collection: location.state.potential_collection,
    };

    if (error && error.invoice_id) {
      data.invoice_id = error.invoice_id;
      data.remaining = error.remaining;
    }

    if (paymentType === "credit") data.registerId = registerId.value;
    else if (paymentType === "check") data.checkNumber = checkNumber;

    postData("/payment_api/process_payment", data, setSuccess, setError, {
      setLoading: setLoading,
      timeout: 400000,
      history: history,
      successCallback: () => {
        if (receiptInfo.print)
          createPDF({ cart: cart, patient: patient, copay: copay });
        setShowAlert(null);
        setTheAlert({ remaining: 0, invoice_id: "" });
      },
      errorCallback: () => console.log("error"),
    });
  }

  return (
    <div className="mycontainer">
      <MDBCard>
        <MDBRow>
          <MDBCardHeader>
            <h2>
              <IoIcons.IoMdCheckmarkCircleOutline />
              3. Confirm Transactions
            </h2>
          </MDBCardHeader>
          <Cart cart={cart} />
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <h3>Copay: ${parseFloat(copay).toFixed(2)}</h3>
            <h3>Service Total: ${parseFloat(total).toFixed(2)}</h3>
            <h3>
              <span>
                <b>
                  Total: ${(parseFloat(total) + parseFloat(copay)).toFixed(2)}
                </b>
              </span>
            </h3>

            <MDBRow>
              <MDBCol md="auto">
                <Form.Label>Payment Type</Form.Label>
              </MDBCol>
              <MDBCol md="auto">
                <h2 style={{ display: "flex" }}>
                  {cash ? (
                    <IoIcons.IoLogoUsd />
                  ) : check ? (
                    <FaIcons.FaMoneyCheckAlt />
                  ) : (
                    <IoIcons.IoMdCard />
                  )}
                </h2>
              </MDBCol>
              <Form
                onChange={(e) => {
                  setPaymentType(e.target.id);
                  if (e.target.id != "credit") setIsManual(false);
                }}
              >
                <Form.Check
                  inline
                  type="radio"
                  name="payment"
                  id="credit"
                  label="Credit"
                  defaultChecked
                />
                <Form.Check
                  inline
                  type="radio"
                  name="payment"
                  id="cash"
                  label="Cash"
                />
                <Form.Check
                  inline
                  type="radio"
                  name="payment"
                  id="check"
                  label="Check"
                />
              </Form>
            </MDBRow>
            {paymentType === "credit" && (
              <div style={{ display: "flex", alignItems: "stretch" }}>
                <Form.Check
                  style={{ paddingRight: "1rem" }}
                  type="switch"
                  id="custom-switch"
                  label="Virtual Payment"
                  checked={isManual}
                  onChange={(e) => {
                    setIsManual(e.target.checked);
                  }}
                />
                <Tooltip
                  title={
                    <p className="my-tooltip">
                      If the patient's card isn't present, or if the physical
                      terminal is not working, check this option.
                    </p>
                  }
                >
                  <span>
                    <FaIcons.FaQuestionCircle />
                  </span>
                </Tooltip>
              </div>
            )}
            <MDBRow>
              <MDBCol md="auto">
                {check && (
                  <Form.Control
                    placeholder="Check Number..."
                    maxLength={9}
                    // type="number"
                    value={checkNumber}
                    onChange={(e) => setCheckNumber(e.target.value)}
                  />
                )}
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol>
            <ReceiptForm
              patientInfo={patient}
              receiptInfo={receiptInfo}
              setReceiptInfo={setReceiptInfo}
            />
          </MDBCol>
        </MDBRow>
        {isManual ? (
          <Accordion defaultActiveKey="0" ref={isManualRef}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Pay Virtually</Accordion.Header>
              <Accordion.Body>
                <OnlinePayment
                  patient={patient}
                  setSuccess={setSuccess}
                  cart={cart}
                  customer={customer}
                  setPatient={setPatient}
                  setError={setError}
                  receipt={{
                    invoice_id: null,
                    customer: customer,
                    patient: patient,
                    receipt: receiptInfo
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          <MDBRow style={{ alignItems: "flex-end" }}>
            <MDBCol md="6" style={{ marginBlock: "0.375rem" }}>
              <h3>Select your terminal</h3>
              <WindowedSelect
                options={terminals}
                onChange={(e) => {
                  setRegisterId(e);
                  localStorage.setItem("registerId", JSON.stringify(e));
                }}
                value={registerId}
                isDisabled={cash || check}
              />
            </MDBCol>
            <MDBCol md="auto">
              <MDBBtn
                disabled={
                  submitLoading ||
                  success !== null ||
                  (!registerId.value && !cash && !check)
                }
                onClick={handleSubmit}
                color="cls"
              >
                {submitLoading && <Spinner animation="grow" variant="light" />}
                {!submitLoading ? "Confirm Transaction" : " Confirming..."}
              </MDBBtn>
            </MDBCol>
            <MDBCol md="auto">
              <Tooltip
                title={
                  success ? (
                    ""
                  ) : (
                    <p className="my-tooltip">
                      Confirm transaction first to generate the receipt.
                    </p>
                  )
                }
              >
                <span style={{ cursor: !success ? "not-allowed" : "pointer" }}>
                  <MemoPrintInvoice
                    receipt={{ cart: cart, patient: patient, copay: copay }}
                    disabled={!success}
                  />
                </span>
              </Tooltip>
            </MDBCol>
          </MDBRow>
        )}

        <ErrorAlert
          error={error && !error.invoice_id ? error : null}
          setToggleAlert={setError}
        />
        {error && error.invoice_id && error.code === 400 ? (
          <Alert variant="danger">
            Stax Error: Receipt Failed to Send.{" "}
            <a
              onClick={() => {
                postData(
                  "/payment_api/resend_receipt",
                  {
                    invoice_id: error.invoice_id,
                    receipt: receiptInfo,
                    patient: patient,
                    customer: customer,
                  },
                  setSuccess,
                  setError,
                  {
                    setLoading: setLoading,
                  }
                );
              }}
            >
              <u>Click here to resend receipt.</u>
            </a>
          </Alert>
        ) : null}
        {/* <Alert variant="secondary" show={submitLoading}>
          Loading...
        </Alert> */}
        <Alert show={success !== null} variant="success">
          Transaction successfully confirmed.
          <br />
          <a href="/patients"> Click here to look up another patient</a>
        </Alert>
        <Alert show={submitLoading && !cash && !check} variant="primary">
          Please take a look at the terminal to complete the transaction.
        </Alert>
        <Alert show={infoWarning} variant="warning">
          If patient's email or sms is different from the default, please
          directly reflect these changes in ECW as well.
        </Alert>

        {/*Partial payments template*/}
        <Alert
          show={error && error.invoice_id && error.code != 400 ? true : false}
          variant="warning"
        >
          {error && error.invoice_id && error.code != 400 ? (
            <>
              <Alert.Heading as="h1">Urgent notice</Alert.Heading>
              <hr />
              Only $<strong>{total + copay - error.remaining}</strong> out of $
              <strong>{total + copay}</strong> was processable by this card.{" "}
              <br />
              To pay the remaining $<strong>{error.remaining}</strong>:
              <li>
                {" "}
                ask the customer for a different card, or to pay with cash or
                check{" "}
              </li>
              <li>
                {" "}
                select the appropriate payment type based on their new payment
                method{" "}
              </li>
              <li> confirm transaction and continue as normal </li>
              If the customer is unable to pay the remaining balance, let them
              know that they can be pay it later. <br />
              <a href="/patients"> Click here to look up another patient</a>
            </>
          ) : null}
        </Alert>
      </MDBCard>
    </div>
  );
};

export const createPDF = async (receipt, invoice, transcript, target = "_blank") => {
  const blob = await ReactPDF.pdf(
    <MyDocument receipt={receipt} invoice={invoice} transcript={transcript} />
  ).toBlob();
  const url = URL.createObjectURL(blob);
  let w = window.open(url, target);
  w.print();
};

export const PrintInvoice = ({
  receipt = null,
  invoice = null,
  transcript = null,
  disabled = false,
}) => {
  return (
    <MDBBtn
      disabled={disabled}
      target="_blank"
      onClick={() => createPDF(receipt, invoice, transcript)}
    >
      Print
    </MDBBtn>
  );
};

export const MemoPrintInvoice = React.memo(PrintInvoice);

const Cart = React.memo(({ cart }) => {
  return cart.map((trans, idx) => {
    return (
      <MDBCard key={idx}>
        <MDBRow>
          <MDBCol md="2">{trans.FacilityName}</MDBCol>
          <MDBCol md="2">
            {trans.ProviderName}
            <br />
            {trans.CPTCode}
            <br />
            {new Date(trans.DateOfService).toLocaleDateString("en-US", {
              timeZone: "GMT",
            })}
          </MDBCol>
          <MDBCol md="4">
            <h4>Visit Description</h4>
            <h5>{trans.Description}</h5>
          </MDBCol>
          <MDBCol md="auto">
            <div>
              {trans.dispute ? "Disputed* " : ""}Total: $
              {parseFloat(trans.PatientPaid).toFixed(2)}
            </div>
            <div>
              {trans.dispute ? "* Disputed total will not be charged" : ""}
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    );
  });
});

// Create styles
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "left",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 12,
    fontFamily: "Oswald",
  },
  subtitleRight: {
    fontSize: 18,
    marginBottom: 12,
    fontFamily: "Oswald",
    textAlign: "right",
  },
  text: {
    lineHeight: 1.5,
    fontSize: 14,
    fontFamily: "Times-Roman",
  },
  qrText: {
    lineHeight: 1,
    fontSize: 10,
  },
  image: {
    marginVertical: 0,
    marginHorizontal: "auto",
    width: 400,
  },
  qrcode: {
    marginVertical: 10,
    width: 80,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  noborder: {
    border: "0",
  },
  col: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    marginHorizontal: 7.5,
  },
  colRight: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    marginHorizontal: 7.5,
    textAlign: "right",
  },
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginHorizontal: 7.5,
    marginBottom: 15,
  },
  rowCost: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginHorizontal: 7.5,
    fontSize: 14,
    fontFamily: "Times-Roman",
    marginBottom: 15,
    alignItems: "flex-end",
  },
  thcell: {
    // border: "1px solid #ddd",
    padding: 4,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "center",
    backgroundColor: "#97190d",
    color: "white",
    fontSize: 12,
  },
  tdcell: {
    // border: "1px solid #ddd",
    padding: 4,
    textAlign: "center",
    fontSize: 10,
  },
  footer: {
    textAlign: "center",
    left: 0,
    right: 0,
    fontSize: 10,
    position: "absolute",
    bottom: 50,
  },
});

// Create Document Component
export const MyDocument = ({ invoice, receipt, transcript }) => {
  const info = receipt || invoice || transcript;
  const amount = receipt ? "PatientPaid" : invoice ? "Remaining" : "Amount";
  const total = info.cart.reduce(
    (previousValue, currentValue) =>
      previousValue + parseFloat(currentValue[amount]),
    0
  );
  const patient = info.patient;
  return (
    <Document>
      <Page style={styles.body}>
        {!receipt && !transcript ? <Text style={styles.qrText}>Scan code to pay online</Text> : null}
        <View style={styles.row}>
          {!receipt && !transcript ? <Image style={styles.qrcode} src={document.querySelector('canvas').toDataURL()} /> : null}
          <Image style={styles.image} src={logo} />
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.subtitle}>Patient Information</Text>
            <Text style={styles.text}>
              {patient.ufname}
              {patient.uminitial ? ` ${patient.uminitial}. ` : " "}
              {patient.ulname}
            </Text>
            <Text style={styles.text}>
              {patient.address}{" "}
              {patient.address2 ? patient.address2 : ""}
            </Text>
            <Text style={styles.text}>
              {patient.ZIP} {patient.city}, {patient.state}
            </Text>
            <Text style={styles.text}>{patient.phone}</Text>
          </View>
          <View style={styles.colRight}>
            <Text style={styles.subtitleRight}>Billing Information</Text>
            {/* <Text style={styles.text}>Date of Birth: {patient.dob}</Text> */}
            {receipt || transcript ? (
              <Text style={styles.text}>
                Date: {date(info.transaction_date) || date()}
              </Text>
            ) : (
              <Text style={styles.text}>Invoice Date: {date()}</Text>
            )}
            <Text style={styles.text}>500 N. Kobayashi Rd</Text>
            <Text style={styles.text}>77598 Webster, TX</Text>
            <Text style={styles.text}>281-942-8001</Text>
          </View>
        </View>

        <Table data={info.cart}>
          <TableHeader>
            <TableCell weighting={10} style={styles.thcell}>
              Provider
            </TableCell>
            <TableCell weighting={10} style={styles.thcell}>
              Item
            </TableCell>
            <TableCell weighting={60} style={styles.thcell}>
              Description
            </TableCell>
            <TableCell weighting={10} style={styles.thcell}>
              Date
            </TableCell>
            <TableCell weighting={10} style={styles.thcell}>
              Amount
            </TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell
              weighting={10}
              style={styles.tdcell}
              getContent={(r) =>
                r.ProviderName
                  ? r.ProviderName
                  : r.Provider
                    ? r.Provider
                    : `${r.ProviderFirstName} ${r.ProviderLastName}`
              }
            />
            <DataTableCell
              weighting={10}
              style={styles.tdcell}
              getContent={(r) => (r.CPTCode ? r.CPTCode : r.Item)}
            />
            <DataTableCell
              weighting={60}
              style={styles.tdcell}
              getContent={(r) => (r.Description ? r.Description : r.Item_Desc)}
            />
            <DataTableCell
              weighting={10}
              style={styles.tdcell}
              getContent={(r) =>
                r.DateOfService
                  ? date(r.DateOfService)
                  : date(r.Date_of_Service)
              }
            />
            <DataTableCell
              weighting={10}
              style={styles.tdcell}
              getContent={(r) => `$${parseFloat(r[amount]).toFixed(2)}`}
            />
          </TableBody>
        </Table>
        <View style={(styles.row, { marginVertical: 20 })}>
          {/* {receipt && (
            <View style={styles.col}>
              <Text style={styles.rowCost}>
                Copay: ${parseFloat(info.copay).toFixed(2)}
              </Text>
              <Text style={styles.rowCost}>
                Service Amount: $
                {(parseFloat(total) - parseFloat(info.copay)).toFixed(2)}
              </Text>
            </View>
          )} */}
          <View style={styles.colRight}>
            {receipt || transcript ? (
              <Text style={{ marginTop: 25, marginRight: 12 }}>
                Total Paid: ${parseFloat(total).toFixed(2)}
              </Text>
            ) : (
              <Text style={{ marginTop: 25, marginRight: 12 }}>
                Total Due: ${parseFloat(total).toFixed(2)}
              </Text>
            )}
          </View>
        </View>
        <Text style={styles.footer}>
          For questions, information, or concerns, contact billing@cls.health
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const date = (dateInput = null) => {
  var date;
  if (dateInput) {
    date = new Date(dateInput);
  } else {
    date = new Date();
  }
  return `${date.getMonth() + 1}/${date.getUTCDate()}/${date.getFullYear()}`;
};
