import React, { useState, useContext } from "react";
import { UserContext } from "../../App";
import { ChangeEmailForm } from "./ProfileForms";
import {
  ErrorAlert,
  LoadingAlert,
  SuccessAlert,
} from "../../Components/Alerts";
import { postData } from "../../Utilities/apiRequests";

const ChangeEmailPage = () => {
  const { user } = useContext(UserContext);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (formData) => {
    const data = {
      uid: user.uid,
      newEmail: formData.newEmail,
      curPassword: formData.curPassword,
    };
    if (user.email === formData.email) {
      setError("Can't change email to current email.");
    } else {
      postData("/profile_api/change_email", data, setSuccess, setError, {
        setLoading: setLoading,
      });
    }
  };
  return (
    <>
      <ChangeEmailForm onFormSubmit={handleSubmit} user={user} />
      <LoadingAlert isLoading={loading} />
      <ErrorAlert error={error} setToggleAlert={setError} />
      <SuccessAlert msg={success} setToggleAlert={setSuccess} />
    </>
  );
};

export default ChangeEmailPage;
