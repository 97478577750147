import React, { useEffect, useState, useContext } from "react";
import { Route, useHistory, Redirect } from "react-router-dom";
import { UserContext } from "../App";
import MySpinner from "./MySpinner";
import getData from "../Utilities/getData";
import { MDBAnimation as Animation } from 'mdbreact'

export const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const { user, setUser } = useContext(UserContext);
  const [error, setError] = useState(null);
  const history = useHistory()
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getData(setUser, setError, "/auth_api/is_logged_in");
    }
    return () => {
      isSubscribed = false;
    };
  }, [setUser]);

  useEffect(() => {
    let isSubscribed = true;
    if (error !== null) {
      console.log(error)
      setError(null);
      history.push("/not-authorized", { error: error });
    }

    return () => {
      isSubscribed = false;
    };
  }, [error]);

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        user.email === null ? (
          <MySpinner />
        ) :
          !{ ...restOfProps }.roles.some(r => user.role.includes(r)) ?
            <Redirect to={{
              pathname: "/not-authorized",
              state: { from: window.location.pathname }
            }} />
            :
            (
              <Animation type="fadeIn">
                <Component {...props} />
              </Animation>
            )
      }
    />
  );
};
